<script>
    import { selectedModule } from "../../stores/AppStatus";
    import { modules } from "../../stores/AppConfig";

    import { _ } from 'svelte-i18n';

</script>

{#if $selectedModule && $selectedModule != 'dashboard'}
    <div class="fs-14 left-splitter pr-4 pl-4 h-100 valign-center">
        {#if $modules.filter(x => x.id == $selectedModule).at(0)?.name}
            {$_($modules.filter(x => x.id == $selectedModule).at(0).name)}
        {/if}
    </div>
{/if}
