<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import CircularProgress from "@smui/circular-progress";
  import { _ } from "svelte-i18n";

  import type { RPPPD } from "../../../types/Report";

  // Filters
  export let countrySearchArray = [];
  export let items: RPPPD[] = [];
  export let filteredItems: RPPPD[] = [];

  // Data Status
  export let dataLoaded = false;
  export let loadingData = false;

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  $: filteredItems = items?.filter((item) => {
    const countryMatch = countrySearchArray.length > 0 ? countrySearchArray.some((unitId) => item.country?.includes(unitId)) : true;
    return countryMatch;
  });

  // Table Head
  $: tableHead = [
    { columnId: "tescoWeek", label: "tesco_week", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "supplierId", label: "rppc_supplier_id", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "supplierRmsNo", label: "rppc_supplier_rms_no", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "country", label: "country", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "dcCode", label: "dc", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "productId", label: "rppc_product_id", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "productDescription", label: "rppc_product_description", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "buyer", label: "buyer", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "productGroup", label: "product_group", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "tpnb", label: "rppc_tpnb", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "productDetailDescription", label: "rppc_product_detail_description", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "supplierName", label: "supplier_name", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "cartonLogistics", label: "carton_logistics", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "palletLogistics", label: "pallet_logistics", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "lastPrice", label: "rppc_last_price", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "price", label: "rppc_price", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "lastPromoPrice", label: "rppc_last_promo_price", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "promoPrice", label: "rppc_promo_price", numeric: false, extraClasses: " wrap-spaces text-center " },
    { columnId: "currency", label: "currency", numeric: false, extraClasses: " wrap-spaces text-center " },
  ];
</script>

<DataTable table$aria-label="report" class="w-100 mt-2 report">
  <Head>
    <Row>
      {#each tableHead as head (head.columnId)}
        <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header right-splitter {head.extraClasses}">
          <Label>{$_(head.label)}</Label>
        </Cell>
      {/each}
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="16" class="mdc-data-table__cell" style="width: 100%; text-align: center">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice.length == 0}
      {#if !dataLoaded}
        <Row>
          <td colspan="16" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("load_report_data")}</td>
        </Row>
      {:else}
        <Row>
          <td colspan="16" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_for_week")}</td>
        </Row>
      {/if}
    {:else}
      {#each slice as data (data.id)}
        <Row>
          <Cell numeric>{data.tescoWeek}</Cell>
          <Cell numeric>{data.supplierId}</Cell>
          <Cell numeric>{data.supplierRmsNo}</Cell>
          <Cell class="text-center">{data.country}</Cell>
          <Cell class="text-center">{data.dcCode}</Cell>
          <Cell numeric>{data.productId}</Cell>
          <Cell>{data.productDescription}</Cell>
          <Cell>{data.buyer}</Cell>
          <Cell>{data.productGroup}</Cell>
          <Cell numeric>{data.tpnb}</Cell>
          <Cell>{data.productDetailDescription}</Cell>
          <Cell>{data.supplierName}</Cell>
          <Cell numeric>{data.cartonLogistics}</Cell>
          <Cell numeric>{data.palletLogistics}</Cell>
          <Cell numeric>{data.lastPrice}</Cell>
          <Cell numeric>{data.price}</Cell>
          <Cell numeric>{data.lastPromoPrice}</Cell>
          <Cell numeric>{data.promoPrice}</Cell>
          <Cell class="text-center">{data.currency}</Cell>
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}
      >first_page</IconButton
    >
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}
      >chevron_left</IconButton
    >
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}
      >chevron_right</IconButton
    >
    <IconButton
      class="material-icons"
      action="last-page"
      title="Last page"
      on:click={() => (currentPage = lastPage)}
      disabled={currentPage === lastPage}>last_page</IconButton
    >
  </Pagination>
</DataTable>

<style>
</style>
