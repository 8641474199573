<script lang="ts">
    import { unauthorized, authenticatedUser } from "../stores/AppStatus";

    import TopAppBar, { Row, Section } from "@smui/top-app-bar";
    import { _ } from "svelte-i18n";
    import HomeButton from "./header/HomeButton.svelte";
    import ActiveModuleTitle from "./header/ActiveModuleTitle.svelte";
    import AppTitle from "./header/AppTitle.svelte";
    import UserMenu from "./header/UserMenu.svelte";
    import RoleBox from "./header/RoleBox.svelte";
    import ExchangeRatesBox from "./header/ExchangeRatesBox.svelte";
</script>

<TopAppBar variant="static" color="surface">
    <Row class="">
        <Section class="pa-0 ma-0">
            <HomeButton />
            <AppTitle />
            {#if !$unauthorized}
                <ActiveModuleTitle />
            {/if}
        </Section>
        <Section class="ma-0 pa-0" align="end" toolbar>
            {#if !$unauthorized}
                <!--<RoleBox />-->
                {#if !$authenticatedUser.isSupplier}
                    <ExchangeRatesBox />
                {/if}

                <UserMenu />
            {/if}
        </Section>
    </Row>
</TopAppBar>

<style>
</style>
