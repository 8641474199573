<script>
    // @ts-nocheck

    import Textfield from "@smui/textfield";
    import Button, { Label } from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiBackspaceOutline } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    import Select, { Option } from "@smui/select";

    import { forecastStatuses, dcList, rejectPurchaseReasons } from "../../../stores/AppConfig";
    import { bsDcList, bsStatusList, bsUnitList, bsConfirmList, bsAutobuyList } from "../../../stores/FiltersLists.js"
    import MultiselectFilterBar from "../common/MultiselectFilterBar.svelte";
    import MultiSelectErrorsFilterBar from "../common/MultiSelectErrorsFilterBar.svelte";

    import { onMount } from "svelte";

    export let selectedDC = [];
    export let ceTpnSearch = "";
    export let ceDescSearch = "";
    export let tpnbSearch = "";
    export let tpnbDescSearch = "";
    export let statusSearchArray = [];
    export let fileNameSearch = "";
    export let buyerSearchArray = [];
    export let buyerList = [];
    export let confirmedSearchArray = [];
    export let autobuySearchArray = [];
    export let reasonSearchArray = [];

    let reasonList = [];

    // User stores to save Multiselect lists to allow unselect values after switch back from Purchase screen
    onMount(() => {

        if ($bsDcList?.length == 0 && $dcList && $dcList?.length > 0) {
            $bsDcList = $dcList.map((x) => { return { id: x.code, label: x.code + " - " + x.name }});
        }

        if ($bsStatusList?.length == 0 && $forecastStatuses && $forecastStatuses.length > 0) {
            $bsStatusList = $forecastStatuses.map((x) => { return { id: x.name, label: $_(x.name) }; });
        }

        if ($bsConfirmList?.length == 0) {
            $bsConfirmList = [{id:'Y', label:$_('yes')},{id:'N', label:$_('no')}];
        }

        if ($bsAutobuyList?.length == 0) {
            $bsAutobuyList = [{id:'Y', label:$_('yes')},{id:'N', label:$_('no')}];
        }

        if ($rejectPurchaseReasons?.length > 0) {
            reasonList = $rejectPurchaseReasons.map((x) => { return { id: x.name, label: $_(x.name) }; });
        }
    });

</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100 bs-bb">
    <MultiselectFilterBar title={$_("dc")} valuesArray={$bsDcList} bind:selection={selectedDC} size="15" sortValues={false} />

    <Textfield
        variant="outlined"
        bind:value={ceTpnSearch}
        input$maxlength={13}
        input$pattern={"\\d+"}
        title={$_("tpn_en")}
        class="white w-100 small flex-1"
        input$class="mnw-200 pl-1"
        label={$_("tpn_en")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={ceDescSearch}
        input$maxlength={200}
        title={$_("product_en")}
        class="white w-100 small flex-0"
        input$class="mnw-200"
        label={$_("product_en")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={tpnbSearch}
        input$maxlength={9}
        input$pattern={"\\d+"}
        title={$_("tpnb")}
        class="white w-100 small flex-1"
        input$class="mnw-200 pl-1"
        label={$_("tpnb")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={tpnbDescSearch}
        input$maxlength={200}
        title={$_("tpnb_desc")}
        class="white w-100 small flex-0"
        input$class="mnw-200"
        label={$_("tpnb_desc")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={fileNameSearch}
        input$maxlength={200}
        title={$_("file_name")}
        class="white w-100 small flex-0"
        input$class="mnw-200"
        label={$_("file_name")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <MultiselectFilterBar title={$_("status")} valuesArray={$bsStatusList} bind:selection={statusSearchArray} size="15" sortValues={false} />

    <MultiselectFilterBar title={$_("buyer")} valuesArray={buyerList} bind:selection={buyerSearchArray} size="15" sortValues={false} />

    <MultiselectFilterBar title={$_("confirmed")} valuesArray={$bsConfirmList} bind:selection={confirmedSearchArray} size="15" sortValues={false} />

    <MultiselectFilterBar title={$_("autobuy")} valuesArray={$bsAutobuyList} bind:selection={autobuySearchArray} size="10" sortValues={false} />

    <MultiselectFilterBar title={$_("reason_code")} valuesArray={reasonList} bind:selection={reasonSearchArray} size="17" sortValues={false} />

    <Button
        title={$_("clear")}
        class="tescoblue white-text"
        on:click={() => {
            ceTpnSearch = "";
            ceDescSearch = "";
            tpnbSearch = "";
            tpnbDescSearch = "";
            fileNameSearch = "";
            selectedDC = [];
            statusSearchArray = [];
            buyerSearchArray = [];
            confirmedSearchArray = [];
            autobuySearchArray = [];
            reasonSearchArray.length = 0;
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </Icon>
    </Button>
</Flex>

<style>
</style>
