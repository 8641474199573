<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import Button from '@smui/button';
    import { Icon } from '@smui/icon-button';
    import ConfirmationDialog from '../../dialogs/ConfirmationDialog.svelte';
    import InfoDialog from '../../dialogs/InfoDialog.svelte';
    import { _ } from 'svelte-i18n';
    import { icons } from './iconMap'; // Add new icons here

    export let items = [];
    export let selected = [];
    export let disabled = true;
    export let title = 'Action';
    export let icon = ''; // mdiSendOutline
    export let allowedConditions = []; // [{ status: 'rejected', applyStatus: 'not_applied' }, { status: 'not_responded', applyStatus: 'not_applied' }]

    const dispatch = createEventDispatcher();
    let dialogOpen = false;
    let errDialogOpen = false;
    let secondDialogOpen = false;
    let confirmText = '';
    let secondConfirmText = '';
    let iconPath = '';
    let validRows = [];

    onMount(() => {
        if (icon && icons[icon]) {
            iconPath = icons[icon];
        } else {
            console.error(`Icon "${icon}" could not be loaded`);
        }
    });

    function handleClick() {
        if (selected.length === 0 && items.length > 0) {
            confirmText = $_('do_you_want_to_use_all_rows', { values: { action: items.length } });
            dialogOpen = true;
        } else if (selected.length > 0) {
            confirmText = $_('do_you_want_to_use_selected_rows_out_off', { values: { selected: selected.length, total: items.length } });
            dialogOpen = true;
        } else {
            errDialogOpen = true;
        }
    }

    function handleConfirm() {
        dialogOpen = false;
        const rowsToProcess = selected.length > 0 ? selected : items;

        let invalidCounts = {};
        validRows = []; // Reset validRows

        for (const row of rowsToProcess) {
            const isAllowed = allowedConditions.some(condition =>
                Object.keys(condition).every(key => row[key] === condition[key])
            );

            if (!isAllowed) {
                const invalidFields = Object.keys(allowedConditions[0])
                    .map(key => $_(row[key]))
                    .join(' - ');

                if (invalidCounts[invalidFields]) {
                    invalidCounts[invalidFields].count += 1;
                } else {
                    invalidCounts[invalidFields] = {
                        count: 1,
                        fields: invalidFields
                    };
                }
            } else {
                validRows.push(row);
            }
        }

        if (Object.keys(invalidCounts).length > 0) {
            const totalInvalidCount = Object.values(invalidCounts).reduce((sum, entry) => sum + entry.count, 0);
            const invalidValues = Object.values(invalidCounts)
                .map(entry => `${entry.fields}`)
                .join(', ');

            if (validRows.length > 0) {
                secondConfirmText = $_('cannot_proceed_but_some_valid_rows', { values: { count: totalInvalidCount, values: invalidValues, validCount: validRows.length } });
                secondDialogOpen = true;
            } else {
                confirmText = $_('cannot_proceed_reason', { values: { count: totalInvalidCount, values: invalidValues } });
                errDialogOpen = true;
            }
        } else if (validRows.length > 0) {
            dispatch(title, validRows);
        } else {
            confirmText = $_('no_valid_rows_to_process');
            errDialogOpen = true;
        }
    }

    function handleSecondConfirm() {
        secondDialogOpen = false;
        dispatch(title, validRows);
    }
</script>

<Button
    {disabled}
    class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
    on:click={handleClick}
>
    {#if iconPath}
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={iconPath} />
        </Icon>
    {/if}
    {$_(title)}
</Button>

<ConfirmationDialog
    open={dialogOpen}
    title={$_(title)}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => (dialogOpen = false)}
    on:confirm={handleConfirm}
>
    {confirmText}
</ConfirmationDialog>

<ConfirmationDialog
    open={secondDialogOpen}
    title={$_(title)}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => (secondDialogOpen = false)}
    on:confirm={handleSecondConfirm}
>
    <div class="pre-wrap">{secondConfirmText}</div>
</ConfirmationDialog>

<InfoDialog
    open={errDialogOpen}
    title={$_(title)}
    btnText={$_("close")}
    big={false}
    headerColors="tescoblue white-text"
    on:close={() => (errDialogOpen = false)}
>
    {confirmText}
</InfoDialog>

<style>
    .pre-wrap {
        white-space: pre-wrap;
    }
</style>