<script>
    import Textfield from "@smui/textfield";
    import Button, { Label } from "@smui/button";

    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiBackspaceOutline } from "@mdi/js";

    import Flex from "svelte-flex";

    import { _ } from "svelte-i18n";

    import CountryFilter from "../common/CountryFilterBar.svelte";
    import MultiselectFilterBar from "../common/MultiselectFilterBar.svelte";
    import MultiSelectErrorsFilterBarAccord from "../common/MultiSelectErrorsFilterBarAccord.svelte";

    import { countries, quoteSheetStatuses } from "../../../stores/AppConfig";

    export let countrySearch = [];
 

     $: {
         console.log({countrySearch});
     }
</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100">
    <CountryFilter bind:selection={countrySearch} title={$_("country")} size="10" multiselect />

</Flex>

<style>
</style>
