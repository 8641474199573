<script>

    import { dcList, countries, amendmentStatuses } from "../../../stores/AppConfig";

    import Textfield from '@smui/textfield';
    import Button, { Label} from '@smui/button';

    import { Icon } from '@smui/icon-button';
    import { mdiMagnify, mdiBackspaceOutline } from '@mdi/js';

    import MultiselectFilterBar from "../common/MultiselectFilterBar.svelte";
    import CountryFilter from '../common/CountryFilterBar.svelte';
    import MultiSelectErrorsFilterBarAccord from "../common/MultiSelectErrorsFilterBarAccord.svelte";

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';

    export let selectedDC = [];
    export let ceTpnSearch = "";
    export let ceDescSearch = "";
    export let tpnbSearch = "";
    export let tpnbDescSearch = "";
    export let fileNameSearch = "";
    export let statusSearchArray = [];
    export let statusSearchErrorsArray = [];
    export let acScreenMode = "";
    export let statusErrors;

    let amendmentCountryStatusesArray = $amendmentStatuses.map(x => {return {id: x.id, label: $_(x.name)}});
    let showErrorFilter = false;
    let dcArray = $dcList.map((x) => { return { id: x.code, label: x.code + " - " + x.name }; });

    $: if (acScreenMode === "upload" && $statusErrors.length > 0) {
        showErrorFilter = true;
    } else {
        statusSearchErrorsArray = [];
        showErrorFilter = false;
    }


</script>

<Flex direction="row" justify="between" align="center" class="w-100 gray lighten-4 h-100">
    <Flex direction="row" justify="start" align="center" class="flex-wrap gap-05">

        <MultiselectFilterBar title={$_("dc")} valuesArray={dcArray} bind:selection={selectedDC} size="15" sortValues={false} />

        <Textfield
            variant="outlined"
            bind:value={ceTpnSearch}
            input$maxlength={13}
            input$pattern={"\\d+"}
            title={$_("tpn_en")}
            class="white w-100 small flex-1"
            input$class="mnw-200 pl-1"
            label={$_("tpn_en")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={ceDescSearch}
            input$maxlength={200}
            title={$_("product_en")}
            class="white w-100 small flex-0"
            input$class="mnw-200"
            label={$_("product_en")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={tpnbSearch}
            input$maxlength={9}
            input$pattern={"\\d+"}
            title={$_("tpnb")}
            class="white w-100 small flex-1"
            input$class="mnw-200 pl-1"
            label={$_("tpnb")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={tpnbDescSearch}
            input$maxlength={200}
            title={$_("tpnb_desc")}
            class="white w-100 small flex-0"
            input$class="mnw-200"
            label={$_("tpnb_desc")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        {#if acScreenMode == "upload"}
            {#if showErrorFilter}
                <MultiSelectErrorsFilterBarAccord title={$_("errors")} valuesArray={$statusErrors} bind:selection={statusSearchErrorsArray} size={30} sortValues={true} />
            {/if}
        {:else}
            <Textfield
                variant="outlined"
                bind:value={fileNameSearch}
                input$maxlength={200}
                title={$_("file_name")}
                class="white w-100 small flex-0"
                input$class="mnw-200"
                label={$_("file_name")}
            >
                <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                    <path fill="currentColor" d={mdiMagnify} />
                </Icon>
            </Textfield>
            <MultiselectFilterBar title={$_("status")} valuesArray={amendmentCountryStatusesArray} bind:selection={statusSearchArray} size="15" sortValues={false} />
        {/if}

    </Flex>

    <Button
        title="{$_('clear')}"
        class="tescoblue white-text"
        on:click={() => {
            ceTpnSearch = "";
            ceDescSearch = "";
            tpnbSearch = "";
            tpnbDescSearch = "";
            fileNameSearch = "";
            selectedDC = [];
            statusSearchArray = [];
            statusSearchErrorsArray = [];
        }}
    >
        <Icon tag='svg' viewBox="0 0 24 24" class="pa-0 ma-0">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </Icon>
    </Button>
</Flex>


<style>

</style>
