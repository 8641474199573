<script>
    import { createEventDispatcher } from "svelte";
    import Button from "@smui/button";
    import Paper, { Title, Content } from "@smui/paper";
    import Flex from "svelte-flex";
    import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
    import IconButton, { Icon } from "@smui/icon-button";
    import { mdiCartOutline, mdiMagnify, mdiFileExcel, mdiPencil } from "@mdi/js";
    import { _ } from "svelte-i18n";
    import CircularProgress from '@smui/circular-progress';

    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";
    import { isActionEnabled } from "../lib/Functions";
    import { authenticatedUser } from "../../../stores/AppStatus";

    const dispatch = createEventDispatcher();

    export let week;
    export let apiCallProcessing;

    let showConfirmationDialog = false;
    let confirmationDialogQuestion = "";

    $: buyingDisabled = !(isActionEnabled("buying", "insert", week.status) || isActionEnabled("buying", "edit", week.status) || isActionEnabled("buying", "delete", week.status));
    $: editDisabled =
        !(isActionEnabled("forecasts", "insert", week.status) || isActionEnabled("forecasts", "edit", week.status) || isActionEnabled("forecasts", "delete", week.status)) ||
        $authenticatedUser.isReadOnly;
    $: uploadDisabled = !isActionEnabled("forecasts", "insert", week.status) || $authenticatedUser.isReadOnly;

    $: viewEditFc = !$authenticatedUser.isBuyer && !$authenticatedUser.isSupplier;
    $: viewBuying = $authenticatedUser.isBuyer || $authenticatedUser.isAdmin;

    let tableRows = [
        { key: "warehouseCode", label: "dc", numeric: false, style: "width: 3rem; padding-right: 0px;", class: "pr-0" },
        { key: "fcTotalLines", label: "forecasted_lines", numeric: true, style: "width: 75px; padding: 3px;" },
        { key: "fcPurchasedLines", label: "purchased_lines", numeric: true, style: "padding: 3px;" },
        { key: "fcPurchaseConfirmedLines", label: "confirmed_lines", numeric: true, style: "padding: 3px;" },
        { key: "acTotalLines", label: "amendment_lines", numeric: true, style: "padding: 3px;" },
        { key: "acFinishedLines", label: "am_finished_lines", numeric: true, style: "padding: 3px;" },
        { key: "acFinishedLessLines", label: "am_finished_less_lines", numeric: true, style: "padding: 3px;" },
        { key: "acRejectedLines", label: "am_rejected", numeric: true, style: "padding: 3px;" }
    ];

    function getCombinedValue(dc, row) {
        if (row.sumKeys) {
            return row.sumKeys.reduce((sum, key) => sum + (dc[key] || 0), dc[row.key] || 0);
        }
        return dc[row.key] || 0;
    }
</script>

<section>
    <Paper square elevation="0" class="pa-0 ma-0">
        <Title class="pt-2 pl-2 pr-2 tescoblue-text">
            <Flex direction="row" align="center" justify="between" class="gap-1">
                <div>{$_("forecasts")}</div>
                <Flex direction="row" class="mr-2 gap-1">
                    {#if $authenticatedUser.isAdmin || $authenticatedUser.isSc}
                        <div title={$_("upload")}>
                            <Button
                                disabled={uploadDisabled}
                                variant="outlined"
                                class={uploadDisabled ? " grey-text white-grey-outlined" : " tescoblue-text white-tescoblue-outlined"}
                                on:click={() => dispatch("uploadFCData", { id: week.id, year: week.tescoYear })}
                            >
                                <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                                    <path fill="currentColor" d={mdiFileExcel} />
                                </Icon>
                                {$_("upload")}
                            </Button>
                        </div>
                    {/if}
                    {#if viewEditFc}
                        <div title={editDisabled ? $_("view_forecasts") : $_("edit_forecasts")}>
                            <Button
                                class="pl-4 pr-4 tescoblue-text white-tescoblue-outlined"
                                on:click={() => dispatch("editFCWeek", { id: week.id, year: week.tescoYear })}
                            >
                                <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                                    <path fill="currentColor" d={editDisabled ? mdiMagnify : mdiPencil} />
                                </Icon>
                                {editDisabled ? $_("view_forecasts") : $_("edit_forecasts")}
                            </Button>
                        </div>
                    {/if}
                    {#if viewBuying}
                        <div title={buyingDisabled ? $_("view_buying") : $_("buying")}>
                            <Button
                                disabled={buyingDisabled}
                                class="pl-4 pr-4 {buyingDisabled ? ' grey-text white-grey-outlined ' : ' tescoblue-text white-tescoblue-outlined '}"
                                variant="outlined"
                                on:click={() => dispatch("buyingFCWeek", { id: week.id, year: week.tescoYear })}
                            >
                                <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                                    <path fill="currentColor" d={mdiCartOutline} />
                                </Icon>
                                {buyingDisabled ? $_("view_buying") : $_("buying")}
                            </Button>
                        </div>
                    {/if}
                </Flex>
            </Flex>
        </Title>
        <Content class="ma-1 pb-1">
            <Flex direction="row" class="gap-2" justify="between">
                <DataTable class="summary summary-border-fix tescoblue-border w-100">
                    <Head>
                        <Row>
                            {#each tableRows as row}
                            <Cell class="datatable-header" numeric={row.numeric} style={row.style}>
                                    <span class="wrap-spaces" title={$_(row.label)}>{$_(row.label)}</span>
                                </Cell>
                            {/each}
                            <Cell class="datatable-header" style="width: 100px;"></Cell>
                        </Row>
                    </Head>
                    <Body>
                        {#if week && week?.forecasts && week?.forecasts.length > 0}
                            {#each week?.forecasts.sort((x, y) => (Number(x.warehouseOrder) > Number(y.warehouseOrder) ? 1 : -1)) as dc}
                                <Row class="small">
                                    {#each tableRows as row}
                                        <Cell numeric={row.numeric} class="{row.class}">
                                            {#if apiCallProcessing}
                                                <CircularProgress indeterminate style="height: 24px; width: 24px;" />
                                            {:else}
                                                {getCombinedValue(dc, row)}
                                            {/if}
                                        </Cell>
                                    {/each}
                                    <Cell>
                                        <Flex direction="row" justify="start">
                                            {#if viewEditFc}
                                                <div title={editDisabled ? $_("view_forecasts") : $_("edit_forecasts")}>
                                                    <IconButton
                                                        size="button"
                                                        class="tescoblue-text"
                                                        on:click={() =>
                                                            dispatch("editForecastsDC", {
                                                                id: week.id,
                                                                year: week.tescoYear,
                                                                dcId: dc.warehouseCode,
                                                                dcName: dc.warehouseName,
                                                            })}
                                                    >
                                                        <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                                                            <path fill="currentColor" d={editDisabled ? mdiMagnify : mdiPencil} />
                                                        </Icon>
                                                    </IconButton>
                                                </div>
                                            {/if}

                                            {#if viewBuying}
                                                <div title={buyingDisabled ? $_("view_buying") : $_("buying")}>
                                                    <IconButton
                                                        size="button"
                                                        class="tescoblue-text"
                                                        on:click={() =>
                                                            dispatch("buyingDC", {
                                                                id: week.id,
                                                                year: week.tescoYear,
                                                                dcId: dc.warehouseCode,
                                                                dcName: dc.warehouseName,
                                                            })}
                                                    >
                                                        <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                                                            <path fill="currentColor" d={mdiCartOutline} />
                                                        </Icon>
                                                    </IconButton>
                                                </div>
                                            {/if}
                                        </Flex>
                                    </Cell>
                                </Row>
                            {/each}
                        {:else}
                            <Row>
                                <td colspan="9" class="mdc-data-table__cell" style="width: 100%">{$_("no_data")}</td>
                            </Row>
                        {/if}
                    </Body>
                </DataTable>
            </Flex>
        </Content>
    </Paper>
</section>

<ConfirmationDialog
    open={showConfirmationDialog}
    title={$_("close_forecasts")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showConfirmationDialog = false;
    }}
    on:confirm={() => {
        showConfirmationDialog = false;
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>

<style>
    .mdc-data-table__cell {
        padding: 8px; /* Adjust padding for all cells */
    }
</style>
