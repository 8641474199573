<script>

    import Paper, { Title, Content } from '@smui/paper';

    import Flex from 'svelte-flex'

    export let title;
    export let size = 'small';
    export let headerColor = 'tescoblue';

    let dialogSize;

    $: if (size) {
        switch (size) {
            case 'small':
                dialogSize = 'mnw-400';
                break;
            case 'medium':
                dialogSize = 'mnw-500';
                break;
            case 'large':
                dialogSize = 'mnw-600';
                break;
            case 'max':
                dialogSize = 'max-size';
        }
    }

</script>

<section>
    <Paper class="pt-0 pl-0 pr-0 {dialogSize}" elevation="5">
        <Content class="w-100">
            <Flex direction="column" class="w-100 gap-2">
                <Title class="w-100 pt-2 pb-2 {headerColor} white-text text-center top-rounded"><b>{title}</b></Title>
                <slot></slot>
            </Flex>
        </Content>
    </Paper>
</section>

<style>
    section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>
