import { writable } from 'svelte/store';

export const unauthorized = writable(true);

export const sessionExpired = writable(false);

export const userLoggedOut = writable(false);

export const authenticatedUser = writable(null);

/* Track failed logins */
// Get the value out of storage on load.
const loginCount = localStorage.loginAttemptCount;

// Set the stored value or a sane default.
export const loginAttemptCount = writable(loginCount || 0);

// Anytime the store changes, update the local storage value.
loginAttemptCount.subscribe((value) => localStorage.loginAttemptCount = value)

export const userCountries =  writable([]);


export const sidebarOpen = writable(false);

export const jobBannerOpen = writable(false);
export const jobBannerText = writable('');

export const apiError = writable(false);
export const apiErrorDetails = writable(null);

export const appInitFailed = writable(false);
export const appInitFailedDetails = writable([]);


/* Some Edit dialog is open */
export const editDialogOpen = writable(false);

/* Main scroll element */
export const contentDiv = writable(null);

/* Snackbar elements */
export const snackbarInfo = writable({element: null, text: '', delay: 4000});
export const snackbarSuccess = writable({element: null, text: '', delay: 4000});
export const snackbarWarning = writable({element: null, text: '', delay: 4000});
export const snackbarError = writable({element: null, text: '', delay: -1, variant: ''});

/* Application parameters from DB */
export const params = writable({});

/* Currently selected module */
export const selectedModule = writable(null);

/* Currently selected menu tab */
export const selectedMenuTab =  writable(null);

/* Current working tesco year */
export const selectedYear = writable(null);

/* Current working tesco week */
export const selectedWeek = writable(null);

/* Current working DC */
export const selectedDC = writable(null);

// Get the value out of storage on load.
const language = localStorage.language || 'cz';

// Set the stored value or a sane default.
export const userLanguage = writable(language);

// Anytime the store changes, update the local storage value.
userLanguage.subscribe((value) => localStorage.language = value)

//
// Country
//

// Get the value out of storage on load.
const country = localStorage.country;

// Set the stored value or a sane default.
export const selectedCountry = writable(country || 'cz');

// Anytime the store changes, update the local storage value.
selectedCountry.subscribe((value) => localStorage.country = value)

export const intlCollator = writable(new Intl.Collator(country || 'en'));

//
// Echange Rates
//
export const exchangeRates = writable([]);

// Variable containing list of Supplier Amendments generated by Country Amendments screen and transferred to Supplier Amenmdents screen
export const supplierAmendments = writable([]);

// Variable to store state of Buying screen
// Current page, page size, scrollbar position
export const buyingScreenState = writable({});
