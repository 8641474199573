<script>
    import Textfield from "@smui/textfield";
    import Button from "@smui/button";

    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiBackspaceOutline } from "@mdi/js";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import CountryFilter from "../common/CountryFilterBar.svelte";
    import MultiSelectErrorsFilterBarAccord from "../common/MultiSelectErrorsFilterBarAccord.svelte";
    import MultiselectFilterBar from "../common/MultiselectFilterBar.svelte";

    import { buyersList } from "../../../stores/Buyers";

    export let tpnEnSearch = "";
    export let countrySearch = [];
    export let supplierSearch = "";
    export let groupSearch = "";
    export let productSearch = "";
    export let screenMode = "";
    export let statusErrors;
    export let statusSearchErrorsArray = [];
    export let buyerSearchArray = [];

    let showErrorFilter = false;

    //$: console.log("buyerSearchArray in filter",buyerSearchArray);

    $: if (screenMode === "upload" && $statusErrors.length > 0) {
        showErrorFilter = true;
    } else {
        statusSearchErrorsArray = [];
        showErrorFilter = false;
    }

    let buyersInput = $buyersList.map((buyer) => ({
        id: buyer.uuid,
        label: `${buyer.firstName} ${buyer.lastName}`,
    }));
</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100">
    <CountryFilter bind:selection={countrySearch} title={$_("country")} size="10" multiselect />

    <Textfield
        variant="outlined"
        bind:value={tpnEnSearch}
        maxlength="50"
        placeholder=""
        title={$_("tpn_en")}
        class="white w-100 small flex-1"
        input$class="mnw-200 pl-1"
        size="40"
        label={$_("tpn_en")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={supplierSearch}
        maxlength="50"
        placeholder=""
        title={$_("supplier")}
        class="white w-100 small flex-1"
        input$class="mnw-100"
        size="40"
        label={$_("supplier")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={groupSearch}
        maxlength="30"
        placeholder=""
        title={$_("group")}
        class="white w-100 small flex-1"
        input$class="mnw-100"
        size="20"
        label={$_("group")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={productSearch}
        maxlength="50"
        placeholder=""
        title={$_("product_name")}
        class="white w-100 small flex-1"
        input$class="mnw-200"
        size="40"
        label={$_("product_name")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
            <path fill="currentColor" d={mdiMagnify} />
        </Icon>
    </Textfield>

    <MultiselectFilterBar title={$_("buyer")} valuesArray={buyersInput} bind:selection={buyerSearchArray} size="15" sortValues={false} />

    {#if screenMode === "upload" && showErrorFilter}
        <MultiSelectErrorsFilterBarAccord
            title={$_("errors")}
            valuesArray={$statusErrors}
            bind:selection={statusSearchErrorsArray}
            size={30}
            sortValues={true}
        />
    {/if}

    <Button
        title={$_("clear")}
        class="tescoblue white-text"
        on:click={() => {
            tpnEnSearch = "";
            countrySearch = [];
            groupSearch = "";
            productSearch = "";
            supplierSearch = "";
            statusSearchErrorsArray.length = 0;
            buyerSearchArray.length = 0;
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </Icon>
    </Button>
</Flex>

<style>
</style>
