<script>
  import Flex from 'svelte-flex';
  import { _ } from 'svelte-i18n'

  import Dialog, { Content } from '@smui/dialog';
  import CircularProgress from '@smui/circular-progress';

  export let open = false;
  export let text = $_('loading_wait');

  let elementSize;
</script>

<Dialog {open}
    sheet
    scrimClickAction=""
    escapeKeyAction=""
    aria-describedby="processing"
    surface$class="">
    <Content class="">
        <Flex direction="column" align="center" class="gap-1" tabindex="0">
            <CircularProgress style="height: 96px; width: 96px;" indeterminate />
            <div class="tescoblue-text">
                {text}
            </div>
        </Flex>
    </Content>
</Dialog>
