<script>
    import { TescoLogo } from '../../services/Images';

    export let position = "top_left"; // top_left or center
    export let size = "small"; // small of big

    $: {
        console.log({position});
        console.log({size});
    }

</script>

<div class="{size} {(position == 'top_left' ? 'position-top-left' : 'position-center')}">
    <img src="{TescoLogo}" alt="Tesco logo" class="{size}" />
</div>



<style>
    .position-top-left {
        position: absolute;
        top: 70px;
        left: 10px;
    }

    .position-center {
        display: flex;
        height: 100%;
    }

    .small {
    }

    .big {
        width: 100%;
    }

    div.big > img {
        opacity: .2;
        filter: grayscale(1);
    }
</style>
