<script lang="ts">

  import { afterUpdate } from 'svelte';

  import { updateQuoteSheet as apiUpdateQuoteSheet } from "../../../api/QuoteSheet";
  import { isNumber } from "../lib/Functions";
  import { handleApiError } from "../lib/errorHandler";

  import { snackbarSuccess, authenticatedUser
          } from "../../../stores/AppStatus";

  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Pagination,
    Label,
    SortValue
  } from '@smui/data-table';
  import Select, { Option } from '@smui/select';
  import Button from '@smui/button';
  import IconButton, { Icon } from '@smui/icon-button';
  import Textfield, { Input, Label } from '@smui/textfield';
  import Tooltip, { Wrapper, Title, Content, Link, RichActions } from '@smui/tooltip';

  import DTInputCell from '../common/DTInputCell.svelte';

  import { mdiCartOutline, mdiFileUploadOutline, mdiMagnify, mdiPencilBoxOutline, mdiCartPlus } from '@mdi/js';

  import Flex from 'svelte-flex';
  import { _, number } from 'svelte-i18n';

  import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

  import { createEventDispatcher } from 'svelte';

  import { appParameters } from "../../../stores/AppConfig";

  // Items
  export let items: Items[] = [];
  export let product = {};
  export let filter = [];
  export let hideBuyIcon = false;
  export let hideEditIcon = false;
  export let purchasedSuppliers = [];
  export let proposedPurchaseVolume = [];
  export let country = [];
  export let editable = false;


  type Items = {
    quoteSheetId: number;
    supplierId: number;
    supplierName: string;
    country: string;
    currency: string;
    variety: number;
    countryOfOrigin: number;
    countryOfOrigin1w: number;
    countryOfOrigin1y: number;
    cartonLogistics: number;
    palletLogistics: number;
    volume: number;
    volume1w: number;
    volume1y: number;
    remainingVolume: number;
    units: string;
    cartonVolume: number;
    palletVolume: number;
    price: number;
    priceEur: number;
    price1w: number;
    priceEur1w: number;
    price1y: number;
    priceEur1y: number;
    pricePromo: number;
    pricePromoEur: number;
    pricePromo1w: number;
    pricePromoEur1w: number;
    pricePromo1y: number;
    pricePromoEur1y: number;
  };

  let quoteSheetTableCountrySearchArray = [];

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  // Sorting
  let sort: keyof Items = 'id';
  let sortDirection: Lowercase<keyof typeof SortValue> = 'ascending';

  // Item detail dialog
  let itemDialogOpen = false;
  let itemDialogType = 'add';
  let itemData = null;

  let modalPriceType = 'normal';
  let modalPriceCurrent;
  let modalPriceNew;
  let modalItem;
  let modalConfirmButtonDisabled = true;
  let dialogTopPosition = 0;
  let dialogRightPosition = 0;

  $: modalConfirmButtonDisabled = (
      (modalPriceType == 'normal' && (modalPriceNew == '' || modalPriceNew == undefined || modalPriceNew == null)) ||
      (modalPriceNew != undefined && modalPriceNew != '' && ! Number.isFinite(+modalPriceNew.replace(',','.')))
    )

  const handleNewPriceSave = async (e, item, priceType, priceNew) => {
    console.log("=== handleNewPriceSave ===");
    // console.log({e});
    // console.log({item});
    // console.log({priceType});

    priceNew = Number(priceNew.replace(',','.'));

    if (priceType == 'normal') {
      item.priceNew = priceNew;
    } else if (priceType == 'promo') {
      item.pricePromoNew = priceNew;
    } else {
      return;
    }

    const ret = await savePriceChange(item, priceType);

    // Close Price Modal dialog
    document.getElementById("priceModal").close();
  }

  const savePriceChange = async (item, priceType) => {
    console.log("=== savePriceChange ===");
    console.log({item});
    console.log({priceType});

    let country = item.country;
    let countryLocal = country[0].toUpperCase()+country[1].toLowerCase();
    if (priceType == 'normal' && item.priceNew) {
      item["price"+countryLocal] = Number(item.priceNew);
    } else if (priceType == 'promo') {
      item["pricePromo"+countryLocal] = Number(item.pricePromoNew);
    } else {
      console.log("Error: unknown priceType - "+priceType);
      return;
    }

    try {
      const ret = await apiUpdateQuoteSheet({...item, id: item.qsId})
      if (ret) {
        console.log({ret});
        $snackbarSuccess.text = $_("price_updated");
        $snackbarSuccess.element && $snackbarSuccess.element.open();

        items.forEach((i) => {
          if (i.id == item.id) {
            if (priceType == 'normal' && item.priceNew) {
              i["price"+countryLocal] = Number(item.priceNew);
            } else if (priceType == 'promo' && item.pricePromoNew) {
              i["pricePromo"+countryLocal] = Number(item.pricePromoNew);
            }
          }
        });

        item.priceNew = null;
        item.pricePromoNew = null;
        dispatch('qsPriceUpdated', {priceType: priceType, country: countryLocal, item: item,})
      }
    } catch (error) {
      console.log({error});
      error.loc = "savePriceChange";

      if (!handleApiError($_("update_failed_plain"), error)) throw(error);
    }
  }

  const handlePriceModal = ((e, item, priceType) => {
    if (editable) {
      // console.log({e});
      // console.log(e?.target?.parentElement?.getBoundingClientRect())
      // console.log("e.clientX:", e.clientX, "e.clientY:", e.clientY);
      if (!document.getElementById("priceModal").checkVisibility()) {
        modalPriceCurrent = (priceType == 'promo' ? item.pricePromo : item.price);
        modalPriceNew = '';
        modalItem = item;
        modalPriceType = priceType;
        let rect = e?.target?.parentElement?.getBoundingClientRect();
        // dialogTopPosition = rect?.bottom + 10;
        dialogTopPosition = 0;
        dialogRightPosition = rect?.left - rect?.width*2  - (priceType == 'promo' ? 150 : 350);
      }
      if (document.getElementById("priceModal").checkVisibility()) {
        document.getElementById("priceModal").close();
      } else {
        document.getElementById("priceModal").showModal();
      }
    }
  })

  const dispatch = createEventDispatcher();

  // $: {
    // console.log("--------------------- PSQuoteSheetsTable ---------------------");
  //   console.log({purchasedSuppliers});
  //   console.log({proposedPurchaseVolume});
    // console.log({items});
    // console.log({product});
    // console.log({filter});
    // console.log({country});
    // console.log({$appParameters});

  //   console.log("dialogTopPosition:", dialogTopPosition);
  //   console.log("dialogRightPosition:", dialogRightPosition)
  //
  //   console.log({modalPriceType});
  //   console.log({modalPriceNew});
  //   console.log({modalConfirmButtonDisabled});
  // }

</script>

<DataTable
    table$aria-label="Quote Sheets table"
    class="w-100 mt-2"
    >
  <Head>
    <Row>
      <Cell rowspan="2" columnId="actions" class="datatable-header pl-0 pr-0 sticky-col" >
        <Label></Label>
      </Cell>
      <Cell rowspan="2" columnId="supplierId" class="datatable-header right-splitter-sub">
        <Label>{$_('supplier')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="supplierName" class="datatable-header right-splitter-sub">
        <Label>{$_('supplier_name')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="country" class="datatable-header right-splitter-sub">
        <Label>{$_('country')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="currency" class="datatable-header right-splitter-sub">
        <Label>{$_('currency')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="variety" class="datatable-header right-splitter-sub">
        <Label>{$_('variety')}</Label>
      </Cell>
      <Cell rowspan="3" columnId="countryOfOrigin" class="datatable-header text-center wrap-spaces right-splitter-sub bottom-splitter-sub">
        <Label>{$_('country_of_origin')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="cartonLogistics" class="datatable-header text-center wrap-spaces right-splitter-sub">
        <Label>{$_('carton_logistics')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="palletLogistics" class="datatable-header text-center wrap-spaces right-splitter-sub">
        <Label>{$_('pallet_logistics')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="volume" class="datatable-header right-splitter-sub wrap-spaces">
        <Label>{$_('volume')} <small>{$_('participation')}</small>
        </Label>
      </Cell>
      <Cell rowspan="2" columnId="remainingVolume" class="datatable-header text-center wrap-spaces right-splitter-sub">
        <Label>{$_('remaining_volume')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="units" class="datatable-header right-splitter-sub">
        <Label>{$_('units')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="cartonVolume" class="datatable-header text-center wrap-spaces right-splitter-sub">
        <Label>{$_('carton_volume')}</Label>
      </Cell>
      <Cell rowspan="2" columnId="palletVolume" class="datatable-header text-center wrap-spaces right-splitter-sub">
        <Label>{$_('pallet_volume')}</Label>
      </Cell>
      <Cell colspan=3 columnId="price" class="datatable-header text-center wrap-spaces right-splitter-sub bottom-splitter-sub">
        <Label>{$_('offered_price')}</Label>
      </Cell>
      <Cell rowspan=2 columnId="pricePromo" class="datatable-header text-center wrap-spaces right-splitter-sub bottom-splitter-sub">
        <Label>{$_('offered_price_promo')}</Label>
      </Cell>
    </Row>
    <Row>
      <Cell columnId="price" class="datatable-header text-center wrap-spaces right-splitter-sub no-border-radius">
        <Label>{$_('cur')}</Label>
      </Cell>
      <Cell columnId="price1w" class="datatable-header text-center wrap-spaces right-splitter-sub">
        <Label>{$_('lw')}</Label>
      </Cell>
      <Cell columnId="price1y" class="datatable-header text-center wrap-spaces right-splitter-sub no-border-radius">
        <Label>{$_('ly')}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if items.length == 0  || (items.length > 0 && items.filter(i => (filter.length == 0 || filter.includes(i.country)) && (!country || country.length == 0 || country.includes(i.country))).length == 0)}
      <Row>
          <td colspan="18" class="mdc-data-table__cell text-center" style="width: 100%">{$_('no_data')}</td>
      </Row>
    {:else}

      {#each items.filter(i => filter.length == 0 || filter.includes(i.country))
                  .sort((a,b) => [a.country[0].replace('C', '1').replace('S', '2').replace('H', '3'), a.supplierName.toLowerCase()].join(':') >
                                 [b.country[0].replace('C', '1').replace('S', '2').replace('H', '3'), b.supplierName.toLowerCase()].join(':') ? 1 : -1
                  ) as item (item.id)}
        {#if (country && country.length > 0 && country.includes(item.country)) ||
             (!country || country.length == 0)
        }
        {@const itemPurchasedVolume = proposedPurchaseVolume.filter(x => x.quoteSheetId == item.qsId && x.supplierId == item.supplierId).at(0)?.totalVol||0}
        {@const remainingVolume = item.remainingVolume - itemPurchasedVolume}
        {@const logParam = $appParameters["logistics_equal_"+item.country.toLowerCase()]?.value}

          <Row>
            <Cell class="pl-0 pr-0 sticky-col">
              <Flex direction="row">
              {#if !hideBuyIcon && purchasedSuppliers.filter(x =>
                      x.supplierId == item.supplierId).length == 0
              }
                <div title="{$_('buy')}">
                  <IconButton
                      size="button"
                      class="tescoblue-text opacity-1"
                      on:click="{() => dispatch('newPurchase', {...item})}"
                  >
                      <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue-text">
                          <path fill="currentColor" d={mdiCartPlus} />
                      </Icon>
                  </IconButton>
                </div>
              {/if}
              {#if !hideEditIcon}
                <div title="{$_('edit')}">
                  <IconButton
                      size="button"
                      class="tescoblue-text opacity-1"
                      on:click="{() => dispatch('editQuoteSheetRow', {...item})}"
                  >
                      <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue-text">
                          <path fill="currentColor" d={mdiPencilBoxOutline} />
                      </Icon>
                  </IconButton>
                </div>
              {/if}
              </Flex>
            </Cell>
            <Cell class="right-splitter-sub">{item.supplierId}</Cell>
            <Cell class="right-splitter-sub">{item.supplierName}</Cell>
            <Cell class="right-splitter-sub text-center">{item.country}</Cell>
            <Cell class="right-splitter-sub text-center">{item.currency}</Cell>
            <Cell class="right-splitter-sub">{item.variety ? item.variety : ''}</Cell>
            <Cell class="right-splitter-sub text-center text-bold" title="LW: {item.countryOfOrigin1y} &#13;LY: {item.countryOfOrigin1w}">{item.countryOfOrigin}</Cell>
            <Cell class="right-splitter-sub" numeric>
              {#if ['ALL', 'CART'].includes(logParam) && item.lwCartonLogistics && item.cartonLogistics != item.lwCartonLogistics}
                <span class="red lighten-3 pa-2" title="{$_('lw')+': '+item.lwCartonLogistics}">
                    {item.cartonLogistics}
                </span>
              {:else if ['ALL', 'CART'].includes(logParam) && product.cartonLogistics && item.cartonLogistics != product.cartonLogistics}
                <span class="yellow lighten-2 pa-2" title="{$_('product')+': '+product.cartonLogistics}">
                    {item.cartonLogistics}
                </span>
              {:else}
                  {item.cartonLogistics}
              {/if}
            </Cell>
            <Cell class="right-splitter-sub" numeric>
              {#if ['ALL', 'PAL'].includes(logParam) && item.lwPalletLogistics && item.palletLogistics != item.lwPalletLogistics}
                <span class="red lighten-3 pa-2" title="{$_('lw')+': '+item.lwPalletLogistics}">
                    {item.palletLogistics}
                </span>
              {:else if ['ALL', 'PAL'].includes(logParam) && product.palletLogistics && item.palletLogistics != product.palletLogistics}
                <span class="yellow lighten-2 pa-2" title="{$_('product')+': '+product.palletLogistics}">
                    {item.palletLogistics}
                </span>
              {:else}
                  {item.palletLogistics}
              {/if}
            </Cell>
            <Cell class="right-splitter-sub" numeric>{item.volume}<br><small>({item.participation} %)</small>
            </Cell>
            <Cell class="right-splitter-sub" numeric><span class="font-weight-bold {item.remainingVolume == 0 ? 'tescored-text' : 'tescoblue-text'}">{item.remainingVolume}</span>
<!--
              {#if itemPurchasedVolume > 0}
                <br><small>({item.remainingVolume ? item.remainingVolume : ''})</small>
              {/if}
-->
            </Cell>
            <Cell class="right-splitter-sub text-center">{item.units}</Cell>
            <Cell class="right-splitter-sub" numeric>{item.cartonVolume}</Cell>
            <Cell class="right-splitter-sub" numeric>{item.palletVolume}</Cell>
            <Cell class="right-splitter-sub text-right {editable? 'edit-hover' : ''}"

            >
                <span class="price">
                  <span class=" text-bold text-end">{item.price}</span> <br> <div class="black-text"><small><span>{item.priceEur} EUR</span></small></div>
                </span>
                {#if editable}
                <span class="edit" style="display:none" on:click="{(e) => {handlePriceModal(e, item, 'normal')}}" title="{$_('click_to_edit')}">
                    <Icon tag='svg' viewBox="0 0 24 24" class="tescored-text" style="height: 2rem; opacity: .9;">
                        <path fill="currentColor" d={mdiPencilBoxOutline } />
                    </Icon>
<!--                     <span class="text-bold">Click<br>to<br>edit</span> -->
                </span>
                {/if}
            </Cell>
            <Cell class="right-splitter-sub text-right">
              {#if item.priceLW}
                <span class="text-end">{item.priceLW}</span>
                {#if item.priceEurLW > 0}
                  <br> <div class="grey-text"><small><span>{item.priceEurLW} EUR</span></small></div>
                {/if}
              {/if}
            </Cell>
            <Cell class="right-splitter-sub text-right">
              {#if item.priceLY}
                <span class="text-end">{item.priceLY}</span>
                {#if item.priceEurLY > 0}
                  <br> <div class="grey-text"><small><span>{item.priceEurLY} EUR</span></small></div>
                {/if}
              {/if}
            </Cell>
            <Cell class="text-right  {editable? 'edit-hover' : ''}">
              <span class="price">
                {#if item.pricePromo}
                  <span class=" text-bold text-end">{item.pricePromo}</span> <br>
                  <div class="black-text"><small><span>{item.pricePromoEur} EUR</span></small></div>
                {/if}
              </span>
                {#if editable}
                  <span class="edit" style="display:none" on:click="{(e) => {handlePriceModal(e, item, 'promo')}}" title="{$_('click_to_edit')}">
                    <Icon tag='svg' viewBox="0 0 24 24" class="tescored-text" style="height: 2rem; opacity: .9;">
                        <path fill="currentColor" d={mdiPencilBoxOutline } />
                    </Icon>
                  </span>
                {/if}
            </Cell>
          </Row>
        {/if}
      {/each}
    {/if}
  </Body>
</DataTable>

<dialog id="priceModal" style="--price-popup-top: {dialogTopPosition}px; --price-popup-right: -{dialogRightPosition}px;"
       on:keydown="{(e) => {
          console.log(e);
          if (e.keyCode == 13 && !modalConfirmButtonDisabled) {
            handleNewPriceSave(e, modalItem, modalPriceType, modalPriceNew);
          }
        }}"
>
  <h1 class="tescoblue-text ma-0 mb-1">{modalPriceType == 'promo' ? $_('promo_price_change') : $_('price_change')}</h1>
  <DataTable class="w-100">
    <Row>
      <Cell class="tescoblue white-text text-bold"><Label style="width: 3rem;">{$_('current')}:</Label></Cell>
      <DTInputCell
        bind:value={modalPriceCurrent}
        editable={false}
        numeric
        emptyValueUndefined
        />
        <Cell>{modalItem?.currency}</Cell>
    </Row>
    <Row>
        <Cell class="tescoblue white-text text-bold"><Label style="width: 3rem;">{$_('new')}:</Label></Cell>
        <DTInputCell type="text"
            bind:value={modalPriceNew}
            maxlength={15}
            emptyValueUndefined
            autofocus
            style="min-width: 50px; width: 5rem;"
            class="small"

          />
        <Cell>{modalItem?.currency}</Cell>
    </Row>
  </DataTable>
  <Flex direction="row" justify="between" class="w-100 mt-1">
    <Button
      class="white-tescoblue-outlined"
      on:click="{(e) => {document.getElementById("priceModal").close();}}"
    ><Label>{$_('cancel')}</Label></Button>
    <Button
      class="{(modalConfirmButtonDisabled ? 'grey' : 'tescoblue')} white-text"
      disabled={modalConfirmButtonDisabled}
      on:click="{(e) => {
        modalItem.priceNew = modalPriceNew;
        handleNewPriceSave(e, modalItem, modalPriceType, modalPriceNew);
      }}"
    ><Label>{$_('save')}</Label></Button>
  </Flex>

</dialog>

<style>
dialog {
  position: relative;
  top: var(--price-popup-top, 0);
  right: var(--price-popup-right, 0);
  border: none;
  box-shadow: 0px 0px 10px gray;
  border-radius: 4px;
}

dialog[open]::backdrop {
  background-color: rgb(0 0 0 / 25%);
}
</style>
