<script>

    import { createEventDispatcher } from 'svelte';
    import Button from '@smui/button';
    import { Icon } from '@smui/icon-button';
    import { mdiDownloadCircleOutline } from '@mdi/js';
    import { _ } from 'svelte-i18n';

    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

    export let approvalNeeded = false
    export let approvalMessage = '';
    export let disabled = false;

    let confirmationDialogOpen = false;

    const dispatch = createEventDispatcher();

</script>

<section class="w-100 nowrap">
    <Button
        {disabled}
        class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue white-text'} pr-4 nowrap"
        title="{$_('get_data')}"
        on:click="{() => {
            if (approvalNeeded) {
                confirmationDialogOpen = true;
            } else {
                dispatch('get');
            }
        }}"
    >
    <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
        <path fill="currentColor" d={mdiDownloadCircleOutline} />
    </Icon> {$_('get_data')}
    </Button>
</section>

<ConfirmationDialog
    open={confirmationDialogOpen}
    title="{$_('confirmation')}"
    noBtnText="{$_('no')}"
    yesBtnText="{$_('yes')}"
    big={false}
    headerColors="tescoblue white-text"
    on:reject="{() => {confirmationDialogOpen=false}}"
    on:confirm="{() => {
        confirmationDialogOpen = false;
        dispatch('get');
    }}">
    {approvalMessage}
</ConfirmationDialog>

<style>
</style>
