<script>
    import { _ } from 'svelte-i18n';
    import Chip, { Set, LeadingIcon, Text } from '@smui/chips';
    import { Icon } from '@smui/icon-button';
    import { mdiAlert, mdiInformationVariantCircle } from '@mdi/js';
    import { isActionEnabled } from "../../modules/lib/Functions";
    import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog.svelte";
    import { updateWeekStatus as apiUpdateWeekStatus } from "../../../api/Weeks";
    import { weekStatuses, dashboardList } from "../../../stores/AppConfig";
    import { weeks }  from "../../../stores/Weeks";
    import { snackbarSuccess, snackbarWarning, selectedWeek, selectedModule, supplierAmendments, authenticatedUser } from "../../../stores/AppStatus";

    import { handleApiError } from "../lib/errorHandler";


    import Select, { Option } from '@smui/select';

    export let text;
    export let type;
    export let tab = 'quote_sheet';
    export let warning = false;
    export let chip = [1];
    let colors = "tescoblue white-text";
    let dialogOpen =  false;
    let selectedWeekStatus;
    let saveStatusEnabled = false;
    let weekStatusesArray = $weekStatuses.map((x) => {return { id: x.name, label: $_(x.name) };});

    let listEl;
    let menuWidth;
    let menuBottom;


    $: console.log('CHANGED: '+selectedWeekStatus);

    $: if (type == "weekStatus") {
        colors = (isActionEnabled(tab, 'edit', text) || isActionEnabled(tab, 'insert', text) || isActionEnabled(tab, 'delete', text))? 'green white-text': 'tescored white-text';
        text = $_(text);
    } else if (type == "status") {
        text = $_(text);
    } else if (type == "jobType") {
        colors = "grey lighten-2 ";
        if (text == 'primary_job') {
            colors += "tescored-text"
        } else {
            colors += "tescoblue-text"
        }
        text = $_(text);
    } else if (type == "buyerMessage") {
        colors = "transparent tescoblue-text";
    }

    function weekStatusDialogOpen(){
        console.log("==============     weekStatusDialogOpen     =============");
        if ($authenticatedUser.isAdmin){
            if (type == "weekStatus") {
                console.log($selectedWeek.status);
                console.log({selectedWeekStatus});
                dialogOpen = true;
                selectedWeekStatus = $selectedWeek.status;
                saveStatusEnabled = false;
            }
            console.log({selectedWeekStatus});
        }
        console.log("==============     END weekStatusDialogOpen     =============");
    }


    function weekStatusSelected() {
        console.log("==============     weekStatusSelected     =============");
        console.log({selectedWeekStatus});
        if (selectedWeekStatus != $selectedWeek.status) {
            saveStatusEnabled = true;
        } else {
            saveStatusEnabled = false;
        }
        console.log("==============     END weekStatusSelected     =============");
    }


    async function changeWeekStatus() {
        console.log("==============     changeWeekStatus     =============");
        //console.log({$weeks});
        //console.log({$selectedWeek});
        if (selectedWeekStatus != $selectedWeek.status) {
            console.log("Changing Week Status: "+selectedWeekStatus);
            apiUpdateWeekStatus({id: $selectedWeek.id, status: selectedWeekStatus})
            .then((res) => {
                //console.log({ res });
                $snackbarSuccess.text = $_("week_status_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                $selectedWeek.status = selectedWeekStatus;

            })
            .catch((error) => {
                //console.log(error);
                if (!handleApiError($_("week_status_update_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
        }
    }


</script>


<Chip {chip}
    class="normal-cursor square small-chip {colors}"
    style=""
    on:click={weekStatusDialogOpen}
>
    {#if type == "status" && warning }
        <LeadingIcon>
            <Icon tag="svg" viewBox="0 0 24 24" class="amber-text">
                <path fill="currentColor" d={mdiAlert} />
            </Icon>
        </LeadingIcon>
    {/if}
    {#if type == "buyerMessage"}
        <LeadingIcon>
            <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                <path fill="currentColor" d={mdiInformationVariantCircle} />
            </Icon>
        </LeadingIcon>
    {/if}
    <Text class="font-weight-bold">{text}</Text>
</Chip>

<ConfirmationDialog
    open={dialogOpen}
    title={$_("change_week_status")}
    noBtnText={$_("cancel")}
    itemData={null}
    yesBtnText={$_("save")}
    big={false}
    headerColors="tescoblue white-text"
    dataValid={saveStatusEnabled}
    on:reject={() => {
        dialogOpen = false;
    }}
    on:confirm={() => {
        changeWeekStatus();
        dialogOpen = false;
    }}
>

    <Select
        variant="outlined"
        label={$_('week_status')}
        class="white small ma-5 with-parameter w-inherit"
        bind:value={selectedWeekStatus}
        bind:this={listEl}
        key="{(listItem) => `${listItem ? listItem.id : ''}`}"
        menu$class="mdc-menu-surface--fixed with-parameter__menu"
        menu$style="max-width: fit-content !important;"
        style="--mdc-menu-min-width: {menuWidth}px;  --mdc-menu-top: {menuBottom}px"
        on:focusin={() => {
            menuWidth = listEl?.getElement()?.clientWidth;
            menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
        }}
        on:click={() => {
            menuWidth = listEl?.getElement()?.clientWidth;
            menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
        }}
        on:SMUISelect:change={weekStatusSelected}
    >
        <Option value='' />
        {#each weekStatusesArray as statusItem (statusItem.id)}
            <Option value={statusItem.id}>{$_(statusItem.label)}</Option>
        {/each}
    </Select>


</ConfirmationDialog>



<style>
    :global(.normal-cursor) {
        cursor: default !important;
    }
    :global(.small-chip) {
        height: auto !important;
        font-size: .7rem !important;
/*        padding-top: 2px;
        padding-bottom: 2px;*/
    }
    :global(.square) {
        border-radius: 0px 0px !important;
    }
</style>
