<script lang="ts">
    import { _ } from "svelte-i18n";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Textfield from "@smui/textfield";

    import DTInputCell from "../../common/DTInputCell.svelte";
    import DTCurrencySelectCell from "../../common/DTCurrencySelectCell.svelte";

    export let rowData = {
        id: 0,
        currencyId: 1,
        currencySign: "",
        validFrom: "",
        valueInEur: 0,
        currency: "",
    };

    let excludeCur = ["EUR"]; // Exclude EUR

/*     $: console.log({ rowData }); */
</script>

<section class="scroll-on">
    <DataTable class="input-form no-border" >
        <Body>
            <Row>
                <Cell class="form-label" style="padding: 3px;">{$_("currency")}</Cell>
                <DTCurrencySelectCell bind:value={rowData.currencyId} bind:currencySign={rowData.currencySign} {excludeCur}/>
            </Row>
            <Row>
                <Cell class="form-label" style="padding: 3px;">{$_("valid_from")}</Cell>
                <DTInputCell colspan=2 bind:value={rowData.validFrom} editable={true} type="date" />
            </Row>
            <Row>
                <Cell class="form-label" style="padding: 3px;">{$_("value_in_eur")}</Cell>
                <Textfield input$class="text-right w-100" variant="outlined" bind:value={rowData.valueInEur} suffix={rowData.currencySign} input$emptyValueUndefined />
            </Row>
        </Body>
    </DataTable>
</section>

<style>
    section {
        padding: 0.5rem;
    }
</style>
