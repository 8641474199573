import Api from "../services/Api";

// Method to get current List of Supplier Amend
export const getSupplierAmend = async (id, year = null, week = null, amendmentCountryId = null, forecastId = null, roundNo = null, country = null, supplierId = null, warehouseId = null, productId = null, productDetailId = null, purchaseId = null, tpnb) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (amendmentCountryId) params.append('amendmentCountryId', amendmentCountryId);
    if (forecastId) params.append('forecastId', forecastId);
    if (productId) params.append('productId', productId);
    if (productDetailId) params.append('productDetailId', productDetailId);
    if (tpnb) params.append('tpnb', tpnb);
    if (roundNo) params.append('roundNo', roundNo);
    if (country) params.append('country', country);
    if (supplierId) params.append('supplierId', supplierId);
    if (warehouseId) params.append('warehouseId', warehouseId);
    if (purchaseId) params.append('purchaseId', purchaseId);

    console.log('getSupplierAmend params:', params.toString());

    const response = await Api.get("/amendment/supplier", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get current List of Supplier Amend last round
export const getSupplierAmendRound = async (id, year, week, amendmentCountryId, forecastId, roundNo, country, supplierId, warehouseId, productId, productDetailId, purchaseId, tpnb) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (amendmentCountryId) params.append('amendmentCountryId', amendmentCountryId);
    if (forecastId) params.append('forecastId', forecastId);
    if (productId) params.append('productId', productId);
    if (productDetailId) params.append('productDetailId', productDetailId);
    if (tpnb) params.append('tpnb', tpnb);
    if (roundNo) params.append('roundNo', roundNo);
    if (country) params.append('country', country);
    if (supplierId) params.append('supplierId', supplierId);
    if (warehouseId) params.append('warehouseId', warehouseId);
    if (purchaseId) params.append('purchaseId', purchaseId);

    console.log('getSupplierAmendRound params:', params.toString());

    const response = await Api.get("/amendment/supplier/round", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get a List of Supplier Amend Changes (history)
export const getSupplierAuditChanges = async (id) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);

    console.log('getSupplierAudit params:', params.toString());

    const response = await Api.get("/amendment/supplier/audit", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};


// Update Supplier(s) Amend data
export const updateSupplierAmend = async (data, type) => {
  try {
    const url = type ? `/amendment/supplier?type=${type}` : `/amendment/supplier`;
    const response = await Api.post(url, data);
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Method to create next round of Supplier Amendment (when not exists, then 1st round is created)
export const nextRoundSupplierAmend = async (data) => {
  try {
    console.log({ data });
    const response = await Api.post("/amendment/supplier/nextRound", data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};


// Check if Supplier Amend exists
export const supplierAmendExistsData = async (data) => {
  //console.log("supplierAmendExistsData input", { data })
  try {
    const response = await Api.post("/amendment/supplier/exist", data);
    console.log("supplierAmendExistsData response: ", { response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Method to generate XLSX file
export const generateXlsxFile = async (ids) => {
  try {
    const response = await Api.post("/excel/amendmentSupplier", ids);
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Method to download XLSX file by UUID
export const downloadXlsxFile = async (uuid) => {
  try {
    const response = await Api.get(`/excel/extract?uuid=${uuid}`, null, { responseType: 'blob' });
    if (response.status === 200) {
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'file.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      console.error(response.data ? response.data : response.statusText);
      throw new Error(response.data ? response.data : response.statusText);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message);
  }
};





