<script lang="ts">

    import Menu from '@smui/menu';
    import List, { Item, Separator, Text } from '@smui/list';
    import Button, { Group, GroupItem, Label} from '@smui/button';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown } from '@mdi/js';
    import { mdiCheckboxMarked, mdiCheckboxOutline, mdiCropSquare } from '@mdi/js';

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';


    export let product;
    export let products;

    let productsMenu: Menu;
    let productsMenuBtnWidth;

    $: {
    console.log("---------- SELECTOR PRODUCT -----------");
    console.log({product});
    console.log({products});
  }
</script>

{#if products}
    <Group
        id="products-menu-grp"
        class="text-box white-text valign-center ma-0 pa-0 h-100"
    >
        <div use:GroupItem>
            <Button
                title="{product ? [[product.productId, product.productNameEn].join(' - '),  ['(', (product.resolved ? $_('resolved') : $_('unresolved')), ')'].join('')].join(' ') : $_('product')}"
                on:click="{() => {
                    productsMenuBtnWidth = document.getElementById('products-menu-grp').clientWidth;
                    productsMenu.setOpen(true)
                }}"
                variant="raised"
                class="tescoblue-text white-tescoblue-outlined h-100 pt-0 pb-0 pl-4 pr-2"
                style="width: 400px;"
            >
                <Label class="font-weight-medium w-100 fs-14 small nowrap">
                    {#if product}
                        <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue-text" size="button">
                            {#if ((product.resolved ))}
                                <path fill="{$_('green')}" d={mdiCheckboxMarked} />
                            {:else}
                                <path fill="{$_('red')}" d={mdiCropSquare} />
                            {/if}
                        </Icon>
                        <span style="display: inline-block; text-align: left; width: 100%; overflow: hidden; text-overflow: ellipsis; max-width: 330px;">
                            {[product.productId, product.productNameEn].join(' - ')}
                        </span>
                        <span style="position: absolute; top: 0; right: 0; background: linear-gradient(to right, transparent 6px, white 4px, white); padding-left: 10px;">{' (' +product.id + '/' + products.length + ')'}</span>
                    {:else}
                        {$_('choose_product')}
                    {/if}
                </Label>
                <Icon class="pr-0 mr-0 pl-0 ml-1" tag='svg' viewBox="2 2 20 20">
                    <path fill="currentColor" d={mdiMenuDown} />
                </Icon>
            </Button>
            <Menu
                bind:this={productsMenu}
                anchorCorner="BOTTOM_RIGHT"
                class="actions-menu"
                style="min-width: {productsMenuBtnWidth}px"
            >
                <List>
                    {#each products as w}
                        <Item
                            class="fs-14 small {w.productId == product.productId ? 'selected-item' : ''}"
                            on:SMUI:action="{() => {
                                console.log({product});
                                console.log({w});
                                product = w;
                            }}"
                        >
                        <IconButton
                            size="button"
                            class="tescoblue-text opacity-1"
                        >
                             <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue-text" size="button">
                                {#if ((w.resolved ))}
                                    <path fill="{$_('green')}" d={mdiCheckboxMarked} />
                                {:else}
                                    <path fill="{$_('red')}" d={mdiCropSquare} />
                                {/if}
                            </Icon>
                        </IconButton>
                            <Text>{w.productId + ' - ' + w.productNameEn}</Text>
                        </Item>
                    {/each}
                </List>
            </Menu>
        </div>
    </Group>
{/if}

<style>
</style>
