<script>
    import { selectedModule, selectedMenuTab } from "../../stores/AppStatus";

    import IconButton, { Icon } from '@smui/icon-button';

    import { push } from "svelte-spa-router";
    import { _ } from 'svelte-i18n';

</script>

<IconButton class="white-text menu-btn"
            title="{$_('home')}"
            ripple={false}
            on:click="{() => {
                $selectedMenuTab = 'dashboard';
                $selectedModule = '';
                push('/appmenu');
            }}">
    <Icon tag='svg'
        viewBox="0 0 24 24"
        class="home-icon">
        <path fill="none" stroke="currentColor" d="M10 1.5L1.5 8.25V20.5h6v-8h5v8h6V8.25z" />
    </Icon>
</IconButton>
