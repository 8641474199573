<script>
    import { selectedYear, selectedWeek, selectedMenuTab } from "../../../stores/AppStatus";
    import { years, weeks } from "../../../stores/Weeks";

    import { getWeek as apiGetWeek } from "../../../api/Weeks";

    import Button, { Label} from '@smui/button';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown, mdiChevronLeft, mdiChevronRight, mdiCompassOutline } from '@mdi/js';

    import SelectorYear from './SelectorYear.svelte';
    import SelectorWeek from './SelectorWeek.svelte';
    import StatusLabel from './StatusLabel.svelte';

    import Flex from 'svelte-flex';

    import { format } from 'date-fns';
    import { _ } from 'svelte-i18n';


    import { createEventDispatcher } from 'svelte';

    export let disabled = false;

    const dispatch = createEventDispatcher();

    let prevSelectedYear;
    let prevSelectedWeek;

    let prevTescoWeekName;
    let prevSelectedWeekStatus;

    let prevButtonDisabled = false;
    let nextButtonDisabled = false;

    $: {console.log($selectedMenuTab);}

    // Change to the same week when year changes
    $: if ($selectedYear.year != prevSelectedYear) {
        prevSelectedYear = $selectedYear.year;
        let currentWeekNo = $selectedWeek.tesco_week;

        if ( currentWeekNo > $selectedYear.maxWeekNo) {
            currentWeekNo = $selectedYear.maxWeekNo;
        }

        $selectedWeek = $weeks.filter(x => x.tesco_year == $selectedYear.year && x.tesco_week == currentWeekNo ).at(0) || {};
    }

    // Notify when week change
    $: if ($selectedWeek && $selectedWeek.tesco_week_name != prevTescoWeekName ) {
        prevTescoWeekName = $selectedWeek.tesco_week_name;
        refreshCurrentWeekStatus();
        // console.log("dispatch: weekChanged")
        dispatch('weekChanged');
    }

    // Notify on week status change
    $: if ($selectedWeek && $selectedWeek.status != prevSelectedWeekStatus) {
        prevSelectedWeekStatus = $selectedWeek.status;
        // console.log("dispatch: weekStatusChanged")
        dispatch('weekStatusChanged');
    }

    // Disable previous week buton when there is no previous year
    $: if ($selectedWeek.tesco_week == 1 && $years.filter(x => x.year == $selectedWeek.tesco_year -1).length == 0) {
        prevButtonDisabled = true;
    } else {
        prevButtonDisabled = false;
    }


    // Disable previous week buton when there is no previous year
    $: if ($selectedWeek.tesco_week == $selectedYear.maxWeekNo && $years.filter(x => x.year == $selectedWeek.tesco_year + 1).length == 0) {
        nextButtonDisabled = true;
    } else {
        nextButtonDisabled = false;
    }


    // Handle previous week button
    const prevWeek = (() => {

        if (prevButtonDisabled) return;

        let newTW = $selectedWeek.tesco_week - 1;
        if (newTW < 1) {
            if ($years.filter(x => x.year == $selectedWeek.tesco_year-1).length > 0) {
                $selectedYear = $years.filter(x => x.year == $selectedWeek.tesco_year-1).at(0);
                newTW = $selectedYear.maxWeekNo;
            } else {
                newTW = 1;
            }
        }

        console.log({newTW});
        if ($weeks.filter(w => w.tesco_week == newTW && w.tesco_year == $selectedYear.year).length > 0) {
            $selectedWeek = $weeks.filter(w => w.tesco_week == newTW && w.tesco_year == $selectedYear.year).at(0);
        }
    })

    // Handle next week button
    const nextWeek = (() => {

        if (nextButtonDisabled) return;

        let newTW = $selectedWeek.tesco_week + 1;
        console.log({newTW});
        if (newTW > $selectedYear.maxWeekNo) {
            if ($years.filter(x => x.year == $selectedWeek.tesco_year+1).length > 0){
                $selectedYear = $years.filter(x => x.year == $selectedWeek.tesco_year+1).at(0);
                newTW = 1;
            } else {
                newTW = $selectedWeek.maxWeekNo;
            }
        }

        console.log({newTW});
        if ($weeks.filter(w => w.tesco_week == newTW && w.tesco_year == $selectedYear.year).length > 0) {
            $selectedWeek = $weeks.filter(w => w.tesco_week == newTW && w.tesco_year == $selectedYear.year).at(0);
        }
    })

    async function refreshCurrentWeekStatus () {
        try {
            const ret = await apiGetWeek($selectedWeek.id);
            console.log({ret});

            if (ret && ret.length > 0) {
                let updated = false;
                $weeks.forEach(w => {
                    if (w.id == ret.at(0).id && w.status != ret.at(0).status) {
                        w.status = ret.at(0).status;
                        updated = true;
                    }
                })

                if (updated) {
                    console.log("Week status changed!", ret.at(0).status);
                    $selectedWeek = $weeks.filter(x => x.id == ret.at(0).id).at(0);
                    $weeks = $weeks;
                }
                console.log("…done")
            }
        } catch (e) {
            console.log('Error: ', {e});
        }
    }


    // $: {
    //     console.log({$selectedYear});
    //     console.log({$selectedWeek});
    //     console.log({prevTescoWeekName})
    //     console.log({prevSelectedWeekStatus})
    // }

</script>

<section class="">
{#if $selectedWeek}
    <Flex direction="row" justify="start" class="pa-2 gap-1">
        <Flex direction="row">
            <div class="nowrap font-weight-bold">{$_('tesco_week')}</div>
            <SelectorYear
                bind:year={$selectedYear}
                {disabled}
            />
            <Flex direction="row" class="gap-0">
                <IconButton
                    class="{prevButtonDisabled || disabled ? 'disabled' : ''}"
                    title="{$_('previous')}"
                    size="button"
                    disabled="{prevButtonDisabled || disabled}"
                    on:click="{prevWeek}"
                >
                    <Icon class="pr-0 mr-0 pl-0 ml-1 {prevButtonDisabled || disabled ? 'grey-text' : 'tescoblue-text'}" tag='svg' viewBox="2 2 20 20">
                        <path fill="currentColor" d={mdiChevronLeft} />
                    </Icon>
                </IconButton>

                <SelectorWeek
                    bind:week={$selectedWeek}
                    year={$selectedYear}
                    {disabled}
                />

                <IconButton
                    class="{nextButtonDisabled || disabled ? 'disabled' : ''}"
                    title="{$_('next')}"
                    size="button"
                    disabled="{nextButtonDisabled || disabled}"
                    on:click="{nextWeek}"
                >
                    <Icon class="pr-0 mr-0 pl-0 ml-1 {nextButtonDisabled || disabled ? 'grey-text' : 'tescoblue-text'}" tag='svg' viewBox="2 2 20 20">
                        <path fill="currentColor" d={mdiChevronRight} />
                    </Icon>
                </IconButton>
            </Flex>
        </Flex>
        {#if $selectedWeek && $selectedWeek.week_start}
            <div class="nowrap"><span class="font-weight-bold">{$_('week')}</span> {$selectedWeek.year}/{$selectedWeek.week_no}</div>
            <div class="nowrap"><span class="font-weight-bold">{$_('start')}</span> {format(new Date($selectedWeek.week_start), "dd.MM.yyyy")}</div>
            <div class="nowrap"><StatusLabel type="weekStatus" tab={$selectedMenuTab} text={$selectedWeek.status} /></div>
        {/if}
    </Flex>
{/if}
</section>

<style>
    section {
        width: fit-content;
    }
</style>
