import Api from "../services/Api";

// Method to get current List of Weeks
export const getWeeks = async (year) => {
    try {
      const params = new URLSearchParams();

      if (year) params.append('tescoYear', year);
      if (year) params.append('all', 'Y');

      const response = await Api.get("/week/", params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

export const getWeek = async (id) => {
    try {
      const params = new URLSearchParams();

      params.append('id', id);

      const response = await Api.get("/week/", params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Create new Week
export const createWeek = async (data) => {
    try {
      const response = await Api.post("/week", data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Update Week status
export const updateWeekStatus = async (data) => {
    try {
      const params = new URLSearchParams();
      params.append('id', data?.id);

      const response = await Api.put("/week/", params, data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Delete Week
export const deleteWeek = async (id) => {
    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const response = await Api.delete("/week/", params);
      console.log({response});

      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message, {cause: error});
    }
};

// Method to get data for Dashboard
export const getWeekDashboard = async (weekIdFrom, weekIdTo) => {
  try {
    const params = new URLSearchParams();

    if (weekIdFrom) params.append('weekIdFrom', weekIdFrom);
    if (weekIdTo) params.append('weekIdTo', weekIdTo);

    const response = await Api.get("/week/dashboard", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};

