<script lang="ts">
    import { weeks }  from "../../../stores/Weeks";

    import Menu from '@smui/menu';
    import List, { Item, Separator, Text } from '@smui/list';
    import Button, { Group, GroupItem, Label} from '@smui/button';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown } from '@mdi/js';

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';

    export let year;
    export let week;
    export let disabled = false;

    let weeksMenu: Menu;
    let weeksMenuBtnWidth;

</script>

{#if $weeks}
    <Group
        id="weeks-menu-grp"
        class="text-box white-text valign-center ma-0 pa-0 h-100"
    >
        <div use:GroupItem>
            <Button
                title="{$_('week')}"
                on:click="{() => {
                    weeksMenuBtnWidth = document.getElementById('weeks-menu-grp').clientWidth;
                    weeksMenu.setOpen(true)
                }}"
                variant="raised"
                class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} h-100 pt-0 pb-0 pl-4 pr-2"
                {disabled}
            >
                <Label class="font-weight-medium">
                    {#if week}
                        {week.tesco_week}
                    {:else}
                        {$_('choose_week')}
                    {/if}
                </Label>
                <Icon class="pr-0 mr-0 pl-0 ml-1" tag='svg' viewBox="2 2 20 20">
                    <path fill="currentColor" d={mdiMenuDown} />
                </Icon>
            </Button>
            <Menu
                bind:this={weeksMenu}
                anchorCorner="BOTTOM_RIGHT"
                class="actions-menu"
                style="min-width: {weeksMenuBtnWidth}px"
            >
                <List>
                    {#each ($weeks.filter(x => x.tesco_year == year.year && x.tesco_week <= year.maxWeekNo)
                                  .sort((a,b) => a.tesco_week < b.tesco_week ? 1 : -1)) as w}
                        <Item
                            class="fs-14 small {w.current_week ? 'current-week' : ''}"
                            on:SMUI:action="{() => {
                                week = w;
                            }}"
                            title="{w.current_week ? $_('current_week') : ''}"
                        >
                            <Text>{w.tesco_week_name}</Text>
                        </Item>
                    {/each}
                </List>
            </Menu>
        </div>
    </Group>
{/if}

<style>
</style>
