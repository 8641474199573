<script>

    import { unauthorized, authenticatedUser } from "../stores/AppStatus";
    import { push } from "svelte-spa-router";

    // $: {
    //     console.log('Index');
    //     console.log({$unauthorized});
    //     console.log({$authenticatedUser});
    //     console.log({$debugSet});
    //     console.log({$backendDebugEnabled});
    // }

</script>

    {#if $unauthorized || !$authenticatedUser }
        { push('/login') }
    {:else}
        {#if ! $authenticatedUser?.roles || $authenticatedUser?.roles?.length == 0 }
            { push('/unauthorized') }
        {:else}
            { push('/appmenu') }
        {/if}
    {/if}
<style>

</style>
