<script lang="ts">
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button, { Label } from "@smui/button";
    import { _ } from "svelte-i18n";
    import DataTable, { Head, Body, Row, Cell, Pagination, Label as DataLabel } from "@smui/data-table";
    import Select, { Option } from "@smui/select";
    import IconButton from "@smui/icon-button";
    import type { products } from "../../../../types/Management";

    export let isOpen = false;
    export let delegationDetails: products[] = [];

    let headerColors = "tescoblue white-text";

    function closeDialog() {
        isOpen = false;
    }

    // Pagging
    let rowsPerPage = 10;
    let currentPage = 0;

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, delegationDetails.length);
    $: slice = delegationDetails.slice(start, end);
    $: lastPage = Math.max(Math.ceil(delegationDetails.length / rowsPerPage) - 1, 0);
    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }
</script>

<Dialog
    bind:open={isOpen}
    aria-labelledby="details_delegated_products-title"
    aria-describedby="details_delegated_products-content"
    surface$style={"max-width: calc(100vw - 32px);"}
>
    <Title class={headerColors}>{$_("details_delegated_products")}</Title>
    <Content>
        <DataTable stickyHeader table$aria-label="Delegated products" style="width: 100%;">
            <Head>
                <Row>
                    <Cell columnId="productId" class="datatable-header" >
                        <DataLabel>{$_("id")}</DataLabel>
                    </Cell>
                    <Cell columnId="productDescription" class="datatable-header" >
                        <DataLabel>{$_("description")}</DataLabel>
                    </Cell>
                </Row>
            </Head>
            <Body>
                {#if slice.length == 0}
                    <Row class="datatable-content">
                        <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
                    </Row>
                {:else}
                    {#each slice as delegateRow (delegateRow.productId)}
                        <Row>
                            <Cell>{delegateRow.productId}</Cell>
                            <Cell>{delegateRow.productDescription}</Cell>
                        </Row>
                    {/each}
                {/if}
            </Body>

                <Pagination slot="paginate">
                    <svelte:fragment slot="rowsPerPage">
                        <DataLabel>{$_("rows_per_page")}</DataLabel>
                        <Select variant="outlined" bind:value={rowsPerPage} noLabel>
                            <Option value={10}>10</Option>
                            <Option value={25}>25</Option>
                            <Option value={100}>100</Option>
                        </Select>
                    </svelte:fragment>
                    <svelte:fragment slot="total">
                        {start + 1}-{end} of {slice.length}
                    </svelte:fragment>

                    <IconButton class="material-icons" action="first-page" title={$_("first_page")} on:click={() => (currentPage = 0)} disabled={currentPage === 0}
                        >first_page</IconButton
                    >
                    <IconButton class="material-icons" action="prev-page" title={$_("prev_page")} on:click={() => currentPage--} disabled={currentPage === 0}
                        >chevron_left</IconButton
                    >
                    <IconButton class="material-icons" action="next-page" title={$_("next_page")} on:click={() => currentPage++} disabled={currentPage === lastPage}
                        >chevron_right</IconButton
                    >
                    <IconButton class="material-icons" action="last-page" title={$_("last_page")} on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
                        >last_page</IconButton
                    >
                </Pagination>

        </DataTable>
    </Content>
    <Actions>
        <Button action="close" class="tescored tescored-text outlined" on:click={closeDialog}>
            <Label>{$_("cancel")}</Label>
        </Button>
    </Actions>
</Dialog>

<style>
</style>
