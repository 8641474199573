<script lang="ts">
  import { _ } from "svelte-i18n";
  import { mdiPencilBoxOutline, mdiDelete } from "@mdi/js";
  import DataTable, { Head, Body, Row, Cell, Pagination } from "@smui/data-table";
  import CircularProgress from "@smui/circular-progress";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import { Label } from "@smui/common";
  import Switch from "@smui/switch";

  import SuppliersDetail from "./SuppliersDetail.svelte";
  import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";

  import { deleteRow, updateRow, getContact, getSupplierLink } from "../../../../api/Supplier";
  import { snackbarSuccess, authenticatedUser } from "../../../../stores/AppStatus";
  import { handleApiError } from "../../lib/errorHandler";

  import type { CeSupplier } from "../../../../types/Management";

  // Loading
  export let tableLoad = false;
  export let tableSearchMessage = true;
  export let ceSuppliers: CeSupplier[] = [];

  // Delete dialog
  let showConfirmationDialog = false;
  let confirmationDialogQuestion = "";
  let confirmationDialogData = {};

  const showDeleteRowConfirmation = (input) => {
    console.log("Show delte id", input);
    confirmationDialogData = { id: input.id };
    confirmationDialogQuestion = $_("do_you_want_delete_ce_supplier", { values: { id: input.id, name: input.name } });
    showConfirmationDialog = true;
  };

  // Function to delete a supplier
  async function deleteSupplier(id) {
    console.log("Start of deleteSupplier");
    if (confirmationDialogData && id) {
      try {
        let contacts = await getContact("supplier/contact", id);
        console.log("Contacts", contacts);
        for (const contact of contacts) {
          await deleteRow("supplier/contact", contact.id);
        }

        let rmsLinks = await getSupplierLink(null, id);
        console.log("RMS Links", rmsLinks);
        for (const link of rmsLinks) {
          await deleteRow("supplier/link", link.id);
        }

        await deleteRow("supplier/", id);
        ceSuppliers = ceSuppliers.filter((supplier) => supplier.id !== id);
      } catch (error) {
        console.error("Error in deleting process:", error);
        if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
      }
    }
  }

  function handleCeSuppStatusChange(ceSupplier) {
    ceSupplier.status = ceSupplier.status === 1 ? 0 : 1;
    console.log("CE Supp status change", ceSupplier);

    // Update RMS supplier
    updateRow("supplier/", ceSupplier)
      .then((res) => {
        ceSuppliers = ceSuppliers;

        $snackbarSuccess.text = $_("ce_supp_status_updated");
        $snackbarSuccess.element && $snackbarSuccess.element.open();
      })
      .catch((error) => {
        console.log(error);
        if (!handleApiError($_("ce_supp_status_update_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
      });
  }

  // CE Supplier detail dialog
  let ceSupplierDialogType = "add";
  let ceSuppData: CeSupplier[] = [];

  // Filters
  export let showDetails = false;
  export let backClicked = false;

  // Table Head
  let tableHead = [
    { columnId: "actions", label: "", numeric: false, extraClasses: "" },
    { columnId: "id", label: "ce_supplier_no", numeric: false, extraClasses: "" },
    { columnId: "name", label: "ce_supplier_name", numeric: false, extraClasses: "" },
    { columnId: "status", label: "ce_supplier_status", numeric: false, extraClasses: "" },
  ];

  export function openDetails(item = null) {
    ceSupplierDialogType = item ? "edit" : "add";
    ceSuppData = item || {
      id: null,
      name: "",
      status: 1,
    };
    showDetails = true;
  }

  async function closeDetails() {
    showDetails = false;
  }

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, ceSuppliers.length);
  $: currentSlice = ceSuppliers.slice(start, end);
  $: lastPage = Math.max(Math.ceil(ceSuppliers.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  console.log({$authenticatedUser});
</script>

<section hidden={showDetails}>
  <DataTable stickyHeader table$aria-label="Supplier list" style="width: 100%;">
    <Head>
      <Row>
        {#each tableHead as head (head.columnId)}
          <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header {head.extraClasses}">
            <Label>{$_(head.label)}</Label>
          </Cell>
        {/each}
      </Row>
    </Head>
    <Body>
      {#if tableLoad}
        <Row class="datatable-content">
          <Cell colspan="10" class="text-center">
            <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
            {$_("loading_wait")}
          </Cell>
        </Row>
      {:else if currentSlice.length > 0}
        {#each currentSlice as ceSupplier (ceSupplier.id)}
          <Row>
            <Cell class="pl-0 pr-0 sticky-col">
              <!-- Edit button -->
              <IconButton title={$_("edit")} size="button" disabled={!$authenticatedUser.isAdmin} on:click={() => openDetails(ceSupplier)}>
                <svg viewBox="0 0 24 24" class={!$authenticatedUser.isAdmin ? "" : "tescoblue-text"} >
                  <path fill="currentColor" d={mdiPencilBoxOutline} />
                </svg>
              </IconButton>

              <!-- Delete button -->
              <IconButton title={$_("delete")} size="button" disabled={!$authenticatedUser.isAdmin} on:click={() => showDeleteRowConfirmation(ceSupplier)}>
                <svg viewBox="0 0 24 24" class={!$authenticatedUser.isAdmin ? "" : "tescoblue-text"}>
                  <path fill="currentColor" d={mdiDelete} />
                </svg>
              </IconButton>
            </Cell>
            <Cell>{ceSupplier.id}</Cell>
            <Cell>{ceSupplier.name}</Cell>
            <Cell>
              <Switch checked={ceSupplier.status === 1} disabled={!$authenticatedUser.isAdmin} on:click={() => handleCeSuppStatusChange(ceSupplier)} />
              {ceSupplier.status === 1 ? $_("active") : $_("inactive")}
            </Cell>
          </Row>
        {/each}
      {:else if tableSearchMessage}
        <Row class="datatable-content">
          <Cell colspan="10" class="text-center" style="width: 100%">{$_("search_instructions")}</Cell>
        </Row>
      {:else}
        <Row class="datatable-content">
          <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
        </Row>
      {/if}
    </Body>

    <Pagination slot="paginate">
      <svelte:fragment slot="rowsPerPage">
        <Label>{$_("rows_per_page")}</Label>
        <Select variant="outlined" bind:value={rowsPerPage} noLabel>
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
          <Option value={100}>100</Option>
        </Select>
      </svelte:fragment>
      <svelte:fragment slot="total">
        {start + 1}-{end} of {ceSuppliers.length}
      </svelte:fragment>

      <IconButton class="material-icons" action="first-page" title={$_("first_page")} on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
      <IconButton class="material-icons" action="prev-page" title={$_("prev_page")} on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
      <IconButton class="material-icons" action="next-page" title={$_("next_page")} on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
      <IconButton class="material-icons" action="last-page" title={$_("last_page")} on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
        >last_page</IconButton
      >
    </Pagination>
  </DataTable>
</section>

{#if showDetails}
  <SuppliersDetail bind:ceSuppData bind:backClicked onClose={closeDetails} {ceSupplierDialogType} />
{/if}

<ConfirmationDialog
  open={showConfirmationDialog}
  title={$_("delete")}
  noBtnText={$_("cancel")}
  yesBtnText={$_("ok")}
  itemData={null}
  big={false}
  headerColors="tescoblue white-text"
  on:reject={() => {
    showConfirmationDialog = false;
  }}
  on:confirm={() => {
    showConfirmationDialog = false;
    deleteSupplier(confirmationDialogData.id);
  }}
>
  {confirmationDialogQuestion}
</ConfirmationDialog>

<style>
</style>
