import Api from "../services/Api";

// Method to get List of App Parameters
export const getAppParameters = async (id, code, country) => {
    try {
      const response = await Api.get("/parameters");
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};
