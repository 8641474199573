import Api from "../services/Api";

// Method to get data of RMS Product
export const getRMSProduct = async (country, tpnb) => {
  try {
    const params = new URLSearchParams();
    if (country) params.append('country', country);
    if (tpnb) params.append('tpnb', tpnb);

    const response = await Api.get("/api/product", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};


// Method to get data of RMS Product Supplier
export const getRMSProductSupplier = async (country, tpnb) => {
  try {
    const params = new URLSearchParams();
    if (country) params.append('country', country);
    if (tpnb) params.append('tpnb', tpnb);

    const response = await Api.get("/api/partner/unit", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get current RMS Suppliers
export const getRmsSupp = async (country, supplier) => {
  try {
    const params = new URLSearchParams();
    if (country) params.append('country', country);
    if (supplier) params.append('partnerNo', supplier);

    const response = await Api.get("/api/partner", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log("API:", error.response.data);
    if (error.response.data.message === "Not found" && Array.isArray(error.response.data.payload) && error.response.data.payload[0] === null) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message, { cause: error });
    }
  }
};