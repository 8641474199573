<script lang="ts">
  import { onMount } from "svelte";
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Flex from "svelte-flex";
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text";
  import Button, { Label } from "@smui/button";
  import Paper from "@smui/paper";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import { _ } from "svelte-i18n";
  import BuyerTmEmailAutocomplete from "../../common/BuyerTmEmailAutocomplete.svelte";
  import EmailAutocomplete from "../../common/EmailAutocomplete.svelte";
  import { createEventDispatcher } from "svelte";

  // Type
  import type { userDelegationList } from "../../../../types/Management";

  export let open;
  export let updateDelegationObject: userDelegationList | null = null;

  // Date
  const date = new Date();
  const today = date.toISOString().split("T")[0];
  date.setFullYear(date.getFullYear() + 1);
  const oneYearFromToday = date.toISOString().split("T")[0];

  let type = "Buyer";
  let dataValid = false;
  let dateValidFrom = false;
  let dateValidTo = false;
  let dateFrom = today;
  let dateTo = today;
  let toUser = "";
  let fromUser = "";
  let validationMsg = "";
  let fromListEmail = false; // Email has to be from list
  let toListEmail = false;
  let disableUserField = false;
  let disableDateFrom = false;
  let disableDateTo = false;
  let countOpen = 0;

  const dispatch = createEventDispatcher();

  $: if (open && updateDelegationObject && countOpen == 0) {
    countOpen++;
    dateFrom = updateDelegationObject.dateFrom;
    dateTo = updateDelegationObject.dateTo;
    toUser = updateDelegationObject.toUser;
    fromUser = updateDelegationObject.fromUser;
    fromListEmail = true;
    toListEmail = true;
    disableUserField = true;
    disableDateFrom = new Date(dateFrom) < new Date(today);
    disableDateTo = new Date(dateTo) < new Date(today);
  }

  $: console.log("Test", disableUserField, countOpen);

  function clearPopupFields() {
    dateFrom = today;
    dateTo = today;
    toUser = "";
    fromUser = "";
    updateDelegationObject = null;
    countOpen = 0;
    fromListEmail = false;
    toListEmail = false;
    disableUserField = false;
  }

  $: dataValid = dateFrom && dateTo && toUser.length > 0 && toUser != fromUser && dateValidFrom === false && dateValidTo === false && fromListEmail && toListEmail;

  $: if (dateFrom > dateTo && !dateValidFrom) {
    dateTo = dateFrom;
  }
  $: console.log(dateTo);

  $: {
    if (dateFrom === undefined || dateTo === undefined) {
      validationMsg = $_("select_date");
    } else {
      const from = new Date(dateFrom);
      const to = new Date(dateTo);
      const now = new Date(today);
      const oneYear = new Date(oneYearFromToday);

      if (from < now || to > oneYear || from > oneYear || (dateTo && to < now)) {
        validationMsg = $_("date_outside_range");
      } else {
        validationMsg = "";
      }
    }
  }

  function handleSaveClick() {
    if (updateDelegationObject) {
      updateDelegationObject.dateFrom = dateFrom;
      updateDelegationObject.dateTo = dateTo;
      dispatch("updateUserDelegation", { updateDelegationObject });
    } else {
      dispatch("delegateUserPopupSave", { fromUser, toUser, dateFrom, dateTo });
    }
    clearPopupFields();
  }
</script>

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style="width: 1250px; max-width: calc(100vw - 32px);"
>
  <Title id="data-changed-title" class="tescoblue white-text">
    {$_("create_user_delegation")}
  </Title>
  <Content id="data-changed-content" class="grey lighten-3">
    <Flex direction="row" class="grey lighten-3 gap-1 mb-2">
      {#if !updateDelegationObject}
        <Label>{$_("delegate_user_type")}:</Label>

        {#each ["Buyer", "TechnicalManager"] as option}
          <FormField>
            <Radio bind:group={type} value={option} touch on:click={() => clearPopupFields()} />
            <span slot="label">
              {#if option === "Buyer"}
                {$_("by_buyer")}
              {:else if option === "TechnicalManager"}
                {$_("by_technical_manager")}
              {/if}
            </span>
          </FormField>
        {/each}
      {/if}
    </Flex>

    <Flex direction="row" align="start" class="gap-1">
      <Paper elevation={5} class="no-padding">
        <Content>
          <EmailAutocomplete
            disableFields={disableUserField}
            bind:value={fromUser}
            role={type}
            label={$_("from_user")}
            variant="outlined"
            small={true}
            style="min-width: 300px;"
            insideDataTable={true}
            bind:emailFromList={fromListEmail}
          />
        </Content>
      </Paper>

      <Paper elevation={5} style="min-width:450px;" class="no-padding">
        <Content>
          <Flex direction="row" align="start" class="gap-2">
            <Flex direction="column">
              <Textfield
                disabled={disableDateFrom}
                variant="outlined"
                type="date"
                input$min={today}
                input$max={oneYearFromToday}
                label={$_("valid_from")}
                title={$_("valid_from")}
                validateOnValueChange
                useNativeValidation
                updateInvalid
                input$emptyValueUndefined
                bind:value={dateFrom}
                bind:invalid={dateValidFrom}
                input$autocomplete="valid_from"
                required
                class="white small"
              >
                <HelperText validationMsg slot="helper">{validationMsg}</HelperText>
              </Textfield>
            </Flex>
            <Flex direction="column">
              <Textfield
                disabled={disableDateTo}
                variant="outlined"
                type="date"
                input$min={dateFrom}
                input$max={oneYearFromToday}
                label={$_("valid_to")}
                title={$_("valid_to")}
                bind:value={dateTo}
                bind:invalid={dateValidTo}
                updateInvalid
                input$autocomplete="valid_to"
                input$emptyValueUndefined
                required
                class="white small"
              >
                <HelperText validationMsg slot="helper">{validationMsg}</HelperText>
              </Textfield>
            </Flex>
          </Flex>
        </Content>
      </Paper>

      <Paper elevation={5} class="no-padding">
        <Content>
          <EmailAutocomplete
            disableFields={disableUserField}
            bind:value={toUser}
            role={type}
            label={$_("to_user")}
            variant="outlined"
            small={true}
            style="min-width: 300px;"
            insideDataTable={true}
            bind:emailFromList={toListEmail}
          />
        </Content>
      </Paper>
    </Flex>
  </Content>
  <Actions>
    <Button
      action="close"
      class="tescored tescored-text outlined"
      on:click={() => {
        dispatch("delegateUserPopupCancel");
        clearPopupFields();
      }}
    >
      <Label>{$_("cancel")}</Label>
    </Button>
    <Button action="save" defaultAction class={dataValid ? "tescoblue white-text" : "grey white-text"} disabled={!dataValid} on:click={handleSaveClick}>
      <Label>{$_("add")}</Label>
    </Button>
  </Actions>
</Dialog>

<style>
</style>
