<script>
    import Button, { Label} from '@smui/button';

    import { Icon } from '@smui/icon-button';
    import { mdiChevronDoubleLeft } from '@mdi/js';

    import { _ } from 'svelte-i18n';
    import { createEventDispatcher } from 'svelte';

    export let btnColors = 'tescoblue white-text';

    const dispatch = createEventDispatcher();

</script>

<section class="">
    <Button
        class="{btnColors} pr-4"
        title="{$_('back')}"
        on:click="{() => {
            dispatch('goBack');
        }}"
    >
    <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
        <path fill="currentColor" d={mdiChevronDoubleLeft} />
    </Icon> {$_('back')}
    </Button>
</section>

<style>
</style>
