<script lang="ts">
  import { _ } from "svelte-i18n";
  import Dialog, { Title, Content as DialogContent, Actions } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import { errorStore } from '../stores/errorStore';

  let open = false;
  let errorDetails = null;

  errorStore.subscribe(value => {
    open = value.open;
    errorDetails = value.details;
  });

  function closeDialog() {
    errorStore.set({
      open: false,
      details: null
    });
  }

  async function copyToClipboard() {
    if (errorDetails) {
      const errorText = JSON.stringify(errorDetails, null, 2);
      try {
        await navigator.clipboard.writeText(errorText);
        closeDialog;
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  }
</script>

<Dialog bind:open
    id="errorModal"
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="errorModal-title"
    aria-describedby="errorModal-content"
    class="pa-2"    
    >
  <Title id="errorModal-title" class="w-100 pt-2 pb-2 ma-0 tescored white-text text-center top-rounded">
    Error{#if errorDetails}
      : {$_(errorDetails.customErrorMessage ?? "")}
    {/if}
  </Title>
  <DialogContent>
    {#if errorDetails}
      <p><strong>Message:</strong> {errorDetails.message ?? ""}</p>
      <p><strong>URL:</strong> {errorDetails.url ?? ""}</p>
      <p><strong>Error:</strong> {errorDetails.dataError ?? ""}</p>
      <p><strong>Data Message:</strong> {errorDetails.dataMessage ?? ""}</p>
      <p><strong>Data Payload:</strong> {errorDetails.dataPayload ?? ""}</p>
      <p><strong>Team Number:</strong> {errorDetails.teamNumber ?? ""}</p>
      <p><strong>Trace ID:</strong> {errorDetails.traceId ?? ""}</p>
      <p><strong>Trace Parent:</strong> {errorDetails.traceParent ?? ""}</p>
      <p><strong>Fatal:</strong> {errorDetails.fatal ? 'Yes' : 'No'}</p>
    {/if}
  </DialogContent>
  <Actions class="pr-4 gap-1 space-around">
    <Button class="tescoblue white-text" on:click={copyToClipboard}>
      <Label>Copy to Clipboard</Label>
    </Button>
    <Button action="close" class="tescoblue white-text" on:click={closeDialog}>
      <Label>{$_("close")}</Label>
    </Button>
  </Actions>
</Dialog>