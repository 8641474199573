<script lang="ts">

  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Pagination,
    Label,
    SortValue
  } from '@smui/data-table';

  import IconButton, { Icon } from '@smui/icon-button';

  import DTInputCell from '../../common/DTInputCell.svelte';

  import { mdiPencilBoxOutline, mdiDelete } from '@mdi/js';

  import Flex from 'svelte-flex';
  import { _ } from 'svelte-i18n';

  import { createEventDispatcher } from 'svelte';

  export let rows = [];
  export let idType = 'number';

  const dispatch = createEventDispatcher();
</script>

<DataTable
    table$aria-label="DC list"
    class="mt-2"
    >
  <Head>
    <Row>
      <Cell columnId="actions" class="datatable-header">
        <Label></Label>
      </Cell>
      <Cell columnId="id" class="datatable-header">
        <Label>{$_('id')}</Label>
      </Cell>
      <Cell  columnId="description" class="datatable-header">
        <Label>{$_('description')}</Label>
      </Cell>
      <Cell  columnId="active" class="datatable-header">
        <Label>{$_('active')}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if !rows || rows.length == 0}
      <Row>
          <td colspan="3" class="mdc-data-table__cell" style="width: 100%">{$_('no_data')}</td>
      </Row>
    {:else}

      {#each rows.sort((x,y) =>
          idType == 'number' ? (Number(x.id) > Number(y.id) ? 1 : -1) :
                               (x.id.toLocaleLowerCase() > y.id.toLocaleLowerCase() ? 1 : -1)) as row (row.id)}
        <Row>
          <Cell class="pa-0 ma-0">
            <Flex direction="row" class="gap-0">
              <IconButton
                title="{$_('edit')}"
                size="button"
                class="tescoblue-text opacity-1 pa-0 ma-0"
                on:click={() => {
                  dispatch('editRow', row.id)
                }}
              >
                <Icon tag='svg' viewBox="0 0 24 24" class="pa-0 ma-0">
                    <path fill="currentColor" d={mdiPencilBoxOutline} />
                </Icon>
              </IconButton>
              <IconButton
                title="{$_('delete')}"
                size="button"
                class="tescoblue-text opacity-1 pa-0 ma-0"
                on:click={() => {
                  dispatch('deleteRow', row.id)
                }}
              >
                <Icon tag='svg' viewBox="0 0 24 24" class="pa-0 ma-0">
                    <path fill="currentColor" d={mdiDelete} />
                </Icon>
            </IconButton>
            </Flex>
          </Cell>
          <Cell>{row.id}</Cell>
          <Cell>{row.description}</Cell>
          <Cell>{row.active ? $_('yes') : $_('no')} </Cell>
        </Row>
      {/each}
    {/if}
  </Body>

</DataTable>

<style>
</style>
