<script lang="ts">
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Dialog, { Title, Content, Actions } from '@smui/dialog';
    import Button from '@smui/button';
    import { Text } from "@smui/list";
    import { Label } from '@smui/common';
    import { createEventDispatcher } from 'svelte';
    import CircularProgress from "@smui/circular-progress";
    import Autocomplete from "../../../extra/Autocomplete.svelte";

    import Icon from "@smui/textfield/icon";
    import Textfield from "@smui/textfield";
    import HelperText from "@smui/textfield/helper-text";

    import DTInputCell from "../../common/DTInputCell.svelte";
    import DTCountrySelectCell from "../../common/DTCountrySelectCell.svelte";

    import { supplierLinkList } from "../../../../stores/Suppliers";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    export let data;
    export let open = false;
    export let type = 'add';
    export let headerColors = "tescoblue white-text";
    export let alreadyLinkedSuppliers = [];

    let notEditable = true;
    let errorEmailHelper = false;
    let errorSuppNotExists = false;
    let errorAlreadyLinked = false;
    let dataCheck;

    const dispatch = createEventDispatcher();

    let supplierResultList = [];
    let selectedSupplierRow;
    let selectedSupplier = null;
    let buttonSaveDisabled = true;
    let supplierDisabled = true;

    // Set menu width according to parent element
    let suppEl;
    let suppMenuWidth;

    // Status change
    let statusLbl = 'active';

    let suppliersListLoading = false;

    $: if (!$supplierLinkList || (Array.isArray($supplierLinkList) && $supplierLinkList.length == 0)) {
        suppliersListLoading = true;
    } else {
        suppliersListLoading = false;
    }

    $: if (alreadyLinkedSuppliers.includes(data?.country?.toUpperCase() + '-' + data?.rmsSupplierNo)) {
        errorAlreadyLinked = true;
    } else {
        errorAlreadyLinked = false;
    }

    $: saveBtnDisabled = errorSuppNotExists || errorAlreadyLinked || !data.rmsSupplierNo;

    $: if (!selectedSupplier) {
        productSupplierClear();
    }

    // $: {
    //     console.log({supplierResultList});
    // }

    function changeProductSupplierStatus(value) {
        console.log('================= changeProductSupplierStatus ==============');
        console.log({value});
        console.log({data});
        productSupplierEnableSaveButton();
        statusLbl = (value)? 'active': 'inactive';
    }

    function productSupplierEnableSaveButton() {
        console.log('================= productSupplierEnableSaveButton ==============');
        buttonSaveDisabled = false;
    }


    function productSupplierSelected(option){
        // Assign selected row data rowData
        selectedSupplierRow = supplierResultList.find(item => item.supplierId + ' - ' + item.supplierName + ' (' + item.rmsSupplierNo + ' ' + item.rmsSupplierName + ')' === option.detail);
        data.rmsSupplierName = selectedSupplierRow.rmsSupplierName;
        data.rmsSupplierNo = selectedSupplierRow.rmsSupplierNo;
        data.supplierId = selectedSupplierRow.supplierId;
        data.supplierLinkId = selectedSupplierRow.id;
        data.supplierName = selectedSupplierRow.supplierName;
        data.currency = selectedSupplierRow.currency;
        selectedSupplier = selectedSupplierRow.supplierId + ' - ' + selectedSupplierRow.supplierName;
        console.log({data});
    }

    function productSupplierClear() {
        console.log('================= productSupplierClear ==============');
        if (data.country && data.country != null  && data.country != '') {
            supplierDisabled = false;
        } else {
            supplierDisabled = true;
        }
        data.rmsSupplierName = '';
        data.rmsSupplierNo = '';
        data.supplierId = null;
        data.supplierLinkId = null;
        data.supplierName = null;
        data.currency = null;
        selectedSupplier = null;

        errorSuppNotExists = false;
        errorAlreadyLinked = false;

    }


    async function searchSupplierLink(input: string) {
        console.log("====================== searchSupplierLink =========================");

        if (input.length < 3) {
            data.rmsSupplierNo = '';
            data.rmsSupplierName = '';
            return [];
        }

        try {
            let results;
            console.log({data});
            console.log({$supplierLinkList});
            let ucInput = String(input).toUpperCase();
            results = [...$supplierLinkList].filter(x => ((x.supplierName.toUpperCase().includes(ucInput) || (String(x.supplierId).includes(ucInput))  || (String(x.rmsSupplierNo).includes(ucInput))) && (x.country.toUpperCase() == data.country.toUpperCase())));
            supplierResultList = results;
            return results.map((item) => item.text);
        } catch (error) {
            console.error("Error fetching autocomplete data:", error);
            data.rmsSupplierNo = '';
            data.rmsSupplierName = '';
            return [];
        }
    }

    async function fetchSupplierData() {
        // Prevent on load Fetch
        if (type == "add") {
            return [];
        }
        if (!data.supplierId || data.supplierId.toString().length !== 5 || !data.country) {
            productSupplierClear();
            return [];
        }
        try {
            //const response = await getRmsSupp(rowData.country, rowData.rmsSupplierNo);
            dispatch('productCESupplierLink');
            const response = $supplierLinkList;
            if (response && response.length > 0) {
                const [supplier] = response;

                // Update rowData based on the supplier response
/*                 rowData = {
                    ...rowData,
                    id: rowData.id,
                    supplierId: rowData.supplierId,
                    country: supplier.country,
                    rmsSupplierNo: supplier.supplier,
                    rmsSupplierName: supplier.name,
                    currency: supplier.currency,
                    // Convert status
                    status: supplier.status === "A" ? 1 : 0,
                    contact: rowData.contact,
                }; */

                // Check if user did not change data after WS call
                dataCheck = { ...data };
                errorSuppNotExists = false;
            } else {
                console.log("No supplier data received");
            }
        } catch (error) {
            console.error("Error fetching suppliers:", error);
            errorSuppNotExists = true;

            // Clear the fields
            productSupplierClear();
        }
    }


</script>

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style="width: 800px; max-width: calc(100vw - 32px);"
>
    <Title id="data-changed-title" class="{headerColors}">
        {$_(type + '_product_supplier')}
    </Title>
    <Content id="data-changed-content" class="mt-4">
<!--    {#if supplierResultList.length == 0}
        Loading...
    {:else}-->
        <DataTable class="w-100 input-form no-border pa-2">
            <Body>
                <Row>
                    <Cell class="form-label">{$_("country")}</Cell>
                    <DTCountrySelectCell bind:value={data.country} editable={true} showIcon={true} on:SMUISelect:change={productSupplierClear}/>
                </Row>
                <Row>
                    <Cell class="form-label">{$_("ce_supplier")}</Cell>
                    {#if data.country && !suppliersListLoading}
                        <Autocomplete
                            class="w-100 tescoblue-text edit-cell-border"
                            title="{$_('enter_min_chars', {values: {chars: 3}})}"
                            search="{(input) => searchSupplierLink(input)}"
                            invalid={saveBtnDisabled}
                            showMenuWithNoInput={false}
                            minInputSizeToShowMenu=3
                            selectOnExactMatch={true}
                            disabled={supplierDisabled}
                            bind:value={selectedSupplier}
                            bind:this={suppEl}
                            menu$class="mdc-menu-surface--fixed with-parameter__menu"
                            style="--mdc-menu-min-width: {suppMenuWidth}px"
                            on:click="{() => {suppMenuWidth = suppEl?.getElement()?.clientWidth}}"
                            on:focus="{event => {event.detail.target.select()}}"
                            on:blur="{() => {if (!selectedSupplier || selectedSupplier == null || selectedSupplier =='') {productSupplierClear()}}}"
                            on:SMUIAutocomplete:selected="{(option) => {productSupplierSelected(option)}}"
                        >
                            <Textfield
                                    type="text"
                                    bind:value={selectedSupplier}
                                    withTrailingIcon={saveBtnDisabled}
                                    invalid={saveBtnDisabled}
                                    input$maxlength={5}
                                    input$autocomplete="Nothing"
                                    on:input="{() => fetchSupplierData()}"
                                    required
                                    style="min-width: 500px;"
                                    class="w-100"
                                >
                                    <svelte:fragment slot="trailingIcon">
                                        {#if saveBtnDisabled && (errorSuppNotExists || errorAlreadyLinked)}
                                            <Icon class="material-icons red-text">error</Icon>
                                        {/if}
                                    </svelte:fragment>
                                </Textfield>

                        </Autocomplete>
                        {#if saveBtnDisabled && (errorSuppNotExists || errorAlreadyLinked)}
                            <HelperText persistent validationMsg class="red-text">
                                    {#if errorSuppNotExists}
                                        {$_("supplier_not_found")}
                                    {:else if errorAlreadyLinked}
                                        {$_("supplier_already_linked")}
                                    {/if}
                            </HelperText>
                        {/if}
                    {:else if !data.country}
                        <Cell>{$_('country_not_selected')}</Cell>
                    {:else}
                        <Cell class="text-center">
                            <Flex align="center" justify="center" class="gap-1">
                                <CircularProgress style="height: 32px; width: 32px;" indeterminate />
                                <div>{$_('loading_suppliers_wait')}</div>
                            </Flex>
                        </Cell>
                    {/if}
                </Row>
                <Row>
                    <Cell class="form-label">{$_("rms_supplier_no")}</Cell>
                    <DTInputCell bind:value={data.rmsSupplierNo} editable={false} numeric={false} class="w-100 grey lighten-2"/>
                </Row>
                <Row>
                    <Cell class="form-label">{$_("rms_supplier_name")}</Cell>
                    <DTInputCell bind:value={data.rmsSupplierName} editable={false} numeric={false} class="w-100 grey lighten-2" />
                </Row>
                <Row>
                    <Cell class="form-label">{$_("currency")}</Cell>
                    <DTInputCell bind:value={data.currency} editable={false} numeric={false} class="w-100 grey lighten-2" />
                </Row>
            </Body>
        </DataTable>
<!--     {/if} -->
    </Content>

    <Actions>
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click="{() => dispatch('productSupplierCancel')}">
            <Label>{$_('cancel')}</Label>
        </Button>
        <Button
            action="save"
            default
            defaultAction
            disabled="{saveBtnDisabled}"
            class="{(saveBtnDisabled ? 'grey' : 'tescoblue')} white-text"
            on:click="{() => dispatch('productSupplierSave', data)}"
        >
        <Label>{type == 'add' ?  $_('add') : $_('save')}</Label>
        </Button>
    </Actions>

</Dialog>

<style>
</style>
