// Add more icons here as needed, in the import AND export
import { mdiPageNextOutline, mdiSendOutline, mdiCheckOutline, mdiContentSaveOutline, mdiFileUploadOutline } from '@mdi/js';

export const icons = {
    mdiPageNextOutline,
    mdiSendOutline,
    mdiCheckOutline,
    mdiContentSaveOutline,
    mdiFileUploadOutline,
};
