import {getSupplier as apiGetSupplier, getSupplierProduct as apiGetSupplierProduct} from "../../../api/Supplier";


export const loadSuppliers = async (id, rmsSupplierName, status) => {
    var response = await apiGetSupplier(id, rmsSupplierName, status)
    .then((res) => {
        console.log({res});
        var supplierList = [];
        for (let supplier of res) {
            supplier.supplierStatus = true;
            supplier.text = supplier.id + ' - ' + supplier.name;
            supplierList.push({...supplier});
        }
        console.log({supplierList})
        return supplierList;
    })
    .catch((error) => {
        console.log(error);
        if (error?.cause?.response?.status == 401) { throw(error) }
        throw new Error (error.message, {cause: error.cause});
    })

    return response;
};


export const loadSupplierProducts = async (supplierId, productId, status) => {
    var response = await apiGetSupplierProduct(supplierId, productId, status)
    .then((res) => {
        console.log({res});
        var supplierProductList = [];
        for (let supplierProduct of res) {
            // console.log({productSupplier});
            supplierProduct.status = (supplierProduct.status)? true: false;
            supplierProduct.productStatus = true;
            supplierProduct.text = supplierProduct.productId + ' - ' + supplierProduct.productDescription;
            supplierProductList.push({...supplierProduct});
        }

        if (supplierProductList.length == 0) {
            console.log('loadProducts().empty');
        } else {
            supplierProductList = supplierProductList;
        }
        console.log({supplierProductList})
        return supplierProductList;
    })
    .catch((error) => {
        console.log(error);
        if (error?.cause?.response?.status == 401) { throw(error) }
        throw new Error (error.message, {cause: error.cause});
    })

    return response;
};

