<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import CircularProgress from "@smui/circular-progress";
  import { _ } from "svelte-i18n";

  export let items = [];
  export let loadingData = false;
  export let dataLoaded = false;

  // Filters
  export let countrySearchArray = [];
  export let dcSearch = "";
  export let productDescEnSearch = "";
  export let deliveryDateSearch = "";
  export let statusSearchArray = [];

  // Store previous filter value
  let prevCountrySearchArray = [];
  let prevDcSearch;
  let prevProductDescEnSearch;
  let prevDeliveryDateSearch;
  let prevStatusSearchArray = [];

  let filteredData: Data[] = [];


  type Data = {
  purchaseId: number;
  forecastId: number;
  tescoWeek: number;
  countryId: number;
  dcNo: number;
  productId: number;
  supplierId: number;
  rmsSupplierNo: number;
  tpnb: number;
  countryProductName: string;
  countryCode: string;
  dcCode: string;
  buyer: string;
  technicalManager: string;
  articleGroup: string;
  ceTpnName: string;
  countryOfOrigin: string;
  variety: string;
  supplierName: string;
  units: string;
  cartonLogistics: number;
  palletLogistics: number;
  temperature: string;
  totalForecasted: number;
  d1VolSngl: number;
  d2VolSngl: number;
  d3VolSngl: number;
  d4VolSngl: number;
  d5VolSngl: number;
  d6VolSngl: number;
  d7VolSngl: number;
  d1PromoFlag: number;
  d2PromoFlag: number;
  d3PromoFlag: number;
  d4PromoFlag: number;
  d5PromoFlag: number;
  d6PromoFlag: number;
  d7PromoFlag: number;
  flexVolSngl: number;
  totalVolSngl: number;
  d1VolCart: number;
  d2VolCart: number;
  d3VolCart: number;
  d4VolCart: number;
  d5VolCart: number;
  d6VolCart: number;
  d7VolCart: number;
  flexVolCart: number;
  totalVolCart: number;
  d1VolPal: number;
  d2VolPal: number;
  d3VolPal: number;
  d4VolPal: number;
  d5VolPal: number;
  d6VolPal: number;
  d7VolPal: number;
  flexVolPal: number;
  totalVolPal: number;
  deliveryType: string;
  price: number;
  pricePromo: number;
  currency: string;
};


  // Pagging
  let rowsPerPage = 25;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredData.length);
  $: slice = filteredData.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredData.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  // Apply filters
  $: if (
    items &&
    (countrySearchArray.join("") != prevCountrySearchArray.join("") ||
      dcSearch != prevDcSearch ||
      productDescEnSearch != prevProductDescEnSearch ||
      deliveryDateSearch != prevDeliveryDateSearch ||
      statusSearchArray.join("") != prevStatusSearchArray.join(""))
  ) {
    console.log("Apply filters");

    prevCountrySearchArray = [...countrySearchArray];
    prevDcSearch = dcSearch;
    prevProductDescEnSearch = productDescEnSearch;
    prevDeliveryDateSearch = deliveryDateSearch;
    prevStatusSearchArray = [...statusSearchArray];

    filteredData = items.filter(
      (x) =>
        (!countrySearchArray.length > 0 || (countrySearchArray && countrySearchArray.includes(x.country))) &&
        (!dcSearch || (dcSearch && x.dc.toLowerCase().includes(dcSearch.toLowerCase()))) &&
        (!productDescEnSearch || (productDescEnSearch && x.productDescEn.toLowerCase().includes(productDescEnSearch.toLowerCase()))) &&
        (!deliveryDateSearch || (deliveryDateSearch && x.groupName.toLocaleDateString().includes(deliveryDateSearch.toLocaleDateString()))) &&
        (!statusSearchArray.length > 0 || (statusSearchArray && statusSearchArray.includes(x.status))),
    );
    filteredData = filteredData;
    console.log({ filteredData });
  } else {
    if (items) {
      filteredData = items;
    } else {
      filteredData = [];
    }
  }

  $: {
    console.log("---------------------");
    console.log({ items });
    //   console.log("---");
    //   console.log({countrySearch});
    //   console.log({dcSearch});
    //   console.log({productDescEnSearch});
    //   console.log({deliveryDateSearch});
    //   console.log({statusSearch});
    //   console.log("---");
    //   console.log({filteredData});
    //   console.log({slice});
    //   console.log({selected})
  }
</script>

<DataTable table$aria-label="report" class="w-100 mt-2 report">
  <Head>
    <Row style="height: 1rem !important;">
      <Cell colspan="21" class="datatable-header bottom-border-tescoblue"></Cell>
      <Cell colspan="8" class="datatable-header bottom-border-tescoblue left-splitter text-center">{$_("singles")}</Cell>
      <Cell colspan="8" class="datatable-header bottom-border-tescoblue left-splitter text-center">{$_("cases_kartons")}</Cell>
      <Cell colspan="8" class="datatable-header bottom-border-tescoblue left-splitter text-center right-splitter">{$_("pallets")}</Cell>
      <Cell colspan="3" class="datatable-header bottom-border-tescoblue text-center">{$_("agreed")}</Cell>
    </Row>
    <Row style="height: 2rem !important;">
      <Cell columnId="TescoWeek" class="datatable-header no-border-radius text-center wrap-spaces" numeric>
        <Label>{$_("tesco_week")}</Label>
      </Cell>
      <Cell columnId="CountryId" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("country_id")}</Label>
      </Cell>
      <Cell columnId="DCNo" class="datatable-header " numeric>
        <Label>{$_("dc_no")}</Label>
      </Cell>
      <Cell columnId="GFSTPN" class="datatable-header {slice.length != 0 ? "sticky-col" : ""} text-center" numeric>
        <Label>{$_("gfs_tpn")}</Label>
      </Cell>
      <Cell columnId="SupplierIDGFS" class="datatable-header {slice.length != 0 ? "sticky-col ds left-1col" : ""}  text-center wrap-spaces" style="min-width: 110px;">
        <Label>{$_("supplier_id_gfs")}</Label>
      </Cell>
      <Cell columnId="SupplierIdCountry" class="datatable-header text-center wrap-spaces" style="min-width: 110px;">
        <Label>{$_("supplier_id_country")}</Label>
      </Cell>
      <Cell columnId="TPNCountry" class="datatable-header" numeric>
        <Label>{$_("country_tpn")}</Label>
      </Cell>
      <Cell columnId="ProductNameCountry" class="datatable-header">
        <Label>{$_("product_name_country")}</Label>
      </Cell>
      <Cell columnId="Country" class="datatable-header">
        <Label>{$_("country")}</Label>
      </Cell>
      <Cell columnId="DC" class="datatable-header">
        <Label>{$_("dc")}</Label>
      </Cell>
      <Cell columnId="Buyer" class="datatable-header">
        <Label>{$_("buyer")}</Label>
      </Cell>
      <Cell columnId="ArticleGroup" class="datatable-header text-center wrap-spaces">
        <Label>{$_("article_group")}</Label>
      </Cell>
      <Cell columnId="ProductEN" class="datatable-header">
        <Label>{$_("product_en")}</Label>
      </Cell>
      <Cell columnId="CountryOfOrigin" class="datatable-header text-center wrap-spaces">
        <Label>{$_("country_of_origin")}</Label>
      </Cell>
      <Cell columnId="Variety" class="datatable-header">
        <Label>{$_("variety")}</Label>
      </Cell>
      <Cell columnId="Supplier" class="datatable-header">
        <Label>{$_("supplier")}</Label>
      </Cell>
      <Cell columnId="Unit" class="datatable-header">
        <Label>{$_("unit")}</Label>
      </Cell>
      <Cell columnId="KartonLogistic" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("carton_logistic")}</Label>
      </Cell>
      <Cell columnId="PalletLogistic" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("pallet_logistic")}</Label>
      </Cell>
      <Cell columnId="Temperature" class="datatable-header" numeric>
        <Label>{$_("temperature")}</Label>
      </Cell>
      <Cell columnId="ForecastDemand" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("forecast_demand")}</Label>
      </Cell>

      <Cell columnId="SinglesMon" class="datatable-header left-splitter" numeric>
        <Label>{$_("mon")}</Label>
      </Cell>
      <Cell columnId="SinglesTue" class="datatable-header" numeric>
        <Label>{$_("tue")}</Label>
      </Cell>
      <Cell columnId="SinglesWed" class="datatable-header" numeric>
        <Label>{$_("wed")}</Label>
      </Cell>
      <Cell columnId="SinglesThu" class="datatable-header" numeric>
        <Label>{$_("thu")}</Label>
      </Cell>
      <Cell columnId="SinglesFri" class="datatable-header" numeric>
        <Label>{$_("fri")}</Label>
      </Cell>
      <Cell columnId="SinglesSat" class="datatable-header" numeric>
        <Label>{$_("sat")}</Label>
      </Cell>
      <Cell columnId="SinglesSun" class="datatable-header" numeric>
        <Label>{$_("sun")}</Label>
      </Cell>
      <Cell columnId="SinglesTotal" class="datatable-header" numeric>
        <Label>{$_("total")}</Label>
      </Cell>

      <Cell columnId="CasesMon" class="datatable-header left-splitter" numeric>
        <Label>{$_("mon")}</Label>
      </Cell>
      <Cell columnId="CasesTue" class="datatable-header" numeric>
        <Label>{$_("tue")}</Label>
      </Cell>
      <Cell columnId="CasesWed" class="datatable-header" numeric>
        <Label>{$_("wed")}</Label>
      </Cell>
      <Cell columnId="CasesThu" class="datatable-header" numeric>
        <Label>{$_("thu")}</Label>
      </Cell>
      <Cell columnId="CasesFri" class="datatable-header" numeric>
        <Label>{$_("fri")}</Label>
      </Cell>
      <Cell columnId="CasesSat" class="datatable-header" numeric>
        <Label>{$_("sat")}</Label>
      </Cell>
      <Cell columnId="CasesSun" class="datatable-header" numeric>
        <Label>{$_("sun")}</Label>
      </Cell>
      <Cell columnId="CasesTotal" class="datatable-header" numeric>
        <Label>{$_("total")}</Label>
      </Cell>

      <Cell columnId="PalletMon" class="datatable-header left-splitter" numeric>
        <Label>{$_("mon")}</Label>
      </Cell>
      <Cell columnId="PalletTue" class="datatable-header" numeric>
        <Label>{$_("tue")}</Label>
      </Cell>
      <Cell columnId="PalletWed" class="datatable-header" numeric>
        <Label>{$_("wed")}</Label>
      </Cell>
      <Cell columnId="PalletThu" class="datatable-header" numeric>
        <Label>{$_("thu")}</Label>
      </Cell>
      <Cell columnId="PalletFri" class="datatable-header" numeric>
        <Label>{$_("fri")}</Label>
      </Cell>
      <Cell columnId="PalletSat" class="datatable-header" numeric>
        <Label>{$_("sat")}</Label>
      </Cell>
      <Cell columnId="PalletSun" class="datatable-header" numeric>
        <Label>{$_("sun")}</Label>
      </Cell>
      <Cell columnId="PalletTotal" class="datatable-header right-splitter" numeric>
        <Label>{$_("total")}</Label>
      </Cell>
      <Cell columnId="AgreedPrice" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("price")}</Label>
      </Cell>
      <Cell columnId="AgreedPromo" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("promo")}</Label>
      </Cell>
      <Cell columnId="AgreedCurrency" class="datatable-header no-border-radius text-center text-center wrap-spaces">
        <Label>{$_("currency")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center;">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice && slice.length == 0}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center; height: 3rem !important;">
          {dataLoaded ? $_("no_data_for_week") : $_("load_week_data")}
        </td>
      </Row>
    {:else}
      {#each slice as data (data.purchaseId)}
        <Row>
          <Cell numeric>{data.tescoWeek}</Cell>
          <Cell numeric>{data.countryId ? data.countryId : ""}</Cell>
          <Cell numeric>{data.dcNo > 0 ? data.dcNo : ""}</Cell>
          <Cell class="sticky-col" numeric>{data.productId}</Cell>
          <Cell class="sticky-col ds left-1col" numeric>{data.supplierId}</Cell>
          <Cell numeric>{data.rmsSupplierNo}</Cell>
          <Cell numeric>{data.tpnb}</Cell>
          <Cell>{data.countryProductName}</Cell>
          <Cell class="text-center">{data.countryCode}</Cell>
          <Cell class="text-center">{data.dcCode}</Cell>
          <Cell>{data.buyer}</Cell>
          <Cell>{data.articleGroup}</Cell>
          <Cell>{data.ceTpnName}</Cell>
          <Cell>{data.countryOfOrigin}</Cell>
          <Cell>{data.variety ? data.variety : ""}</Cell>
          <Cell>{data.supplierName}</Cell>
          <Cell class="text-center">{data.units}</Cell>
          <Cell class="text-center">{data.cartonLogistics}</Cell>
          <Cell class="text-center">{data.palletLogistics}</Cell>
          <Cell class="text-center">{data.temperature}</Cell>
          <Cell numeric>{data.totalForecasted}</Cell>
          <Cell numeric class="left-splitter-black">{data.d1PromoFlag ? data.d1VolSngl + ' *' : data.d1VolSngl}</Cell>
          <Cell numeric>{data.d2PromoFlag ? data.d2VolSngl + ' *' : data.d2VolSngl}</Cell>
          <Cell numeric>{data.d3PromoFlag ? data.d3VolSngl + ' *' : data.d3VolSngl}</Cell>
          <Cell numeric>{data.d4PromoFlag ? data.d4VolSngl + ' *' : data.d4VolSngl}</Cell>
          <Cell numeric>{data.d5PromoFlag ? data.d5VolSngl + ' *' : data.d5VolSngl}</Cell>
          <Cell numeric>{data.d6PromoFlag ? data.d6VolSngl + ' *' : data.d6VolSngl}</Cell>
          <Cell numeric>{data.d7PromoFlag ? data.d7VolSngl + ' *' : data.d7VolSngl}</Cell>
          <Cell numeric>{data.totalVolSngl?.toFixed(2)?.replace(".00", "")}</Cell>
          <Cell numeric class="left-splitter-black">{data.d1PromoFlag ? data.d1VolCart + ' *' : data.d1VolCart}</Cell>
          <Cell numeric>{data.d2PromoFlag ? data.d2VolCart + ' *' : data.d2VolCart}</Cell>
          <Cell numeric>{data.d3PromoFlag ? data.d3VolCart + ' *' : data.d3VolCart}</Cell>
          <Cell numeric>{data.d4PromoFlag ? data.d4VolCart + ' *' : data.d4VolCart}</Cell>
          <Cell numeric>{data.d5PromoFlag ? data.d5VolCart + ' *' : data.d5VolCart}</Cell>
          <Cell numeric>{data.d6PromoFlag ? data.d6VolCart + ' *' : data.d6VolCart}</Cell>
          <Cell numeric>{data.d7PromoFlag ? data.d7VolCart + ' *' : data.d7VolCart}</Cell>
          <Cell numeric>{data.totalVolCart?.toFixed(2)?.replace(".00", "")}</Cell>
          <Cell numeric class="left-splitter-black">{data.d1PromoFlag ? data.d1VolPal + ' *' : data.d1VolPal}</Cell>
          <Cell numeric>{data.d2PromoFlag ? data.d2VolPal + ' *' : data.d2VolPal}</Cell>
          <Cell numeric>{data.d3PromoFlag ? data.d3VolPal + ' *' : data.d3VolPal}</Cell>
          <Cell numeric>{data.d4PromoFlag ? data.d4VolPal + ' *' : data.d4VolPal}</Cell>
          <Cell numeric>{data.d5PromoFlag ? data.d5VolPal + ' *' : data.d5VolPal}</Cell>
          <Cell numeric>{data.d6PromoFlag ? data.d6VolPal + ' *' : data.d6VolPal}</Cell>
          <Cell numeric>{data.d7PromoFlag ? data.d7VolPal + ' *' : data.d7VolPal}</Cell>
          <Cell numeric class="right-splitter-black">{data.totalVolPal?.toFixed(2)?.replace(".00", "")}</Cell>
          <Cell numeric>{data.price}</Cell>
          <Cell numeric>{data.pricePromo ? data.pricePromo : '-'}</Cell>
          <Cell class="text-center">{data.currency}</Cell>
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredData.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}>last_page</IconButton>
  </Pagination>
</DataTable>

<style>
</style>
