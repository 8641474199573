<script lang="ts">
    import Flex from "svelte-flex";
    import Paper from "@smui/paper";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";

    import { _ } from "svelte-i18n";
    import { handleApiError } from "../../lib/errorHandler";

    import { snackbarSuccess } from "../../../../stores/AppStatus";

    import UserPopup from "./DUPopup.svelte";
    import UserDataTable from "./DUDataTable.svelte";
    import UserFilterBar from "./DUFilterBar.svelte";
    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";

    // Type
    import type { userDelegationList } from "../../../../types/Management";

    // API
    import { getUserDelegation, createUserDelegation, deleteUserDelegation, updateUserDelegation } from "../../../../api/Delegate.js";

    let delegationList: userDelegationList[] = [];
    let updateDelegationObject: userDelegationList | null = null;
    let delegateUserPopupOpen = false;

    let tableSearchMessage = false;
    let tableLoad = false;
    let showDeleteUserDelegationConfirmationDialog = false;

    function apiGetUserDelegation(userFromSearch, userToSearch, validFromSearch, validToSearch) {
        getUserDelegation(userFromSearch, userToSearch, validFromSearch, validToSearch)
            .then((response) => {
                delegationList = response;
                console.log("User delegation List Updated", delegationList);

                if (delegationList.length == 0) {
                    console.log("getUserDelegation().empty");
                    tableSearchMessage = false;
                } else if (delegationList.length == null) {
                    console.log("getUserDelegation().null");
                    tableSearchMessage = false;
                }
                tableLoad = false;
            })
            .catch((error) => {
                console.error("Error loading user delegation list:", error);
                if (error?.cause?.response?.status == 401) { throw(error) }
                tableLoad = false;
            });
    }

    function apiCreateUserDelegation(data) {
        createUserDelegation(data)
            .then((res) => {
                data.id = res[0].id;
                delegationList = [data, ...delegationList];
                $snackbarSuccess.text = $_("user_delegation_created");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("user_delegation_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    }

    function apiDeleteUserDelegation(id) {
        deleteUserDelegation(id)
            .then((res) => {
                // delete the id from delegationList
                delegationList = delegationList.filter((deleg) => deleg.id !== id);
                $snackbarSuccess.text = $_("user_delegation_deleted");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("user_delegation_delete_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    }

    function apiUpdateUserDelegation(data) {
        updateUserDelegation(data)
            .then((res) => {
                const index = delegationList.findIndex(item => item.id === data.id);
                if (index !== -1) {
                    // Update the found delegation
                    delegationList[index] = { ...delegationList[index], dateFrom: data.dateFrom, dateTo: data.dateTo };
                    // Reassign delegationList to trigger reactivity
                    delegationList = [...delegationList];
                }
                $snackbarSuccess.text = $_("user_delegation_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("user_delegation_update_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    }
    let confirmationDialogQuestion = "";
    let confirmationDialogData = {};
    const showDeleteRowConfirmation = (input) => {
        confirmationDialogData = input.id;
        confirmationDialogQuestion = $_("delete_user_delegation?", { values: { fromUser: input.fromUser, toUser: input.toUser } });
        showDeleteUserDelegationConfirmationDialog = true;
    };

    const handleDelegateUserEvents = (event) => {
        console.log("handlePurchaseEvents");
        console.log({ event });

        switch (event.type) {
            case "delegateUserPopupOpen":
                console.log("Delegate User popup Open");
                delegateUserPopupOpen = true;
                break;
            case "delegateUserPopupOpenEdit":
                console.log("Delegate User popup Open");
                updateDelegationObject = event.detail;
                delegateUserPopupOpen = true;
                break;
            case "delegateUserPopupCancel":
                console.log("Delegate User popup Cancel");
                delegateUserPopupOpen = false;
                break;
            case "delegateUserPopupSave":
                console.log("Delegate User popup Save", event.detail);
                apiCreateUserDelegation(event.detail);
                delegateUserPopupOpen = false;
                break;
            case "deleteUserDelegation":
                console.log("Delete User Delegation Dialog");
                showDeleteRowConfirmation(event.detail);
                break;
            case "loadList":
                console.log("loadList");
                tableLoad = true;
                // Extract the parameters from event.detail
                const { userFromSearch, userToSearch, validFromSearch, validToSearch } = event.detail;
                apiGetUserDelegation(userFromSearch, userToSearch, validFromSearch, validToSearch);
                break;
            case "updateUserDelegation":
                delegateUserPopupOpen = false;
                console.log("updateUserDelegation", event.detail.updateDelegationObject);
                apiUpdateUserDelegation(event.detail.updateDelegationObject);
                break;
            case "clearList":
                console.log("clearList");
                delegationList = [];
                tableSearchMessage = true;
                tableLoad = false;
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    $: {
        console.log("DelegateUserManagement");
    }
</script>

<section class="w-100 header top-splitter">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3 pr-3" justify="between">
        <Flex direction="row" justify="between" class="gap-1 w-100">
            <div class="w-100 font-weight-bold">
                {$_("delegate_user")}
            </div>
            <Flex direction="row" justify="end" class="w-100 gap-1">
                <Button
                    class="tescoblue-text white-tescoblue-outlined pr-4 nowrap"
                    title={$_("add_item")}
                    on:click={() => {
                        console.log("Delegate User popup Open");
                        delegateUserPopupOpen = true;
                    }}
                >
                    <Icon class="material-icons">add_circle</Icon>
                    {$_("add_user_delegation")}
                </Button>
            </Flex>
        </Flex>
    </Flex>
</section>

<section class="data tescoblue-border-top-thin">
    <Paper class="grey lighten-3 w-100 bs-bb pt-3">
        <div class="smui-paper__content bs-bb">
            <section class="mt-2 pt-2 w-100">
                <UserFilterBar on:loadList={handleDelegateUserEvents} on:clearList={handleDelegateUserEvents} />
            </section>

            <section class="mt-2 pt-2 w-100">
                <UserDataTable
                    {delegationList}
                    {tableLoad}
                    {tableSearchMessage}
                    on:deleteUserDelegation={handleDelegateUserEvents}
                    on:delegateUserPopupOpenEdit={handleDelegateUserEvents}
                />
            </section>
        </div>
    </Paper>
</section>

<UserPopup
    open={delegateUserPopupOpen}
    {updateDelegationObject}
    on:delegateUserPopupCancel={handleDelegateUserEvents}
    on:delegateUserPopupSave={handleDelegateUserEvents}
    on:updateUserDelegation={handleDelegateUserEvents}
></UserPopup>

<ConfirmationDialog
    open={showDeleteUserDelegationConfirmationDialog}
    title={$_("delete_user_delegation")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showDeleteUserDelegationConfirmationDialog = false;
    }}
    on:confirm={() => {
        showDeleteUserDelegationConfirmationDialog = false;
        apiDeleteUserDelegation(confirmationDialogData);
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>
