<script>

    import Button, { Label} from '@smui/button';

    import { Icon } from '@smui/icon-button';
    import { mdiDelete } from '@mdi/js';

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';
    import { createEventDispatcher } from 'svelte';

    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

    export let title = $_('quote_sheet');
    export let dialogOpen = false;

    const dispatch = createEventDispatcher();

</script>

<Flex direction="row" justify="start" class="gap-1">
    <Button
        class="tescoblue-text white-tescoblue-outlined pr-4 nowrap"
        title="{$_('clear_all')}"
        on:click="{() => dialogOpen=true}"
    >
    <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
        <path fill="currentColor" d={mdiDelete} />
    </Icon> {$_('clear_all')}
    </Button>

</Flex>

<ConfirmationDialog
    open={dialogOpen}
    title={title}
    noBtnText="{$_('no')}"
    yesBtnText="{$_('yes')}"
    big={false}
    headerColors="tescoblue white-text"
    on:reject="{() => {dialogOpen=false}}"
    on:confirm="{() => {
        dialogOpen=false;
        dispatch('clearAllData');
    }}">
    {$_('really_clear_all_data')}
</ConfirmationDialog>
