<script>

    import { createEventDispatcher } from 'svelte';

    import Button, { Label} from '@smui/button';

    import { Icon } from '@smui/icon-button';
    import { mdiDownloadCircleOutline } from '@mdi/js';

    import { push } from "svelte-spa-router";
    import { _ } from 'svelte-i18n';

    const dispatch = createEventDispatcher();

</script>

<Button
    class="tescoblue white-text pr-4"
    title="{$_('show_report')}"
    on:click="{() => dispatch('get')}"
>
<Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
    <path fill="currentColor" d={mdiDownloadCircleOutline} />
</Icon> {$_('show_report')}
</Button>

<style>
</style>
