<script>
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Button from "@smui/button";
    import FormField from '@smui/form-field';
    import Checkbox from "@smui/checkbox";
    import { Icon } from "@smui/icon-button";
    import { mdiFileUploadOutline } from "@mdi/js";
    import Flex from 'svelte-flex';

    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

    export let approvalNeeded = false;
    export let approvalMessage = "do_you_want_close_forecasts";
    export let title = "close_forecasts"
    export let disabled = false;
    export let showNotifyFlag = false;

    let confirmationDialogOpen = false;
    let notifyUsersFlag = false;

    const dispatch = createEventDispatcher();
</script>

<section class="nowrap">
    <Button
        class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
        title={$_(title)}
        {disabled}
        on:click={() => {
            if (approvalNeeded) {
                notifyUsersFlag = true;
                confirmationDialogOpen = true;
            } else {
                dispatch("changeStatus");
            }
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={mdiFileUploadOutline} />
        </Icon>
        {$_(title)}
    </Button>
</section>

<ConfirmationDialog
    open={confirmationDialogOpen}
    title={$_("confirmation")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmationDialogOpen = false;
    }}
    on:confirm={() => {
        confirmationDialogOpen = false;
        dispatch("changeStatus", {notifyUsersFlag: (showNotifyFlag ? notifyUsersFlag : false)});
    }}
>
    <Flex direction="column">
        <div class="tescoblue-text fs-1p2rem">{approvalMessage}</div>
        {#if showNotifyFlag}
        <FormField style="align-self: start;">
            <Checkbox
                bind:checked={notifyUsersFlag}
            />
            <span slot="label">{$_('notify_users')}</span>
        </FormField>
        {/if}
    </Flex>


</ConfirmationDialog>

<style>
</style>
