<script lang="ts">
    import { _ } from "svelte-i18n";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Switch from "@smui/switch";
    import DTInputCell from "../../common/DTInputCell.svelte";
    import DTCountrySelectCell from "../../common/DTCountrySelectCell.svelte";

    export let rowData;

    $: {
        console.log({ rowData });
    }
</script>

<section class="scroll-on">
    <DataTable class="w-100 input-form no-border pa-2">
        <Body>
            <Row>
                <Cell class="form-label">{$_("id")}</Cell>
                <Cell class="w-100 grey lighten-2">
                    {rowData.id}
                </Cell>
            </Row>
            <Row>
                <Cell class="form-label">{$_("country")}</Cell>
                <DTCountrySelectCell bind:value={rowData.country} editable={true} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("code")}</Cell>
                <DTInputCell bind:value={rowData.code} editable={true} numeric={false} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("site")}</Cell>
                <DTInputCell bind:value={rowData.site} editable={true} numeric={false} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("live")}</Cell>
                <Cell><Switch bind:checked={rowData.live} /></Cell>
            </Row>
            <Row>
                <Cell class="form-label">{$_("name")}</Cell>
                <DTInputCell bind:value={rowData.name} editable={true} numeric={false} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("address")}</Cell>
                <DTInputCell bind:value={rowData.location} editable={true} numeric={false} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("participation")}</Cell>
                <DTInputCell bind:value={rowData.participation} editable={true} numeric={false} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("display_order")}</Cell>
                <DTInputCell bind:value={rowData.displayOrder} editable={true} numeric={false} />
            </Row>
        </Body>
    </DataTable>
</section>

<style>
    section {
        padding: 0.5rem;
    }
</style>
