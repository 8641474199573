<script lang="ts">

    import Menu from '@smui/menu';
    import List, { Item, Separator, Text } from '@smui/list';
    import Button, { Group, GroupItem, Label} from '@smui/button';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown } from '@mdi/js';

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';


    export let supplier;
    export let suppliers;

    let suppliersMenu: Menu;
    let suppliersMenuBtnWidth;

    $: {
    console.log("---------- SELECTOR SUPPLIER -----------");
    console.log({supplier});
    console.log({suppliers});
  }
</script>

{#if suppliers}
    <Group
        id="suppliers-menu-grp"
        class="text-box white-text valign-center ma-0 pa-0 h-100"
    >
        <div use:GroupItem>
            <Button
                title="{$_('supplier')}"
                on:click="{() => {
                    suppliersMenuBtnWidth = document.getElementById('suppliers-menu-grp').clientWidth;
                    suppliersMenu.setOpen(true)
                }}"
                variant="raised"
                class="tescoblue-text white-tescoblue-outlined h-100 pt-0 pb-0 pl-4 pr-2"
                style="min-width: 200px; text-align: left"
            >
                <Label class="font-weight-medium w-100">
                    {#if supplier}
                        {supplier.id + ' - ' + supplier.supplierName}
                    {:else}
                        {$_('select_supplier')}
                    {/if}
                </Label>
                <Icon class="pr-0 mr-0 pl-0 ml-1" tag='svg' viewBox="2 2 20 20">
                    <path fill="currentColor" d={mdiMenuDown} />
                </Icon>
            </Button>
            <Menu
                bind:this={suppliersMenu}
                anchorCorner="BOTTOM_RIGHT"
                class="actions-menu"
                style="min-width: {suppliersMenuBtnWidth}px"
            >
                <List>
                    {#each suppliers as w}
                        <Item
                            class="fs-14 small"
                            on:SMUI:action="{() => {
                                console.log({supplier});
                                console.log({w});
                                supplier = w;
                            }}"
                        >
                            <Text>{w.id + ' - ' + w.supplierName}</Text>
                        </Item>
                    {/each}
                </List>
            </Menu>
        </div>
    </Group>
{/if}

<style>
</style>
