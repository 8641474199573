<script lang="ts">
    import { classMap } from '@smui/common/internal';

    import Select, { Option } from '@smui/select';
    import Icon from '@smui/select/icon';
    import { _ } from 'svelte-i18n';

    let className = '';
    export { className as class };
    export let value;
    export let list = [];
    export let format = { showId: false, translateName: true };
    export let errorMsg = false;
    export let required = false;
    export let disabled = false;
    export let noLabel = true;
    export let style = '';
    export let colspan = 1;

    let edEl;
    let focused = false;
    let menuWidth;
    let menuBottom;

    const setFocus = (() => {
        if (edEl && edEl.getElement()) {
            edEl.getElement().focus();
            edEl.getElement().select();
            focused = true;
        }
    })

    // $: {
    //     console.log('=====================');
    //     console.log('DTSelectCell change');
    //     console.log('=====================');
    //     console.log({value});
    //     console.log({list});
    //     console.log({edEl});
    //     console.log("Width: ", edEl?.getElement()?.clientWidth)
    // }

    const formatOptionName = ((item) => {
        if (format?.showId) {
            return [item.id, (format?.translateName ? $_(item.name) : item.name)].join(" - ");
        } else {
            return (format?.translateName ? $_(item.name) : item.name);
        }
    })

</script>

<div
    class={classMap({
            [className]: true,
            'select-cell': true
        })
    }
    {style}
    {colspan}
>
{#if list && list.length > 0}
    <Select
        variant="outlined"
        bind:value
        bind:this={edEl}
        {noLabel}
        {required}
        {disabled}
        fullWidth={false}
        class="w-100 tescoblue-text white with-parameter bs-bb outlined"
        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
        menu$class="mdc-menu-surface--fixed with-parameter__menu"
        on:SMUISelect:change
        on:focusin={() => {
            //console.log("In Focus", {edEl});
            menuWidth = edEl?.getElement()?.clientWidth;
            menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
        }}
        on:click={() => {
            menuWidth = edEl?.getElement()?.clientWidth;
            menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
        }}
        updateInvalid={false}
        invalid={errorMsg}
        withLeadingIcon={errorMsg}
    >
        {#each list as item}
            <Option value={item.name}>{formatOptionName(item)}</Option>
        {/each}
        <svelte:fragment slot="leadingIcon">
            {#if errorMsg}
                <Icon class="material-icons red-text">error</Icon>
            {/if}
        </svelte:fragment>
    </Select>
{/if}
</div>

<style>
</style>
