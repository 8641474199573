<script>
    import { snackbarSuccess, selectedModule, selectedWeek } from "../../../stores/AppStatus";
    import { handleApiError } from "../lib/errorHandler";

    import WeekInfo from "../common/WeekInfo.svelte";
    // import DCSelector from "./DCSelector.svelte";
    // import SupplierSelector from "./SupplierSelector.svelte";
    // import BuyerSelector from "./BuyerSelector.svelte";
    import RPShowReportButton from "./RPShowReportButton.svelte";
    import RPDownloadExcelButton from "./RPDownloadExcelButton.svelte";
    import RPLCFilterBar from "./RPLCFilterBar.svelte";
    import RPLCDataTable from "./RPLCDataTable.svelte";
    import RPPCFilterBar from "./RPPCFilterBar.svelte";
    import RPPCDataTable from "./RPPCDataTable.svelte";
    import {
        generalExportToExcel as apiGeneralExportToExcel,
        reportPriceComparison as apiReportPriceComparison,
        reportLogisticsChanges as apiReportLogisticsChanges,
    } from "../../../api/Report";

    import RPDataTable from "./RPDataTable.svelte";
    // import DSFilterBar from "./DSFilterBar.svelte"
    import FullscreenLoader from "../../elementary/FullscreenLoader.svelte";

    import Paper, { Title, Subtitle, Content } from "@smui/paper";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    export let tsWeek;
    export let calWeek;

    // Report data variables
    let rppcItems = [];
    let rppcFilteredItems = [];
    let rplcItems = [];
    let rplcFilteredItems = [];

    // Filters
    let countrySearchArray = [];
    let dcSearchArray = [];
    let supplierSearchArray = [];
    let buyerSearchArray = [];
    let deliveryDateSearch = null;
    let statusSearchArray = null;
    let uplStatusSearchArray = null;

    // Loading layer...
    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    // Data indicators
    let dataLoaded = false;
    let loadingData = false;

    $: downloadDisabled =
        ($selectedModule == "price_comparison" && rppcFilteredItems.length == 0) ||
        ($selectedModule == "logistic_change" && rplcFilteredItems.length == 0);

    const getReportData = async () => {
        console.log("================= getReportData ==============");
        console.log("$selectedModule: " + $selectedModule);
        switch ($selectedModule) {
            case "price_comparison":
                generateReportPriceComparison();
                break;
            case "logistic_change":
                generateReportLogisticsChanges();
                break;
            default:
                break;
        }
    };

    const exportReportData = async () => {
        console.log("================= exportReportData ==============");
        console.log("$selectedModule: " + $selectedModule);
        switch ($selectedModule) {
            case "price_comparison":
                exportReportPriceComparison();
                break;
            case "logistic_change":
                exportReportLogisticsChanges();
                break;
            default:
                break;
        }
    };

    async function generateReportPriceComparison() {
        console.log("================= generateReportPriceComparison ==============");
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("generating_report_please_wait");
        try {
            loadingData = true;
            const res = await apiReportPriceComparison(
                $selectedWeek.tesco_year,
                $selectedWeek.tesco_week,
                null /* countrySearch.map((x) => {return { id: x.id }.id;}).join(",").toUpperCase()*/,
            );
            console.log({ res });
            let id = 0;
            rppcItems = [...res];
            rppcItems.forEach((x) => {
                x.id = id++;
                //x.productId = x.productId;
                //x.tpnb = String(x.tpnb);
                x.selected = false;
            });

            if (rppcItems.length == 0) {
                console.log("Report Price Comparison is Empty");
            } else {
                // $divisionsList = [...divsArray];
                rppcItems = rppcItems;
                console.log({ rppcItems });
            }
            dataLoaded = true;
            loadingData = false;
            apiCallProcessing = false;
        } catch (error) {
            console.log("Report Price Comparison Error");
            console.log({ error });
            loadingData = false;
            apiCallProcessing = false;
            if (!handleApiError($_("report_failed"), error, "non-fatal")) throw error;
        }
    }

    async function exportReportPriceComparison() {
        console.log("================= exportReportPriceComparison ==============");
        let data = [];
        let fileName = "Report_Price_Comparison_w" + $selectedWeek.tesco_year + $selectedWeek.tesco_week;
        let reportName = $_("report_price_comparison");
        let headersTranslations = [
            $_("tesco_week"),
            $_("rppc_supplier_id"),
            $_("rppc_supplier_rms_no"),
            $_("country"),
            $_("dc"),
            $_("rppc_product_id"),
            $_("rppc_product_description"),
            $_("buyer"),
            $_("product_group"),
            $_("rppc_tpnb"),
            $_("rppc_product_detail_description"),
            $_("supplier_name"),
            $_("carton_logistics"),
            $_("pallet_logistics"),
            $_("rppc_last_price"),
            $_("rppc_price"),
            $_("rppc_last_promo_price"),
            $_("rppc_promo_price"),
            $_("currency"),
        ];
        let columnTypes = [
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "number",
            "number",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "text",
        ];

        rppcFilteredItems.forEach(function (row) {
            let rowData = [];
            rowData.push(row.tescoWeek);
            rowData.push(row.supplierId);
            rowData.push(row.supplierRmsNo);
            rowData.push(row.country);
            rowData.push(row.dcCode);
            rowData.push(row.productId);
            rowData.push(row.productDescription);
            rowData.push(row.buyer);
            rowData.push(row.productGroup);
            rowData.push(row.tpnb);
            rowData.push(row.productDetailDescription);
            rowData.push(row.supplierName);
            rowData.push(Number(row.cartonLogistics));
            rowData.push(Number(row.palletLogistics));
            rowData.push(Number(row.lastPrice));
            rowData.push(Number(row.price));
            rowData.push(Number(row.lastPromoPrice));
            rowData.push(Number(row.promoPrice));
            rowData.push(row.currency);

            data.push(rowData);
        });

        let exportData = {
            fileName: fileName,
            reportName: reportName,
            headersTranslations: headersTranslations,
            columnTypes: columnTypes,
            data: data,
        };

        apiGeneralExportToExcel(exportData)
            .then((res) => {
                $snackbarSuccess.text = $_("export_price_comparison_report_succeeded");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("export_price_comparison_report_failed", { values: { reason: error.message } }), error, "non-fatal"))
                    throw error;
            });
    }

    async function generateReportLogisticsChanges() {
        console.log("================= generateReportLogisticsChanges ==============");
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("generating_report_please_wait");
        try {
            loadingData = true;
            const res = await apiReportLogisticsChanges(
                $selectedWeek.tesco_year,
                $selectedWeek.tesco_week,
                null /* countrySearch.map((x) => {return { id: x.id }.id;}).join(",").toUpperCase()*/,
            );
            console.log({ res });
            let id = 0;
            rplcItems = [...res];
            rplcItems.forEach((r) => {
                r.id = id++;
                //r.productId = r.productId;
                //r.tpnb = String(r.tpnb);
                r.totalVolSngl =
                    (r.d1VolSngl || 0) +
                    (r.d2VolSngl || 0) +
                    (r.d3VolSngl || 0) +
                    (r.d4VolSngl || 0) +
                    (r.d5VolSngl || 0) +
                    (r.d6VolSngl || 0) +
                    (r.d7VolSngl || 0) +
                    (r.flexVolSngl || 0);
                r.totalVolCart =
                    (r.d1VolCart || 0) +
                    (r.d2VolCart || 0) +
                    (r.d3VolCart || 0) +
                    (r.d4VolCart || 0) +
                    (r.d5VolCart || 0) +
                    (r.d6VolCart || 0) +
                    (r.d7VolCart || 0) +
                    (r.flexVolCart || 0);
                r.totalVolPal =
                    (r.d1VolPal || 0) +
                    (r.d2VolPal || 0) +
                    (r.d3VolPal || 0) +
                    (r.d4VolPal || 0) +
                    (r.d5VolPal || 0) +
                    (r.d6VolPal || 0) +
                    (r.d7VolPal || 0) +
                    (r.d7VolPal || 0);
                r.reason = r.differentLastWeekLogistics == 1 ? "different_last_week_logistics" : "different_current_week_logistics";
                r.selected = false;
            });

            if (rplcItems.length == 0) {
                console.log("Report Logistics Changes is Empty");
            } else {
                // $divisionsList = [...divsArray];
                rplcItems = rplcItems;
                console.log({ rplcItems });
            }
            dataLoaded = true;
            loadingData = false;
            apiCallProcessing = false;
        } catch (error) {
            console.log("Report Logistics Changes Error");
            console.log({ error });
            loadingData = false;
            apiCallProcessing = false;
            if (!handleApiError($_("report_failed"), error, "non-fatal")) throw error;
        }
    }

    async function exportReportLogisticsChanges() {
        console.log("================= exportReportLogisticsChanges ==============");
        let data = [];
        let fileName = "Report_Logistics_Changes_w" + $selectedWeek.tesco_year + $selectedWeek.tesco_week;
        let reportName = $_("report_logistics_changes");
        let headersTranslations = [
            $_("tesco_week"),
            $_("country_id"),
            $_("dc_no"),
            $_("gfs_tpn"),
            $_("supplier_id_gfs"),
            $_("supplier_id_country"),
            $_("country_tpn"),
            $_("product_name_country"),
            $_("country"),
            $_("dc"),
            $_("buyer"),
            $_("article_group"),
            $_("product_en"),
            $_("country_of_origin"),
            $_("variety"),
            $_("supplier"),
            $_("unit"),
            $_("carton_logistic"),
            $_("carton_logistic_lw"),
            $_("pallet_logistic"),
            $_("pallet_logistic_lw"),
            $_("reason"),
            $_("temperature"),
            $_("forecast_demand"),
            $_("singles") + " " + $_("mon"),
            $_("singles") + " " + $_("tue"),
            $_("singles") + " " + $_("wed"),
            $_("singles") + " " + $_("thu"),
            $_("singles") + " " + $_("fri"),
            $_("singles") + " " + $_("sat"),
            $_("singles") + " " + $_("sun"),
            $_("singles") + " " + $_("total"),
            $_("cases_kartons") + " " + $_("mon"),
            $_("cases_kartons") + " " + $_("tue"),
            $_("cases_kartons") + " " + $_("wed"),
            $_("cases_kartons") + " " + $_("thu"),
            $_("cases_kartons") + " " + $_("fri"),
            $_("cases_kartons") + " " + $_("sat"),
            $_("cases_kartons") + " " + $_("sun"),
            $_("cases_kartons") + " " + $_("total"),
            $_("pallets") + " " + $_("mon"),
            $_("pallets") + " " + $_("tue"),
            $_("pallets") + " " + $_("wed"),
            $_("pallets") + " " + $_("thu"),
            $_("pallets") + " " + $_("fri"),
            $_("pallets") + " " + $_("sat"),
            $_("pallets") + " " + $_("sun"),
            $_("pallets") + " " + $_("total"),
            $_("agreed_price"),
            $_("agreed_currency"),
        ];
        let columnTypes = [
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "decimal2",
            "text",
        ];
        rplcFilteredItems.forEach(function (row) {
            let rowData = [];
            rowData.push(row.tescoWeek);
            rowData.push(row.countryId ? row.countryId : "");
            rowData.push(row.dcNo > 0 ? row.dcNo : "");
            rowData.push(row.productId);
            rowData.push(row.supplierId);
            rowData.push(row.rmsSupplierNo);
            rowData.push(row.tpnb);
            rowData.push(row.countryProductName);
            rowData.push(row.countryCode);
            rowData.push(row.dcCode);
            rowData.push(row.buyer);
            rowData.push(row.articleGroup);
            rowData.push(row.ceTpnName);
            rowData.push(row.countryOfOrigin);
            rowData.push(row.variety ? row.variety : "");
            rowData.push(row.supplierName);
            rowData.push($_(row.units));
            rowData.push(row.cartonLogistics);
            rowData.push(row.cartonLogisticsLW);
            rowData.push(row.palletLogistics);
            rowData.push(row.palletLogisticsLW);
            rowData.push($_(row.reason));
            rowData.push($_(row.temperature));
            rowData.push(Number(row.totalForecasted));
            rowData.push(Number(row.d1VolSngl));
            rowData.push(Number(row.d2VolSngl));
            rowData.push(Number(row.d3VolSngl));
            rowData.push(Number(row.d4VolSngl));
            rowData.push(Number(row.d5VolSngl));
            rowData.push(Number(row.d6VolSngl));
            rowData.push(Number(row.d7VolSngl));
            rowData.push(row.totalVolSngl?.toFixed(2)?.replace(".00", ""));
            rowData.push(Number(row.d1VolCart));
            rowData.push(Number(row.d2VolCart));
            rowData.push(Number(row.d3VolCart));
            rowData.push(Number(row.d4VolCart));
            rowData.push(Number(row.d5VolCart));
            rowData.push(Number(row.d6VolCart));
            rowData.push(Number(row.d7VolCart));
            rowData.push(row.totalVolCart?.toFixed(2)?.replace(".00", ""));
            rowData.push(Number(row.d1VolPal));
            rowData.push(Number(row.d2VolPal));
            rowData.push(Number(row.d3VolPal));
            rowData.push(Number(row.d4VolPal));
            rowData.push(Number(row.d5VolPal));
            rowData.push(Number(row.d6VolPal));
            rowData.push(Number(row.d7VolPal));
            rowData.push(row.totalVolPal?.toFixed(2)?.replace(".00", ""));
            rowData.push(Number(row.price));
            rowData.push(row.currency);

            data.push(rowData);
        });

        let exportData = {
            fileName: fileName,
            reportName: reportName,
            headersTranslations: headersTranslations,
            columnTypes: columnTypes,
            data: data,
        };

        apiGeneralExportToExcel(exportData)
            .then((res) => {
                $snackbarSuccess.text = $_("export_logistics_changes_report_succeeded");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("export_logistics_changes_report_failed", { values: { reason: error.message } }), error, "non-fatal"))
                    throw error;
            });
    }
</script>

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

<section class="qs main-data-view">
    <section class="w-100 header tescoblue-border-bottom-thin">
        <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1" justify="between">
            <WeekInfo />

            <Flex direction="row" justify="end" class="w-100 gap-05">
                <RPShowReportButton on:get={getReportData} />
                <RPDownloadExcelButton disabled={downloadDisabled} on:download={exportReportData} />
            </Flex>
        </Flex>
    </section>

    <section class="filters">
        <Paper class="grey lighten-3 w-100 bs-bb pb-2 pt-2 ">
            <div class="smui-paper__content bs-bb">
                <section class="w-100">
                    {#if $selectedModule == "price_comparison"}
                        <RPPCFilterBar bind:countrySearch={countrySearchArray} {dataLoaded} {loadingData} />
                    {:else if $selectedModule == "logistic_change"}
                        <RPLCFilterBar bind:countrySearchArray bind:dcSearchArray bind:supplierSearchArray bind:buyerSearchArray />
                    {:else}
                        <!-- Other report's filters
                    <DSFilterBar
                        bind:countrySearchArray
                        bind:dcSearch
                        bind:tpnSearch
                        bind:suppSearch
                        bind:productDescEnSearch
                        bind:deliveryDateSearch
                        bind:statusSearchArray
                        bind:uplStatusSearchArray
                    />
                    -->
                    {/if}
                </section>
            </div>
        </Paper>
    </section>

    <section class="data tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb">
                {#if $selectedModule == "price_comparison"}
                    <RPPCDataTable
                        items={rppcItems}
                        bind:filteredItems={rppcFilteredItems}
                        countrySearchArray={countrySearchArray.map((x) => x.id.toUpperCase())}
                    />
                {:else if $selectedModule == "logistic_change"}
                    <RPLCDataTable
                        items={rplcItems}
                        bind:filteredItems={rplcFilteredItems}
                        {tsWeek}
                        {calWeek}
                        countrySearchArray={countrySearchArray.map((x) => x.id.toUpperCase())}
                        {dcSearchArray}
                        {supplierSearchArray}
                        {buyerSearchArray}
                    />
                {:else}
                    <!-- Other report's tables
                <RPDataTable
                    tsWeek={tsWeek}
                    calWeek={calWeek}
                    countrySearchArray="{countrySearchArray.map(x => x.id.toUpperCase())}"
                    {dcSearch}
                    {tpnSearch}
                    {suppSearch}
                    {productDescEnSearch}
                    {deliveryDateSearch}
                />
                -->
                {/if}
            </div>
        </Paper>
    </section>
</section>

<style>
    section.header {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 100%;
    }
</style>
