<script lang="ts">
    import { _ } from 'svelte-i18n';
    import { classMap, prefixFilter } from '@smui/common/internal';
    import { ContextFragment } from '@smui/common';
    import { Cell } from '@smui/data-table';
    import { Input } from '@smui/textfield';

    import { createEventDispatcher } from 'svelte';

    export let value = '';
    let className = ''; export { className as class };
    export let editable = true;
    export let numeric = true;
    export let placeholder = '';
    export let colspan;
    export let autofocus = true;
    export let maxlength = 200;
    export let withTrailingIcon = false;

    let edEl;
    let focused = false;

    let prevValue;

    const dispatch = createEventDispatcher();

    const setFocus = (() => {
        if (!focused && edEl && edEl.getElement()) {
            edEl.getElement().focus();
            edEl.getElement().select();
            focused = true;
        }
    })

    $: if ((value != prevValue) || (value && !prevValue) || (!value && prevValue) ) {
        if (prevValue || prevValue == 0 ) {
            dispatch('change');
        }
        prevValue = value;
    }

    // $: {
    //     console.log({focused});
    //     console.log({edEl});
    //     console.log({value});
    //     console.log("restProps", {...$$restProps});
    // }

</script>

<Cell
    numeric
    class={classMap({
            [className]: true,
            'edit-cell-border': true,
            'focused': focused,
            'text-right': numeric,
            'text-left': !numeric
        })
    }
    {colspan}
    style="{withTrailingIcon ? 'padding-right: 1.2rem !important;' : ''}"
    on:click={setFocus}
>
    <Input
        class="input-cell pa-0 ma-0 {numeric ? 'text-right' : 'text-left'} {editable ? '' : 'lightgrey grey-text'}"
        placeholder={placeholder}
        {maxlength}
        {withTrailingIcon}
        disabled={!editable}
        bind:value={value}
        bind:this={edEl}
        on:focus="{() => {setFocus()}}"
        on:blur="{() => {focused = false;}}"
        on:keydown
        {autofocus}
        {...prefixFilter($$restProps, 'input$')}
    />
    <ContextFragment key="SMUI:textfield:icon:leading" value={false}>
      <slot name="trailingIcon" />
    </ContextFragment>

</Cell>

<style>
</style>
