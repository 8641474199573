import { writable } from 'svelte/store';

export const errorStore = writable({
    open: false,
    details: null
});

export function handleApiError(error_message = null, error, fatal) {
    const errorDetails = {
        customErrorMessage: error_message,
        message: error.cause.message,
        responseURL: error.cause.request?.responseURL,
        method: error.cause.response?.config?.method,
        data: error.cause.response?.config?.data,
        url: error.cause?.config?.url,
        date: error.cause.response?.headers?.date,
        status: error.cause.response?.data.status,
        dataError: error.cause.response?.data?.error,
        dataMessage: error.cause.response?.data?.message,
        dataPayload: error.cause.response?.data?.payload,
        teamNumber: error.cause.response?.headers?.teamnumber,
        traceId: error.cause.response?.headers?.traceid,
        traceParent: error.cause.response?.headers?.traceparent,
        fatal,
    };

    errorStore.set({
        open: true,
        details: errorDetails
    });
}