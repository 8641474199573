<script lang="ts">

  import { afterUpdate } from 'svelte';

  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
    SortValue
  } from '@smui/data-table';
  import Select, { Option } from '@smui/select';
  import Button from '@smui/button';
  import IconButton, { Icon } from '@smui/icon-button';
  import Textfield, { Input } from '@smui/textfield';


  import DTInputCell from '../common/DTInputCell.svelte';
  import Loader from "../../elementary/Loader.svelte";

  import { mdiAlertOutline, mdiDelete, mdiArrowULeftTop } from '@mdi/js';

  import Flex from 'svelte-flex';
  import { _ } from 'svelte-i18n';

  import { createEventDispatcher } from 'svelte';

  import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

  // Items
  export let items: Items[] = [];
  export let country;
  export let working;

  const dispatch = createEventDispatcher();

  type Items = {
    id: number;
    supplierId: number;
    supplierName: number;
    participation: number;
  };

  const sumParticipations = ((i, country) => {

    if (i && Array.isArray(i) && i.length > 0 ) {

      return i.filter(x => x.country == country && x.rowType != 'delete').map(x => Number(x.participation)).reduce((acc, val) => acc + val, 0);
    }

    return 0;
  })

  const handleDelete = ((item) => {
    if (item.rowType == 'new') {
      items = items.filter(x => x.id != item.id);
    } else {
      item.rowType = 'delete';
      item.changed = true;
      items = items;
    }
  })

  const handleUndoDelete = ((item) => {
    if (item.rowType == 'delete') {
      item.rowType = 'orig';
      items = items;
    }
  })

  $: totalParticipation = sumParticipations(items, country);

  $: {
    console.log("---------------------");
    console.log({items});
  }

</script>

<DataTable
    table$aria-label="Todo list"
    class="w-100 mt-2"
    >
  <Head>
    <Row>
      <Cell columnId="actions" class="datatable-header pl-0 pr-0" >
        <Label></Label>
      </Cell>
      <Cell columnId="supplierId" class="datatable-header">
        <Label>{$_('supplier_id')}</Label>
      </Cell>
      <Cell columnId="supplierName" class="datatable-header">
        <Label>{$_('supplier')}</Label>
      </Cell>
      <Cell numeric columnId="participation" class="datatable-header">
        <Label>{$_('participation')}</Label>
      </Cell>
      <Cell class="datatable-header"></Cell>
    </Row>
  </Head>
  <Body>
    {#if working}
      <Row>
          <td colspan="5" class="mdc-data-table__cell" style="width: 100%">
              <Loader/>
          </td>
      </Row>
    {:else}
      {#if items && items.filter(x => x.country == country).length == 0 || !country}
        <Row>
            <td colspan="5" class="mdc-data-table__cell text-center" style="width: 100%">{$_('no_data')}</td>
        </Row>
      {:else}
        {#each items.filter(i => i.country == country) as item (item.id)}
          <Row class="{item.rowType == 'delete' ? 'text-line-through' : ''}">
            <Cell class="pl-0 pr-0">
                <Flex direction="row">
                  {#if (item.rowType != 'delete')}
                    <div title="{$_('delete')}">
                      <IconButton
                          size="button"
                          class="tescoblue-text opacity-1"
                          on:click="{() => handleDelete(item)}"
                          >
                          <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue-text">
                              <path fill="currentColor" d={mdiDelete} />
                          </Icon>
                      </IconButton>
                    </div>
                  {:else}
                    <div title="{$_('undo')}">
                      <IconButton
                          size="button"
                          class="tescoblue-text opacity-1"
                          on:click="{() => handleUndoDelete(item)}"
                          >
                          <Icon tag='svg' viewBox="0 0 24 24" class="tescoblue-text">
                              <path fill="currentColor" d={mdiArrowULeftTop} />
                          </Icon>
                      </IconButton>
                    </div>
                  {/if}
              </Flex>
            </Cell>
            <Cell>{item.supplierId}</Cell>
            <Cell>{item.supplierName}</Cell>
            <DTInputCell
                bind:value={item.participation}
                editable={true}
                on:change={() => {
                  console.log("on:change");
                  item.changed = true;
                }}
                >
            </DTInputCell>
            <Cell class="pl-1 pr-1 text-center fs-1rem">%</Cell>
          </Row>
        {/each}
        <Row>
          <Cell colspan="3" class="datatable-summary text-bold text-right"><Label>{$_('total')}:</Label></Cell>
          <Cell class="datatable-summary text-bold" >
              <Flex direction="row" justify="end">
                {#if (totalParticipation != 100)}
                  <div title="{$_('sum_not_100_pct')}">
                    <IconButton
                        size="button"
                        class="white-text opacity-1"
                        disabled={true}
                        >
                        <Icon tag='svg' viewBox="0 0 24 24" class="tescored-text">
                            <path fill="currentColor" d={mdiAlertOutline} />
                        </Icon>
                    </IconButton>
                  </div>
                {/if}
                <div class="{totalParticipation != 100 ? 'tescored-text' : ''}">{totalParticipation}</div>
              </Flex>
          </Cell>
          <Cell class="pl-1 pr-1 datatable-summary text-bold text-center fs-1rem">%</Cell>
        </Row>
      {/if}
    {/if}
  </Body>
</DataTable>

<style>
</style>
