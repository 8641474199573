import Api from "../services/Api";

// Method to get current List of QuoteSheets
export const getQuoteSheets = async (id, year, week, country, supplierId, productId, productDesc, productGroup, countryOfOrigin, variety, units) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (country) params.append('country', country);
    if (supplierId) params.append('supplierId', supplierId);
    if (productId) params.append('productId', productId);
    if (productDesc) params.append('productDesc', productDesc);
    if (productGroup) params.append('productGroup', productGroup);
    if (countryOfOrigin) params.append('countryOfOrigin', countryOfOrigin);
    if (variety) params.append('variety', variety);
    if (units) params.append('units', units);

    const response = await Api.get("/quoteSheet/", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

export const checkQuoteSheetItems = async (data) => {
  try {

    const response = await Api.post("/quoteSheet/exist", data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Create new QuoteSheet
export const createQuoteSheet = async (data) => {
  try {
    let url = Array.isArray(data) ? "/quoteSheet/array" : "/quoteSheet/";

    const response = await Api.post(url, data);
    console.log({ response });
    if (response.status) {
      if (data.idXLS && data.idXLS != null) {
        response.payload[0].idXLS = data.idXLS;
      }
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Update QuoteSheet data
export const updateQuoteSheet = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/quoteSheet/", params, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Update QuoteSheet Logistics data
export const updateQuoteSheetLogistics = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('productId', data?.productId);
    params.append('year', data?.year);
    params.append('week', data?.week);

    const response = await Api.put("/quoteSheet/logistics", params, data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Delete QuoteSheet
export const deleteQuoteSheet = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/quoteSheet/", params);
    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, { cause: error });
  }
};

// Delete QuoteSheets (multi)
export const deleteQuoteSheets = async (ids) => {
  try {
    const response = await Api.post("/quoteSheet/arrayDelete", ids);

    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};
