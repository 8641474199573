<script lang="ts">

  import { afterUpdate } from 'svelte';

  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
    SortValue
  } from '@smui/data-table';
  import Select, { Option } from '@smui/select';
  import Button from '@smui/button';
  import IconButton, { Icon } from '@smui/icon-button';
  import Textfield, { Input } from '@smui/textfield';

  import DTInputCell from '../common/DTInputCell.svelte';

  import { mdiPencilBoxOutline } from '@mdi/js';

  import Flex from 'svelte-flex';
  import { _ } from 'svelte-i18n';

  import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

  // Items
  export let rows: Rows[] = [];

  type Rows = {
    type: string;
    d1: number;
    d2: number;
    d3: number;
    d4: number;
    d5: number;
    d6: number;
    d7: number;
    total: number;
    wholeWeek: number;
  };


  $: {
    console.log("---------------------");
    console.log({rows});
  }

</script>

<DataTable
    table$aria-label="Todo list"
    class="w-100 mt-2"
    >
  <Head>
    <Row>
      <Cell numeric columnId="d1" class="datatable-header right-splitter-main">
        <Label>{$_('d1')}</Label>
      </Cell>
      <Cell numeric columnId="d2" class="datatable-header right-splitter-main">
        <Label>{$_('d2')}</Label>
      </Cell>
      <Cell numeric columnId="d3" class="datatable-header right-splitter-main">
        <Label>{$_('d3')}</Label>
      </Cell>
      <Cell numeric columnId="d4" class="datatable-header right-splitter-main">
        <Label>{$_('d4')}</Label>
      </Cell>
      <Cell numeric columnId="d5" class="datatable-header right-splitter-main">
        <Label>{$_('d5')}</Label>
      </Cell>
      <Cell numeric columnId="d6" class="datatable-header right-splitter-main">
        <Label>{$_('d6')}</Label>
      </Cell>
      <Cell numeric columnId="d7" class="datatable-header right-splitter-main">
        <Label>{$_('d7')}</Label>
      </Cell>
      <Cell numeric columnId="wholeWeek" class="datatable-header right-splitter-main">
        <Label>{$_('flex')}</Label>
      </Cell>
      <Cell numeric columnId="total" class="datatable-header">
        <Label>{$_('total')}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    <Row>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell class="right-splitter-main" numeric>-</Cell>
      <Cell numeric>-</Cell>
    </Row>
  </Body>
</DataTable>

<style>
</style>
