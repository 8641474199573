<script lang="ts">

  import Select, { Option } from '@smui/select';
  import Dialog, { Title, Content, Actions, InitialFocus } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import DataTable, { Head, Body, Row, Cell, Label as TableLabel } from '@smui/data-table';
  import DTInputCell from '../common/DTInputCell.svelte';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import Textfield from '@smui/textfield';
  import Radio from '@smui/radio';


  import { _ } from 'svelte-i18n';
  import Flex from 'svelte-flex';

  import { createEventDispatcher } from 'svelte';

  import { countries, deliveryTypes, forecastStatuses, rejectPurchaseReasons } from "../../../stores/AppConfig";

  // Temporary prototype variables

  // Base variables
  export let open;
  export let type;
  export let action;
  export let availableStatuses;
  export let title;
  export let headerColors = "tescoblue white-text";
  export let big = false;
  export let data;

  // Variables to save
  export let selectedStatus = '';
  export let selectedReason = '';
  export let comment = null;

  let response = 'Nothing yet.';
  let reasonCodeDisabled = true;
  let adminMode = false;

  let listEl;
  let menuWidth;
  let menuBottom;

  const dispatch = createEventDispatcher();


  $: {
        console.log('------- BUYING SCREEN POPUP, action = '+ action +', type = '+ type +' ---------');
        console.log({action});
        console.log({type});
        console.log({availableStatuses});
        console.log({selectedStatus});
        console.log({selectedReason});
        console.log({comment});
    }

  $: if (action == 'reject') {
    reasonCodeDisabled = false;
    selectedStatus = 'rejected';
  }

  $: updateDisabled = false || action == 'reject';

  function statusSelected() {
    console.log('================= statusSelected ==============');
    console.log({selectedStatus});
    if (action != 'reject') {
      if (selectedStatus == '') {
        updateDisabled = true;
      } else {
        if (['rejected','purchased_more','purchased_less'].includes(selectedStatus)) {
          reasonCodeDisabled = false;
          selectedReason = null;
          updateDisabled = true;
        } else {
          reasonCodeDisabled = true;
          selectedReason = null;
          updateDisabled = false;
        }
      }
    }
  }

  function reasonCodeSelected() {
    console.log('================= reasonCodeSelected ==============');
    console.log({selectedReason});
    if (['rejected','purchased_more','purchased_less'].includes(selectedStatus)) {
      if (selectedReason == '') {
        updateDisabled = true;
      } else {
        updateDisabled = false;
      }
    } else {
      updateDisabled = false;
    }
  }


</script>




<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style="min-height:400px; min-width: 400px; {big ? 'width: 1920px; max-width: calc(100vw - 32px);' : ''}"
>
<Title id="data-changed-title" class="{headerColors}">
  {title}
</Title>
<Content id="data-changed-content" class="mt-4">

  <Flex direction="column" justify="center" align="center" class=" gap-05 w-100 gray lighten-4 h-100 pt-2 flex-1">
    {#if adminMode || action == 'reject'}
      <!-- Forecast Status -->
      {#if action != 'reject'}
        <Select
            variant="outlined"
            label={$_('status')}
            class="white small ma-5 with-parameter w-inherit"
            disabled={action == 'reject'}
            bind:value={selectedStatus}
            key="{(listItem) => `${listItem ? listItem.id : ''}`}"
            menu$class="mdc-menu-surface--fixed with-parameter__menu"
            on:SMUISelect:change={statusSelected}
        >
              <Option value='' />
              {#each availableStatuses as statusItem (statusItem.id)}
                <Option value={statusItem.id}>{$_(statusItem.name)}</Option>
              {/each}
        </Select>
      {/if}

      <!-- Reject Reason -->
      {#if (!reasonCodeDisabled)}
        <Select
          variant="outlined"
          label={$_('reject_reason')}
          class="white small ma-5 with-parameter w-inherit"
          disabled={reasonCodeDisabled}
          bind:value={selectedReason}
          bind:this={listEl}
          key="{(listItem) => `${listItem ? listItem.id : ''}`}"
          menu$class="mdc-menu-surface--fixed with-parameter__menu"
          menu$style="max-width: fit-content !important;"
          style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
          on:focusin={() => {
              menuWidth = listEl?.getElement()?.clientWidth;
              menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
          }}
          on:click={() => {
              menuWidth = listEl?.getElement()?.clientWidth;
              menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
          }}
          on:SMUISelect:change={reasonCodeSelected}
        >
            <Option value='' />
            {#each [...$rejectPurchaseReasons].filter(r => (r.statuses.includes(selectedStatus))) as reasonCodeItem (reasonCodeItem.id)}
              <Option value={reasonCodeItem.id}>{$_(reasonCodeItem.name)}</Option>
            {/each}
        </Select>
      {/if}
    {/if}

    <!-- Comment -->
    <Textfield
        variant="outlined"
        bind:value={comment}
        maxlength="50"
        placeholder=""
        title="{$_('comment')}"
        class="white small ma-5 w-100"
        input$class="mnw-100"
        size="40"
        label="{$_('comment')}"
    >
    </Textfield>

  </Flex>

</Content>
<Actions>
    <Button
      action="close"
      class="tescored tescored-text outlined"
      on:click="{() => dispatch('popupCancel')}">
      <Label>{$_('cancel')}</Label>
    </Button>
    <Button
      action="save"
      default
      defaultAction
      disabled={updateDisabled}
      class="{updateDisabled ? 'white-text grey' : 'tescoblue white-text'} nowrap"
      on:click="{() => dispatch('popupSave',{data: data, status: selectedStatus, reasonCode: selectedReason, comment: comment, type: type})}">
      <Label>{$_('save')}</Label>
    </Button>
  </Actions>
</Dialog>

<style>
</style>
