<script lang="ts">
  import Dialog, { Title, Content, Actions } from "@smui/dialog";
  import Flex from "svelte-flex";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Button, { Label, Group } from "@smui/button";
  import Paper from "@smui/paper";
  import { mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";

  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";

  import ProductList from "./DPProductList.svelte";
  import DTCountrySelectCellAll from "../../common/DTCountrySelectCellAll.svelte";
  import BuyerTmEmailAutocomplete from "../../common/BuyerTmEmailAutocomplete.svelte";
  import type { ProductsForDelegation } from "../../../../types/Management";

  import { getProduct } from "../../../../api/Product.js";

  let type = "Buyer";
  export let open;
  let productItemList: ProductsForDelegation[] = [];
  let selectedProducts: ProductsForDelegation[] = [];
  let toUser;
  let fromUser;
  let country = "";
  let status = 1;
  let dataValid = false;
  let tableLoad = false;
  let tableSearchMessage = false;
  let disableFields = false;

  const dispatch = createEventDispatcher();

  function clearPopupFields() {
    console.log("clearPopupFields",selectedProducts);
    productItemList = [];
    fromUser = "";
    toUser = "";
    country = "";
    type = "Buyer";
    selectedProducts = [];
    tableSearchMessage = false;
    disableFields = false;
    console.log("clearPopupFields end",selectedProducts);
  }

  async function fetchProducts() {
    try {
      tableLoad = true;
      // Check if country is 'ALL' to list all items/countries
      const apiCountry = country === "ALL" ? null : country;

      let buyer = null;
      let technicalManager = null;
      console.log();
      // Based on 'type'
      if (type === "Buyer") {
        buyer = fromUser;
      } else if (type === "TechnicalManager") {
        technicalManager = fromUser;
      }

      // id, tpnb, ean, description, buyer, technicalManager, country, supplierName, status, supplierId
      const response = await getProduct(null, null, null, null, buyer, technicalManager, apiCountry, null, null, null);
      console.log("getProduct", { response });

      productItemList = [];
      selectedProducts = [];
      productItemList = response;
      if (productItemList.length == 0) {
        console.log("getProduct().empty");
        tableSearchMessage = true;
      }
      disableFields = true;
      tableLoad = false;
    } catch (error) {
      if (error?.cause?.response?.status == 401) { throw(error) }
      tableLoad = false;
      console.error("Error fetching products:", error);
    }
  }

  // Validation
  $: {
    dataValid = country && fromUser && toUser && fromUser != toUser && selectedProducts.length > 0;
    console.log("selectedProducts", country, fromUser,toUser,{selectedProducts});
  };

</script>

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style="width: 1275px; max-width: calc(100vw - 32px);"
>
  <Title id="data-changed-title" class="tescoblue white-text">
    {$_("create_product_delegation")}
  </Title>
  <Content id="data-changed-content" class="grey lighten-3">
    <Flex direction="row" justify="center" align="center" class="grey lighten-3 gap-1 mb-2">
      <Label>{$_("delegate_user_type")}:</Label>

      {#each ["Buyer", "TechnicalManager"] as option}
        <FormField>
          <Radio bind:group={type} value={option} touch on:click={() => clearPopupFields()} />
          <span slot="label">
            {#if option === "Buyer"}
              {$_("by_buyer")}
            {:else if option === "TechnicalManager"}
              {$_("by_technical_manager")}
            {/if}
          </span>
        </FormField>
      {/each}
    </Flex>
    <Flex direction="row" justify="start" align="start" class="gap-1">
      <Paper elevation={5} class="no-padding">
        <Content>
          <Flex direction="column" class="gap-1 w-100">
            <DTCountrySelectCellAll bind:value={country} title={$_("country")} allFlag={true} classe={"small w-100"} noLabel={false} editable={!disableFields} insideDataTable={true}/>
            <BuyerTmEmailAutocomplete bind:value={fromUser} role={type} label={$_("from_user")} variant="outlined" small={true} {disableFields} insideDataTable={true}/>

            <Group>
              <Button
                title={$_("clear")}
                class="tescoblue white-text"
                on:click={() => {
                  clearPopupFields();
                }}
              >
                <svg viewBox="0 0 24 24" width="20" height="20">
                  <path fill="currentColor" d={mdiBackspaceOutline} />
                </svg>
              </Button>

              <Button
                class="tescoblue white-text pr-4"
                title={$_("search")}
                on:click={() => {
                  fetchProducts();
                }}
              >
                <svg viewBox="0 0 24 24" width="20" height="20">
                  <path fill="currentColor" d={mdiDownloadCircleOutline} />
                </svg>
                {$_("search")}
              </Button>
            </Group>
          </Flex>
        </Content>
      </Paper>

      <Paper elevation={5} style="min-width:450px; min-height:200px;" class="no-padding">
        <Content>
          <ProductList {productItemList} {tableLoad} {tableSearchMessage} bind:selectedProducts />
        </Content>
      </Paper>

      <Paper elevation={5} class="no-padding">
        <Content>
          <BuyerTmEmailAutocomplete bind:value={toUser} role={type} label={$_("to_user")} variant="outlined" small={true} class="white tescoblue-text" insideDataTable={true}/>
        </Content>
      </Paper>
    </Flex>
  </Content>
  <Actions>
    <Button
      action="close"
      class="tescored tescored-text outlined"
      on:click={() => {
        clearPopupFields();
        dispatch("delegateProductPopupCancel");
      }}
    >
      <Label>{$_("cancel")}</Label>
    </Button>
    <Button
      action="save"
      defaultAction
      class={dataValid ? "tescoblue white-text" : "grey white-text"}
      disabled={!dataValid}
      on:click={() => {
        const productIds = selectedProducts.map((product) => ({ productId: product.id, productDescription: product.description }));
        dispatch("delegateProductPopupSave", { type, country, fromUser, toUser, products: productIds, status });
        clearPopupFields();
      }}
    >
      <Label>{$_("add")}</Label>
    </Button>
  </Actions>
</Dialog>

<style>
  /* Use :global() to apply styles to child components */
  :global(.no-padding) {
    padding: 0 !important; /* Use !important to ensure override */
  }
</style>
