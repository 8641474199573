<script lang="ts">
    import Autocomplete from "../../extra/Autocomplete.svelte";
    import { onMount } from "svelte";
    import Textfield from "@smui/textfield";
    import { Text } from "@smui/list";
    import CircularProgress from "@smui/circular-progress";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify } from "@mdi/js";
    import { _ } from "svelte-i18n";
    import { classMap } from "@smui/common/internal";
    import { getUserManagement, getUserEmailByUUID } from "../../../api/User.js";

    export let value = "";
    export let icon = true;
    export let disableFields: boolean = false;
    export let emailFromList: boolean = false;

    let className = "";
    export { className as class };

    export let role = "TechnicalManager";
    export let label = "technical_manager";
    export let variant = "";
    export let style = "";
    export let small = false;
    export let white = false;
    export let insideDataTable = false;

    let cachedUserList = [];
    let previousValue = "";

    let edEl;
    let menuWidth;
    let menuBottom;

    let validateOnMount = false;

    $: if (role) {
        cachedUserList = [];
    }

    onMount(() => {
        if (value) {
            validateOnMount = true;
        }
    });

    async function searchForName(input, role) {
        console.log("Searching for:", input);
        if (disableFields) {
            return [];
        }

        if (cachedUserList.length == 0) {
            try {
                const userResponse = await getUserManagement(input, role);
                console.log("User response:", userResponse);
                cachedUserList = userResponse.sort((a, b) => a.lastName.localeCompare(b.lastName));
            } catch (err) {
                console.error("Error fetching data:", err);
                if (err?.cause?.response?.status == 401) {
                    throw err;
                }
                return [];
            }
        }

        if (validateOnMount && value) {
            handleInput({ target: { value: value } });
            validateOnMount = false;
        }

        const filteredUsers = cachedUserList
            .filter((item) => {
                const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
                return fullName.includes(input.toLowerCase());
            })
            .map((item) => ({
                uuid: item.uuid,
                name: `${item.firstName} ${item.lastName}`,
            }));

        console.log("Filtered users:", filteredUsers);
        return filteredUsers;
    }

    async function handleSelection(selectedItem) {
        console.log("handleSelection: ", selectedItem);
        try {
            const userEmail = await getUserEmailByUUID(selectedItem.uuid);
            value = userEmail[0].email;
            console.log("handleSelection email: ", userEmail);
            emailFromList = true;
        } catch (err) {
            console.error("Error fetching email:", err);
            emailFromList = false;
        }
    }

    function handleInput(event) {
        const currentInput = event.target.value;
        const selectedUser = cachedUserList.find((item) => {
            const fullName = `${item.firstName} ${item.lastName}`;
            return fullName.toLowerCase() === currentInput.toLowerCase();
        });
        if (selectedUser && currentInput !== previousValue) {
            emailFromList = true;
        } else {
            emailFromList = false;
        }
        previousValue = currentInput;
    }
</script>

{#key role}
    <Autocomplete
        search={(input) => searchForName(input, role)}
        on:input={handleInput}
        bind:value
        bind:this={edEl}
        disabled={disableFields}
        showMenuWithNoInput={true}
        selectOnExactMatch={true}
        class={classMap({
            [className]: true,
            "edit-cell-border": true,
        })}
        menu$class={insideDataTable ? "mdc-menu-surface--fixed with-parameter__menu" : ""}
        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
        on:focusin={() => {
            menuWidth = edEl?.getElement()?.clientWidth;
            menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
        }}
        on:click={() => {
            menuWidth = edEl?.getElement()?.clientWidth;
        }}
        on:SMUIAutocomplete:selected={(option) => {
            handleSelection(option.detail);
        }}
    >
        <Text slot="loading" style="display: flex; width: 100%; justify-content: center; align-items: center;">
            <CircularProgress style="height: 24px; width: 24px;" indeterminate />
        </Text>
        <svelte:fragment slot="match" let:match>
            <Text>{match.name}</Text>
        </svelte:fragment>
        <Textfield
            {variant}
            bind:value
            withLeadingIcon={icon}
            label={$_(label)}
            class={`${small ? "small" : ""} ${white ? "white" : ""}`}
            {style}
            input$autocomplete="EmailSearch"
            disabled={disableFields}
        >
            <svelte:fragment slot="leadingIcon">
                {#if icon}
                    <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                        <path fill="currentColor" d={mdiMagnify} />
                    </Icon>
                {/if}
            </svelte:fragment>
        </Textfield>
    </Autocomplete>
{/key}
