<script lang="ts">
    import Select, { Option } from "@smui/select";
    import { classMap } from "@smui/common/internal";
    import { _ } from "svelte-i18n";
    import { currencyList } from "../../../stores/AppConfig";
    import { Flags } from "../../../services/Images";

    export let value;
    export let currencySign;
    export let editable = true;
    export let allFlag = true;
    export let title = $_("filter");
    export let noLabel = true;
    export let variant: "outlined" | "standard" | "filled" = "outlined";
    export let presentCountries = [];
    export let insideDataTable = false;
    export let excludeCur: string[] = [];

    // Define classes using classMap
    export let classe = classMap({
        white: true,
        "tescoblue-text": true,
        small: false,
        "custom-icon": true,
        "with-parameter": true,
        "w-100": true,
    });
    let list = [];

    // Set menu width according to parent element
    let edEl;
    let menuWidth;
    let menuBottom;

    // Reactive statement to filter countries
    $: {
        let excludedCountries = new Set(Array.from(presentCountries).map((item) => item.toUpperCase()));
        let excludedCodes = new Set(excludeCur.map(code => code.toUpperCase()));
        list = $currencyList.filter((currency) => !excludedCountries.has(currency.id) && allFlag && !excludedCodes.has(currency.code.toUpperCase()));

        // Automatically select the currency if list contains just one currency
        if (list.length === 1 && list[0].id !== value) {
            value = list[0].id;
            currencySign = list[0].sign;
        }
    }

    function handleSelectChange(event) {
        const selectedCurrency = list.find(c => c.id === value);
        currencySign = selectedCurrency ? selectedCurrency.sign : "";
    }
</script>

<Select
    {variant}
    label={title}
    {noLabel}
    bind:value
    bind:this={edEl}
    class={classe}
    style="--mdc-menu-max-width: {menuWidth}px; --mdc-menu-top: 240px"
    disabled={!editable}
    on:SMUISelect:change={handleSelectChange}
    menu$class={insideDataTable ? "mdc-menu-surface--fixed with-parameter__menu" : ""}
    on:focusin={() => {
        menuWidth = edEl?.getElement()?.clientWidth;
        menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
    }}
    on:click={() => {
        menuWidth = edEl?.getElement()?.clientWidth;
    }}
    menuBottom="edEl?.getElement()?.getBoundingClientRect()?.bottom;"
>
    <svelte:fragment slot="leadingIcon">
        {#if value}
            {@const currency = list.find((x) => x.id == value)}
            {#if currency}
                <div class="pl-4 pr-2">
                    <img src={Flags[currency.flag]} alt={currency.code} width="20px" />
                </div>
            {/if}
        {/if}
    </svelte:fragment>
    {#if list && list.length > 0}
        {#key value}
            {#each list as currency}
                <Option value={currency.id}>
                    <img src={Flags[currency.flag]} alt={currency.id} width="20px" />
                    <span class="pl-4">{currency.code}</span>
                </Option>
            {/each}
        {/key}
    {/if}
</Select>
