import './app.css'
import './assets/styles/theme/_smui-theme.scss'
import './assets/styles/svelte-material-ui/bare.css'
import './assets/styles/fixes/chrome.css'

// https://svelte-materialify.vercel.app/styles/colors/#color-pack
import './assets/styles/svelte-materialify/colors.css'

// https://svelte-materialify.vercel.app/styles/text-and-typography/#text-transform
import './assets/styles/svelte-materialify/typography.css'

// https://svelte-materialify.vercel.app/styles/spacing/#spacing
import './assets/styles/svelte-materialify/spacing.css'

// Material Icons
import './assets/fonts/material_icons.css'

import App from './App.svelte'


import { register, init, getLocaleFromNavigator } from 'svelte-i18n';

register('en', () => import('./translations/en.json'));
register('cz', () => import('./translations/cz.json'));
register('sk', () => import('./translations/sk.json'));
register('hu', () => import('./translations/hu.json'));

init({
	fallbackLocale: 'en',
	initialLocale: getLocaleFromNavigator(),
});

const app = new App({
  target: document.getElementById('app'),
})

export default app;


/** Return Date in format required by WS (yyyy-mm-dd)
* @param date input date
*
* @return yyyy-mm-dd
*/

Date.prototype.toWSDate = function() {
	return this.getFullYear()+'-'+ (this.getMonth()+1).pad()+'-'+ this.getDate().pad();
};

/** Return Date in format required by WS (yyyy-mm-ddThh:mi:ss)
* @param date input date
*
* @return yyyy-mm-ddThh:mm:ss
*/

Date.prototype.toWSDateTime = function() {
	return this.getFullYear()+'-'+ (this.getMonth()+1).pad()+'-'+ this.getDate().pad()+'T'+this.getHours().pad()+':'+this.getMinutes().pad()+':'+this.getSeconds().pad();
};

/** Return Date in format required by app (dd.mm.yyyy)
* @param date input date
*
* @return dd.mm.yyyy
*/

Date.prototype.toAppDate = function() {
	return this.getDate().pad()+'.'+ (this.getMonth()+1).pad()+'.'+ this.getFullYear();
};

/** Return Date in short format required by app (dd.mm.)
* @param date input date
*
* @return dd.mm.
*/

Date.prototype.toAppDateShort = function() {
	return this.getDate().pad()+'.'+ (this.getMonth()+1).pad()+'.';
};

/** Return Date in format required by app (dd.mm.yyyy hh:mi)
* @param date input date
*
* @return dd.mm.yyyy hh:mi
*/

Date.prototype.toAppDateTime = function() {
	return this.getDate().pad()+'.'+ (this.getMonth()+1).pad()+'.'+ this.getFullYear()+' '+this.getHours().pad()+':'+ this.getMinutes().pad();
};

/** Return Date in format required by app (dd.mm.yyyy hh:mi)
* @param date input date
*
* @return dd.mm. hh:mi
*/

Date.prototype.toAppDayMonthTime = function() {
	return this.getDate().pad()+'.'+ (this.getMonth()+1).pad()+'.'+' '+this.getHours().pad()+':'+ this.getMinutes().pad();
};

/** Return Time in format required by app (hh:mi)
* @param date input date
*
* @return hh:mi
*/

Date.prototype.toAppTime = function() {
	return this.getHours().pad()+':'+ this.getMinutes().pad();
};

/** Return full Time in format required by app (hh:mi:ss)
* @param date input date
*
* @return hh:mi:ss
*/

Date.prototype.toAppFullTime = function() {
	return this.getHours().pad()+':'+ this.getMinutes().pad()+':'+ this.getSeconds().pad();
};

/** Add given number of days to date
* @param days number of days to add
*/
Date.prototype.addDays = function(days) {
	this.setDate(this.getDate() + parseInt(days));
	return this;
};

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

/** Expand Number class, add leading zeroes for String padding
*
*  https://stackoverflow.com/a/11187738
*
* @parameter size maximal digits count
* @return string
*/
Number.prototype.pad = function(size) {
	var s = String(this);
	while (s.length < (size || 2)) {s = "0" + s;}
	return s;
};
