<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import DataTable, { Head, Body, Row, Cell, Pagination, Label, SortValue } from "@smui/data-table";
  import CircularProgress from "@smui/circular-progress";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import { mdiDelete, mdiPencilBoxOutline } from "@mdi/js";
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";

  import type { userDelegationList } from "../../../../types/Management";

  // Items
  export let delegationList: userDelegationList[] = [];

  // Filters
  export let tableLoad = false;
  export let tableSearchMessage = false;

  const dispatch = createEventDispatcher();

  // Date
  const date = new Date();
  const today = date.toISOString().split("T")[0];

  // CE time format
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("cs-CZ", options);
  }


  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, delegationList.length);
  $: slice = delegationList.slice(start, end);
  $: lastPage = Math.max(Math.ceil(delegationList.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }
</script>

<DataTable table$aria-label="Delegate user list" class="w-100 mt-2">
  <Head>
    <Row>
      <Cell columnId="actions" class="datatable-header pl-0 pr-0 sticky-col">
        <Label></Label>
      </Cell>
      <Cell columnId="userFrom" class="datatable-header">
        <Label>{$_("user_from")}</Label>
      </Cell>
      <Cell columnId="userTo" class="datatable-header">
        <Label>{$_("user_to")}</Label>
      </Cell>
      <Cell columnId="validFrom" class="datatable-header">
        <Label>{$_("valid_from")}</Label>
      </Cell>
      <Cell columnId="validTo" class="datatable-header">
        <Label>{$_("valid_to")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if tableLoad}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center">
          <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
          {$_("loading_wait")}
        </Cell>
      </Row>
    {:else if slice.length > 0}
      {#each slice as item (item.id)}
        <Row>
          <Cell class="pl-0 pr-0 sticky-col">
            <Flex direction="row">
              {#if item.dateTo>=today} 
              <!-- Edit button -->
              <IconButton title={$_("edit")} size="button" class="tescoblue-text" on:click={() => dispatch("delegateUserPopupOpenEdit", item)}>
                <svg viewBox="0 0 24 24" class="tescoblue-text">
                  <path fill="currentColor" d={mdiPencilBoxOutline} />
                </svg>
              </IconButton>
              {/if}
              {#if item.dateFrom>=today}
              <!-- Delete button -->
              <IconButton title={$_("delete")} size="button" class="tescoblue-text" on:click={() => dispatch("deleteUserDelegation", item)}>
                <svg viewBox="0 0 24 24" class="pa-0 ma-0">
                  <path fill="currentColor" d={mdiDelete} />
                </svg>
              </IconButton>
              {/if}
            </Flex>
          </Cell>
          <Cell>{item.fromUser}</Cell>
          <Cell>{item.toUser}</Cell>
          <Cell>{formatDate(item.dateFrom)}</Cell>
          <Cell>{formatDate(item.dateTo)}</Cell>
        </Row>
      {/each}
    {:else if tableSearchMessage}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center" style="width: 100%">{$_("search_instructions")}</Cell>
      </Row>
    {:else}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
      </Row>
    {/if}
  </Body>

  {#if slice.length > 10}
    <Pagination slot="paginate">
      <svelte:fragment slot="rowsPerPage">
        <Label>{$_("rows_per_page")}</Label>
        <Select variant="outlined" bind:value={rowsPerPage} noLabel>
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
          <Option value={100}>100</Option>
        </Select>
      </svelte:fragment>
      <svelte:fragment slot="total">
        {start + 1}-{end} of {slice.length}
      </svelte:fragment>

      <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
      <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
      <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
      <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}>last_page</IconButton>
    </Pagination>
  {/if}
</DataTable>

<style>
</style>
