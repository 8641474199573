import { writable } from 'svelte/store';

export const buyersList =  writable(
[
  {
    "countryCode": "CZ",
    "name": "Adela Kovkova",
    "email": "Adela.Kovkova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Akos Gellen",
    "email": "AkosPeter.Gellen@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Andras Boda",
    "email": "Andras.Boda@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Attila Vida",
    "email": "Attila.Vida@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Balazs Bakti",
    "email": "Balazs.Bakti@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Balazs Bogdan",
    "email": "Balazs.Bogdan@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Balazs Kozak",
    "email": "Balazs.Kozak@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Barbara Emese Juhasz",
    "email": "Barbaraemese.Juhasz@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Csaba Fekete",
    "email": "Csaba.Fekete1@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Csenge Dorottya Fulop",
    "email": "CsengeDorottya.Fueloep@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Dorina Paul",
    "email": "Dorina.Paul@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Dorottya Nagy",
    "email": "Dorottya.Nagy@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Eszter Bognar",
    "email": "Eszter.Bognar@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Frantisek Koudela",
    "email": "Frantisek.Koudela@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Gabor Skodnitz",
    "email": "Gabor.Skodnitz@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Greta Borbely",
    "email": "Greta.Borbely@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Gyorgy Arnotszky",
    "email": "Gyorgy.Arnotszky@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Hana Hrubonova",
    "email": "Hana.Hrubonova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Ildiko Kovacs",
    "email": "Ildiko.Kovacs9@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Janos Hidvegi",
    "email": "Janos.Hidvegi@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Jiri Bares",
    "email": "Jiri.Bares@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Jozsef Vass",
    "email": "Jozsef.Vass@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Katerina Markova",
    "email": "Katerina.Markova5@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Katerina Novotna",
    "email": "katerina.novotna2@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Kinga Volgyesi",
    "email": "Kinga.Volgyesi@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Lenka Bacinska",
    "email": "Lenka.Bacinska@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Lucia Pojezdalova",
    "email": "Lucia.Pojezdalova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Lucie Hradecka",
    "email": "Lucie.Hradecka@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Lucie Matejckova",
    "email": "Lucie.Matejckova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Ludmila Paulikova",
    "email": "Ludmila.Paulikova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Lukas Vano",
    "email": "Lukas.Vano@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Maria Vegso Velkovics",
    "email": "Maria.Vegso-Velkovics@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Marie Dvorakova",
    "email": "Marie.Dvorakova2@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Martin Kubin",
    "email": "Martin.Kubin@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Martina Kramperova",
    "email": "Martina.Kramperova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Martina Odraskova",
    "email": "Martina.Odraskova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Martina Olivova",
    "email": "Martina.Olivova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Melitta Kiraly",
    "email": "Melitta.Kiraly@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Michaela Fabianova",
    "email": "Michaela.Fabianova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Mikulas Micka",
    "email": "mikulas.micka1@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Monika Budosova",
    "email": "Monika.Budosova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Monika Dvorackova_CZ",
    "email": "Monika.Dvorackova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Monika Kohoutova",
    "email": "Monika.Kohoutova2@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Norbert Galambos",
    "email": "Norbert.Galambos@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Orsolya Kovacs",
    "email": "Orsolya.Kovacs@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Patrik Zizka",
    "email": "patrik.zizka@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Pavel Vlach",
    "email": "Pavel.Vlach@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petr Dvorak",
    "email": "Petr.Dvorak@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petr Hubeny",
    "email": "Petr.Hubeny@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petra Hasenohrlova",
    "email": "Petra.Hasenoehrlova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petra Loudova",
    "email": "Petra.Loudova1@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petra Petervari",
    "email": "Petra.Petervari@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petra Vavro",
    "email": "Petra.Vavro@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Petra Winiarska",
    "email": "Petra.Winiarska@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Radek Maxima",
    "email": "Radek.Maxima@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Rita Szabo",
    "email": "Rita.Szabo@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Roman Bartos",
    "email": "Roman.Bartos2@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Tereza Benesova",
    "email": "Tereza.Benesova1@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Tereza Bibova",
    "email": "Tereza.Bibova@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Veronika Juhasz",
    "email": "VeronikaAnna.Juhasz@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Veronika Laznovska",
    "email": "Veronika.Laznovska@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Viktor Gaal",
    "email": "Viktor.Gaal@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Viktoria Kovacsne Elekes",
    "email": "Viktoria.KovacsneElekes@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Viktória Sass",
    "email": "Viktoria.Sass@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Zdenek Mjartan",
    "email": "Zdenek.Mjartan@tesco.com",
    "type": "Buyer"
  },
  {
    "countryCode": "CZ",
    "name": "Zdenek Vomocil",
    "email": "Zdenek.Vomocil@tesco.com",
    "type": "Buyer"
  }
]);
