import Api from "../services/Api";

// Method to get current List of QuoteSheets
export const generalExportToExcel = async (exportData) => {
try {
      // Add response type to request data
      exportData.responseType = 'blob';
      let response = await Api.post("/excel/general", exportData, { responseType: 'blob' });
      if (response){
        //console.log({response});
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        const timestamp = (new(Date)).toWSDate().replaceAll("-", "") + "_" + (new(Date)).toAppTime().replaceAll(":", "");
        link.href = url;
        link.setAttribute('download', exportData.fileName+"_"+timestamp);
        document.body.appendChild(link);
        link.click();
        link.remove();

      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      };

    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
  }


// Method to generate data for Price Comparison report
export const reportPriceComparison = async (year, week, country) => {
  try {
    const params = new URLSearchParams();
    params.append('year', year);
    params.append('week', week);
    if (country) params.append('country', country);

    const response = await Api.get("/report/priceComparison", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
}

// Method to generate data for Logistics Changes report
export const reportLogisticsChanges = async (year, week, warehouseCode, supplierId, buyer) => {
  try {
    const params = new URLSearchParams();
    params.append('year', year);
    params.append('week', week);
    if (warehouseCode && warehouseCode?.length == 2) {
      params.append('country', warehouseCode);
    } else if (warehouseCode) {
      params.append('warehouseCode', warehouseCode);
    }
    if (supplierId) params.append('supplierId', supplierId);
    if (buyer) params.append('buyer', buyer);

    const response = await Api.get("/report/logisticsChanges", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
}


// Method to generate DeliverySchedule and download it as Excel file
export const deliveryScheduleExcel = async (exportData) => {
  try {
        // Add response type to request data
        exportData.responseType = 'blob';
        let response = await Api.post("/excel/deliverySchedule", exportData, { responseType: 'blob' });
        if (response){
          //console.log({response});
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          const timestamp = (new(Date)).toWSDate().replaceAll("-", "") + "_" + (new(Date)).toAppTime().replaceAll(":", "");
          link.href = url;
          link.setAttribute('download', exportData.fileName+"_"+timestamp);
          document.body.appendChild(link);
          link.click();
          link.remove();
  
        } else {
          console.error(response.payload ? response.payload : response.message);
          throw new Error(response.payload ? response.payload : response.message);
        };
  
      } catch (error) {
        console.log(error);
        throw new Error(error.message, {cause: error});
      }
    }
  
  
  