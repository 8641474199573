import Api from "../services/Api";

// Method to get current List of DCs
export const getDCList = async (id, code, country) => {
    try {
      const params = new URLSearchParams();
      if (id) params.append('id', id);
      if (code) params.append('code', code);
      if (country) params.append('country', country);

      const response = await Api.get("/lov/wh", params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Create new DC
export const createDC = async (data) => {
    try {
      const response = await Api.post("/lov/wh", data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Update DC data
export const updateDC = async (data) => {
    try {
      console.log("updateDC API input data: ", { data });
      const params = new URLSearchParams();
      params.append('id', data?.id);

      const response = await Api.put("/lov/wh", params, data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Delete DC
export const deleteDC = async (id) => {
    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const response = await Api.delete("/lov/wh", params);
      console.log({response});

      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message, {cause: error});
    }
};

///////////////////////////////////////////////////////////////
// Method to get List data
export const getListData = async (list, id) => {
    try {
      const params = new URLSearchParams();
      if (id) params.append('id', id);

      const response = await Api.get("/lov/"+list, params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Method to create new row in List
export const createListRow = async (list, data) => {
    try {
      const response = await Api.post("/lov/"+list, data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Method to create update row in List
export const updateListRow = async (list, data) => {
    try {
      const params = new URLSearchParams();
      params.append('id', data?.id);

      const response = await Api.put("/lov/"+list, params, data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Method to create delete row in List
export const deleteListRow = async (list, id) => {
    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const response = await Api.delete("/lov/"+list, params);
      console.log({response});

      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message, {cause: error});
    }
};
