import { writable } from 'svelte/store';

export const years =  writable([]);

export const weeks =  writable([]);
/*
  {
    "id": "f2023w14",
    "tesco_week_name": "f2023w14",
    "tesco_year": 2023,
    "tesco_week": 14,
    "week_start": "2023-05-29T00:00:00.000Z",
    "week_end": "2023-06-04T00:00:00.000Z",
    "year": 2023,
    "week_no": 22,
    "week_name": "2023W22",
    "status": "open",
    "current_week": false,
    "stats": [
      {
        "dc": "CZK",
        "forecasted": 0,
        "purchased": 0,
        "not_purchased": 0,
        "with_comment": 0,
        "buyers_pending": 0
      },
      {
        "dc": "SKB",
        "forecasted": 1000,
        "purchased": 900,
        "not_purchased": 100,
        "with_comment": 5,
        "buyers_pending": 1
      },
      {
        "dc": "SKP",
        "forecasted": 1000,
        "purchased": 0,
        "not_purchased": 0,
        "with_comment": 0,
        "buyers_pending": 0
      },
      {
        "dc": "HUB",
        "forecasted": 1000,
        "purchased": 0,
        "not_purchased": 0,
        "with_comment": 0,
        "buyers_pending": 0
      }
    ]
  },
  {
    "id": "f2023w13",
    "tesco_week_name": "f2023w13",
    "tesco_year": 2023,
    "tesco_week": 13,
    "week_start": "2023-05-22T00:00:00.000Z",
    "week_end": "2023-05-28T00:00:00.000Z",
    "year": 2023,
    "week_no": 21,
    "week_name": "2023W21",
    "status": "forecasted",
    "current_week": true,
    "stats": [
      {
        "dc": "CZK",
        "forecasted": 0,
        "purchased": 0,
        "not_purchased": 0,
        "with_comment": 0,
        "buyers_pending": 0
      },
      {
        "dc": "SKB",
        "forecasted": 1000,
        "purchased": 900,
        "not_purchased": 100,
        "with_comment": 5,
        "buyers_pending": 1
      },
      {
        "dc": "SKP",
        "forecasted": 1000,
        "purchased": 0,
        "not_purchased": 0,
        "with_comment": 0,
        "buyers_pending": 0
      },
      {
        "dc": "HUB",
        "forecasted": 1000,
        "purchased": 0,
        "not_purchased": 0,
        "with_comment": 0,
        "buyers_pending": 0
      }
    ]
  },
  {
    "id": "f2023w12",
    "tesco_week_name": "f2023w12",
    "tesco_year": 2023,
    "tesco_week": 12,
    "week_start": "2023-05-15T00:00:00.000Z",
    "week_end": "2023-05-21T00:00:00.000Z",
    "year": 2023,
    "week_no": 20,
    "week_name": "2023W20",
    "status": "purchased",
    "current_week": false,
    "stats": []
  },
  {
    "id": "f2023w11",
    "tesco_week_name": "f2023w11",
    "tesco_year": 2023,
    "tesco_week": 11,
    "week_start": "2023-05-08T00:00:00.000Z",
    "week_end": "2023-05-14T00:00:00.000Z",
    "year": 2023,
    "week_no": 19,
    "week_name": "2023W19",
    "status": "closed",
    "current_week": false,
    "stats": []
  },
  {
    "id": "f2023w10",
    "tesco_week_name": "f2023w10",
    "tesco_year": 2023,
    "tesco_week": 10,
    "week_start": "2023-05-01T00:00:00.000Z",
    "week_end": "2023-05-07T00:00:00.000Z",
    "year": 2023,
    "week_no": 18,
    "week_name": "2023W18",
    "status": "closed",
    "current_week": false,
    "stats": []
  },
  {
    "id": "f2023w09",
    "tesco_week_name": "f2023w09",
    "tesco_year": 2023,
    "tesco_week": 9,
    "week_start": "2023-04-24T00:00:00.000Z",
    "week_end": "2023-04-30T00:00:00.000Z",
    "year": 2023,
    "week_no": 17,
    "week_name": "2023W17",
    "status": "closed",
    "current_week": false,
    "stats": []
  },
  {
    "id": "f2023w08",
    "tesco_week_name": "f2023w08",
    "tesco_year": 2023,
    "tesco_week": 8,
    "week_start": "2023-04-17T00:00:00.000Z",
    "week_end": "2023-04-23T00:00:00.000Z",
    "year": 2023,
    "week_no": 16,
    "week_name": "2023W16",
    "status": "closed",
    "current_week": false,
    "stats": []
  },
  {
    "id": "f2023w07",
    "tesco_week_name": "f2023w07",
    "tesco_year": 2023,
    "tesco_week": 7,
    "week_start": "2023-04-10T00:00:00.000Z",
    "week_end": "2023-04-16T00:00:00.000Z",
    "year": 2023,
    "week_no": 15,
    "week_name": "2023W15",
    "status": "closed",
    "current_week": false,
    "stats": []
  }
]);*/
