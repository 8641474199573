<script lang="ts">
    //    import Autocomplete from "@smui-extra/autocomplete";
    import Autocomplete from "../../extra/Autocomplete.svelte";
    import { onMount } from 'svelte';

    import Textfield from "@smui/textfield";
    import { Text } from "@smui/list";
    import CircularProgress from "@smui/circular-progress";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify } from "@mdi/js";
    import { _ } from "svelte-i18n";

    import { classMap } from "@smui/common/internal";

    import { getUserManagement } from "../../../api/User.js";

    export let value = "";
    export let icon = true;
    export let disableFields: boolean = false;
    export let emailFromList: boolean = false; // Return true if email is from api list

    let className = "";
    export { className as class };

    // TM as default
    export let role = "TechnicalManager";
    export let label = "technical_manager";
    export let variant = "";
    export let style = "";
    export let small = false;
    export let white = false;
    export let insideDataTable = false;

    // Email search
    let cachedUserList = [];
    let previousValue = "";


    // Set menu width according to parent element
    let edEl;
    let menuWidth;
    let menuBottom;

    // Validate email on mount (if email entered)
    let validateOnMount = false;

    // On role changes reset data
    $: if (role) {
        cachedUserList = [];
    }

    // $: {
    //     console.log("disableFields", disableFields);
    //     console.log({ menuWidth });
    //     console.log({ menuBottom });
    //     console.log({ emailFromList });
    // }

	onMount(() => {
        // It value exists update emailFromList flag
		if (value) {
            validateOnMount = true;
		}
	});

    async function searchEmail(input, role) {
        //console.log("====================== searchEmail =========================", role, input);
        //console.log({emailFromList});
        if (disableFields) {
            return [];
        }
        try {
            if (cachedUserList.filter((item) => item.email.toLowerCase().includes(input.toLowerCase())).length == 0){
                const userResponse = await getUserManagement(null, role);
                cachedUserList = userResponse.sort((a, b) => a.lastName.localeCompare(b.lastName));
            }
            //console.log({cachedUserList});
        } catch (err) {
            console.error("Error fetching data:", err);
            if (err?.cause?.response?.status == 401) { throw(err) }
            return [];
        }

        // Value exists, validate that email exists on list
        if (validateOnMount && value) {
            handleInput({target: {value: value}});
            validateOnMount = false;
        }

        // Filter fetched list
        return cachedUserList.filter((item) => item.email.toLowerCase().includes(input.toLowerCase())).map((item) => item.email);
    }

    function handleInput(event) {
        //console.log("====================== handleInput =========================", event);
        //console.log({cachedUserList});
        const currentInput = event.target.value;
        // Check if the current input matches exactly any email in the list without further modification
        if (cachedUserList.find((item) => item.email === currentInput) && currentInput !== previousValue) {
            emailFromList = true;
        } else {
            emailFromList = false;
        }
        previousValue = currentInput;
    }
</script>

{#key role}
    <Autocomplete
        search={(input) => searchEmail(input, role)}
        on:input={handleInput}
        bind:value
        bind:this={edEl}
        disabled={disableFields}
        showMenuWithNoInput={true}
        selectOnExactMatch={true}
        class={classMap({
            [className]: true,
            "edit-cell-border": true,
        })}
        menu$class={insideDataTable ? "mdc-menu-surface--fixed with-parameter__menu" : ""}
        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
        on:focusin={() => {
            //console.log("In Focus", {edEl});
            menuWidth = edEl?.getElement()?.clientWidth;
            menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
        }}
        on:click={() => {
            //console.log("On Click", {edEl});
            menuWidth = edEl?.getElement()?.clientWidth;
        }}
        menuBottom="edEl?.getElement()?.getBoundingClientRect()?.bottom;"
        on:SMUIAutocomplete:selected={(option) => {
            emailFromList = true;
        }}
    >
        <Text slot="loading" style="display: flex; width: 100%; justify-content: center; align-items: center;">
            <CircularProgress style="height: 24px; width: 24px;" indeterminate />
        </Text>
        <Text slot="option" let:option>
            {option.email}
        </Text>
        <Textfield
            {variant}
            bind:value
            withLeadingIcon={icon}
            label={$_(label)}
            class={`${small ? "small" : ""} ${white ? "white" : ""}`}
            {style}
            input$autocomplete="BuyerTmSearch"
            disabled={disableFields}
        >
            <svelte:fragment slot="leadingIcon">
                {#if icon}
                    <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                        <path fill="currentColor" d={mdiMagnify} />
                    </Icon>
                {/if}
            </svelte:fragment>
        </Textfield>
    </Autocomplete>
{/key}
