import Api from "../services/Api";

// Method to get current List of Forecasts
export const getForecast = async (id, year, week, country, warehouseId, productId, productDetailId, productDesc, tpnb, description, ean, buyer, technicalManager, note, status, reasonCode, fileName, purchaseConfirmed) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (country) params.append('country', country);
    if (warehouseId) params.append('warehouseId', warehouseId);
    if (productId) params.append('tpnEn', productId);
    if (productDetailId) params.append('productDetailId', productDetailId);
    if (productDesc) params.append('descriptionEn', productDesc);
    if (tpnb) params.append('tpnb', tpnb);
    if (description) params.append('description', description);
    if (ean) params.append('ean', ean);
    if (buyer) params.append('buyer', buyer);
    if (technicalManager) params.append('technicalManager', technicalManager);
    if (note) params.append('note', note);
    if (status) params.append('status', status);
    if (reasonCode) params.append('reasonCode', reasonCode);
    if (fileName) params.append('fileName', fileName);
    if (purchaseConfirmed) params.append('purchaseConfirmed', purchaseConfirmed);

    console.log('getUserManagement params:', params.toString());

    const response = await Api.get("/forecast/", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Create new Forecast
export const createForecast = async (data) => {
  try {
    let url = Array.isArray(data) ? "/forecast/array" : "/forecast/";

    const response = await Api.post(url, data);
    console.log("createForecast", { response });
    if (response.status) {
      if (data.idXLS && data.idXLS != null) {
        response.payload[0].idXLS = data.idXLS;
      }
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Update Forecast data
export const updateForecast = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/forecast/", params, data);
    //console.log({response});
    if (response.status) {
      return {...response.payload, type: "forecast", operation: "update", id: data?.id};
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Delete Forecast
export const deleteForecast = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/forecast/", params);
    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Delete Forecasts (multi)
export const deleteForecasts = async (ids) => {
  try {
    const response = await Api.post("/forecast/arrayDelete", ids);

    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};



export const forecastItemsExistsData = async (data) => {
  console.log("forecastItemsExistsData input", { data })
  try {
    const response = await Api.post("/forecast/exist", data);
    console.log("forecastItemsExistsData response: ", { response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

