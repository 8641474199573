<script lang="ts">
    import Autocomplete from "../../extra/Autocomplete.svelte";
    import { onMount } from "svelte";
    import Textfield from "@smui/textfield";
    import IconButton, { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiCloseCircleOutline } from "@mdi/js";
    import { _ } from "svelte-i18n";
    import { classMap } from "@smui/common/internal";
    import { buyersList } from "../../../stores/Buyers";

    export let nameInput = ""; // The name to match, "lastName firstName"
    export let emailOutput = "";
    export let uuidOutput = "";
    export let icon = true;
    export let disableFields: boolean = false;
    export let emailFromList: boolean = false; // Return true if email is from api list
    export let clear: boolean = false; // Signal to clear  

    let className = "";
    export { className as class };

    export let label = "buyer";
    export let variant = "outlined";
    export let insideDataTable = false;

    let showTrailingIcons = true;

    // Set menu width according to parent element
    let edEl;
    let menuWidth;
    let menuBottom;

    // Get buyer list
    let options = $buyersList.sort((a, b) => a.lastName.localeCompare(b.lastName));
    let value = {};
    let text = "";

    // Find matching buyer and preselect it
    onMount(() => {
        if (nameInput) {
            // Find all options that match the name input: `${option.lastName} ${option.firstName}`
            const matchingOptions = options.filter(option => `${option.lastName} ${option.firstName}` === nameInput);
            
            // If exactly match, preselect it (multiple users with same name?)
            if (matchingOptions.length === 1) {
                const matchedOption = matchingOptions[0];
                value = matchedOption;
                text = `${matchedOption.lastName} ${matchedOption.firstName}`;  // Set displayed text
                emailOutput = matchedOption.email;
                uuidOutput = matchedOption.uuid;
                emailFromList = true;
                console.log("Preselected matched option:", { value, emailOutput, uuidOutput });
            } else if (matchingOptions.length > 1) {
                // Log if there are multiple matches
                console.log("Multiple matches found for", nameInput, matchingOptions);
            } else {
                // Log if no matches are found
                console.log("No match found for", nameInput);
                emailFromList = false;
            }
        }
    });

    // update email and uuid on value change
    $: if (value && value.email && value.uuid) {
        emailOutput = value.email;
        uuidOutput = value.uuid;
        //console.log("Updated email and uuid", { emailOutput, uuidOutput });
    } else {
        emailOutput = "";
        uuidOutput = "";
    }

    $: if (clear) {
        text = "";
        emailOutput = "";
        uuidOutput = "";       
        clear = false;
    }
</script>

<Autocomplete
    {options}
    getOptionLabel={(option) => (option ? `${option.lastName} ${option.firstName}` : "")}
    bind:text
    bind:value
    bind:this={edEl}
    disabled={disableFields}
    selectOnExactMatch={true}
    class={classMap({
        [className]: true,
    })}
    menu$class={insideDataTable ? "mdc-menu-surface--fixed with-parameter__menu" : ""}
    on:focusin={() => {
        menuWidth = edEl?.getElement()?.clientWidth;
        menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;

        // Select the text in the input field
        const inputElement = edEl.getElement()?.querySelector("input");
        if (inputElement) {
            inputElement.select();
        }
    }}
    style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
    on:click={() => {
        menuWidth = edEl?.getElement()?.clientWidth;
    }}
    menuBottom="edEl?.getElement()?.getBoundingClientRect()?.bottom;"
>
    <Textfield
        {variant}
        bind:value={text}
        withLeadingIcon={icon}
        showTrailingIcons
        label={$_(label)}
        class={classMap({
            [className]: true,
        })}
        input$autocomplete="BuyerSearch"
        disabled={disableFields}
    >
        <svelte:fragment slot="leadingIcon">
            {#if icon}
                <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                    <path fill="currentColor" d={mdiMagnify} />
                </Icon>
            {/if}
        </svelte:fragment>
    </Textfield>
</Autocomplete>
