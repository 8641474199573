<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import CircularProgress from "@smui/circular-progress";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import { mdiEye, mdiArrowULeftTopBold } from "@mdi/js";
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";
  import type { productDelegationList } from "../../../../types/Management";

  // Items
  export let delegationList: productDelegationList[] = [];

  // Filters
  export let tableLoad = false;
  export let tableSearchMessage = false;

  const dispatch = createEventDispatcher();

  // CE time format
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("cs-CZ", options);
  }

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, delegationList.length);
  $: slice = delegationList.slice(start, end);
  $: lastPage = Math.max(Math.ceil(delegationList.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  // Table Head
  let tableHead = [
    { columnId: "actions", label: "", numeric: false, extraClasses: "" },
    { columnId: "type", label: "type", numeric: false, extraClasses: "" },
    { columnId: "fromUser", label: "from_user", numeric: false, extraClasses: "" },
    { columnId: "toUser", label: "to_user", numeric: false, extraClasses: "" },
    { columnId: "country", label: "country", numeric: false, extraClasses: "" },
    { columnId: "status", label: "status", numeric: false, extraClasses: "" },
    { columnId: "created", label: "created", numeric: false, extraClasses: "" },
  ];
</script>

<DataTable stickyHeader table$aria-label="Delegate product" style="width: 100%;">
  <Head>
    <Row>
      {#each tableHead as head (head.columnId)}
        <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header {head.extraClasses}">
          <Label>{$_(head.label)}</Label>
        </Cell>
      {/each}
    </Row>
  </Head>
  <Body>
    {#if tableLoad}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center">
          <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
          {$_("loading_wait")}
        </Cell>
      </Row>
    {:else if slice.length > 0}
      {#each slice as delegateRow (delegateRow.id)}
        <Row>
          {#each tableHead as head (head.columnId)}
            <Cell>
              {#if head.columnId === "actions"}
                <!-- View button -->
                <IconButton title={$_("view")} size="button" class="tescoblue-text" on:click={() => dispatch("viewProductDelegation", delegateRow)}>
                  <svg viewBox="0 0 24 24" class="tescoblue-text">
                    <path fill="currentColor" d={mdiEye} />
                  </svg>
                </IconButton>
                <!-- Revoke button -->
                <IconButton title={$_("revoke")} size="button" class="tescoblue-text" on:click={() => dispatch("revokeProductDelegation", delegateRow)}>
                  <svg viewBox="0 0 24 24" class="pa-0 ma-0">
                    <path fill="currentColor" d={mdiArrowULeftTopBold} />
                  </svg>
                </IconButton>
              {:else if head.columnId === "type"}
                {delegateRow.type === "Buyer" ? $_("by_buyer") : $_("by_technical_manager")}
              {:else if head.columnId === "status"}
                {delegateRow.status === 1 ? $_("active") : $_("revoked")}
              {:else if head.columnId === "created"}
                {formatDate(delegateRow.created)}
              {:else}
                {delegateRow[head.columnId]}
              {/if}
            </Cell>
          {/each}
        </Row>
      {/each}
    {:else if tableSearchMessage}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center" style="width: 100%">{$_("search_instructions")}</Cell>
      </Row>
    {:else}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
      </Row>
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {delegationList.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title={$_("first_page")} on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title={$_("prev_page")} on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title={$_("next_page")} on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title={$_("last_page")} on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
      >last_page</IconButton
    >
  </Pagination>
</DataTable>

<style>
</style>
