<script>

    import { createEventDispatcher } from 'svelte';

    import Button, { Label } from '@smui/button';
    import Dialog, { Title as DTitle, Content as DContent, Actions, InitialFocus } from '@smui/dialog';

    export let open;
    export let title;
    export let btnText = 'close';
    export let headerColors = "tescoblue white-text";
    export let big = false;

    const dispatch = createEventDispatcher();

</script>
<Dialog
    bind:open={open}
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style="{big ? 'width: 850px; max-width: calc(100vw - 32px);' : ''}"
    >
    <DTitle id="data-changed-title" class="{headerColors}">{title}</DTitle>
    <DContent id="data-changed-content" class="mt-4">
        <slot ></slot>
    </DContent>
    <div class="mdc-dialog__actions gap-1 pr-4 mr-4 space-around">
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click="{() => {dispatch('close'); open=false}}"
        >
            <Label>{btnText}</Label>
        </Button>
    </div>
</Dialog>

<style>

</style>
