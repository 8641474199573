<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Textfield from "@smui/textfield";
    import Button from "@smui/button";
    import Select, { Option } from "@smui/select";
    import { mdiMagnify, mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    import BuyerTmEmailAutocomplete from "../../common/BuyerTmEmailAutocomplete.svelte";
    import DTCountrySelectCellAll from "../../common/DTCountrySelectCellAll.svelte";

    const dispatch = createEventDispatcher();

    let userTypeSearch = "";
    let userFromSearch = "";
    let userToSearch = "";
    let tpnEnSearch = "";
    let statusSearch = "";
    let countrySearch = "";
</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100">
    <Select variant="outlined" label={$_("delegate_user_type")} title={$_("delegate_user_type")} class="white small" bind:value={userTypeSearch}>
        <Option value="" />
        <Option value="Buyer">{$_("by_buyer")}</Option>
        <Option value="TechnicalManager">{$_("by_technical_manager")}</Option>
    </Select>
    <DTCountrySelectCellAll bind:value={countrySearch} title={$_("country")} allFlag={true} classe={"small white"} noLabel={false} />
    <BuyerTmEmailAutocomplete
        bind:value={userFromSearch}
        role={userTypeSearch ? userTypeSearch : "Buyer,TechnicalManager"}
        label={$_("from_user")}
        variant="outlined"
        small={true}
        white={true}
        style="min-width: 300px;"
    />
    <BuyerTmEmailAutocomplete
        bind:value={userToSearch}
        role={userTypeSearch ? userTypeSearch : "Buyer,TechnicalManager"}
        label={$_("to_user")}
        variant="outlined"
        small={true}
        white={true}
        style="min-width: 300px;"
    />

    <Textfield
        variant="outlined"
        bind:value={tpnEnSearch}
        maxlength="50"
        placeholder=""
        title={$_("ce_tpn")}
        class="white w-100 small flex-0"
        input$class="mnw-200 pl-1"
        size="40"
        label={$_("ce_tpn")}
    >
        <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
            <path fill="currentColor" d={mdiMagnify} />
        </svg>
    </Textfield>

    <Select
        variant="outlined"
        label={$_('delegate_status')}
        title="{$_('delegate_status')}"
        class="white small"
        bind:value={statusSearch}
        style="min-width: 220px;"
        >
        <Option value='' />
        <Option value=0>{$_('revoked')}</Option>
        <Option value=1>{$_('active')}</Option>
    </Select>

    <Button
        title={$_("clear")}
        class="tescoblue white-text"
        on:click={() => {
            userTypeSearch = "";
            userFromSearch = "";
            tpnEnSearch = "";
            userToSearch = "";
            countrySearch = "";
            statusSearch = "";
            dispatch("clearList");
        }}
    >
        <svg viewBox="0 0 24 24" width="20" height="20">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </svg>
    </Button>
    <Button
        class="tescoblue white-text pr-4"
        title={$_("search")}
        on:click={() => {
            dispatch("loadList", {
                userTypeSearch: userTypeSearch,
                userFromSearch: userFromSearch,
                tpnEnSearch: tpnEnSearch,
                userToSearch: userToSearch,
                countrySearch: countrySearch,
                statusSearch: statusSearch
            });
        }}
    >
        <svg viewBox="0 0 24 24" width="20" height="20">
            <path fill="currentColor" d={mdiDownloadCircleOutline} />
        </svg>
        {$_("search")}
    </Button>
</Flex>

<style>
</style>
