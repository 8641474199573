import axios from "axios";

import { writable, get as getValue } from 'svelte/store';

import { endPointUrl } from '../stores/AppConfig'

// Create a instance of axios to use the same base url.
const axiosAPI = axios.create({
  baseURL : getValue(endPointUrl)
});

// implement a method to execute all the request from here.
const apiRequest = (method, url, request) => {
  return axiosAPI({
    method,
    url,
    data: request,
    responseType: request?.responseType || 'json'
  }).then(res => {
    if (res.config.responseType === 'blob') {
      return res;
    }
    return Promise.resolve(res.data);
  }).catch(err => {
    return Promise.reject(err);
  });
};

// Append parameters to url
const prepareUrl = (url, params) => (params ? [url, params.toString()].join('?') : url);

// function to execute the http get request
const get = (url, params, request) => apiRequest("get", prepareUrl(url, params), request);

// function to execute the http delete request
const deleteRequest = (url, params, request) =>  apiRequest("delete", prepareUrl(url, params), request);

// function to execute the http post request for delete operations requiring a body
const postDelete = (url, request) => apiRequest("post", url, request);

// function to execute the http post request
const post = (url, request) => apiRequest("post", url, request);

// function to execute the http put request
const put = (url, params, request) => apiRequest("put", prepareUrl(url, params), request);

// function to execute the http path request
const patch = (url, request) =>  apiRequest("patch", url, request);

const refreshBaseUrl = () => {
  axiosAPI.defaults.baseURL = getValue(endPointUrl);
}

// expose your method to other services or actions
const API = {
    get,
    delete: deleteRequest,
    post,
    put,
    patch,
    postDelete,
    refreshBaseUrl
};
export default API;
