import axios from "axios";

import { get as getValue } from 'svelte/store';

import { toolkitEndPointUrl } from '../stores/AppConfig'

// Create a instance of axios to use the same base url.
const axiosAPI = axios.create({
  baseURL: getValue(toolkitEndPointUrl)+'api/'
});

// implement a method to execute all the request from here.
const apiRequest = (method, url, request) => {
    console.log("apiRequest: ",method, url, request)
    return axiosAPI({
        method,
        url,
        data: request
      }).then(res => {
        return Promise.resolve(res.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
};


const refreshBaseUrl = () => {
  axiosAPI.defaults.baseURL = getValue(toolkitEndPointUrl)+'api/';
}

const logout = () => apiRequest("post", "logout", {"event": "logout"});

// expose your method to other services or actions
const toolkitApi = {
    logout,
    refreshBaseUrl
};
export default toolkitApi;
