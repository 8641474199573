<script>

  import { onMount } from 'svelte';
  import Router from 'svelte-spa-router';
  import { appName, endPointUrl, toolkitEndPointUrl } from "./stores/AppConfig";
  import { unauthorized, sessionExpired, authenticatedUser, userLanguage, selectedCountry,
           contentDiv, snackbarInfo, snackbarSuccess, snackbarWarning, snackbarError,
           apiError, appInitFailed, apiErrorDetails } from "./stores/AppStatus";

  import Snackbar, { Label, Actions } from '@smui/snackbar';
  import Button from '@smui/button';
  import IconButton from '@smui/icon-button';
  import DataTable, { Head, Body, Row, Cell} from '@smui/data-table';

  import Header from './components/Header.svelte';
  import Footer from './components/Footer.svelte';

  import AppError from './components/AppError.svelte';

  import routes from './routes';
  import { _ } from 'svelte-i18n';

  import { waitLocale, isLoading, locale } from 'svelte-i18n';

  import ErrorDialog from "./components/ErrorDialog.svelte";

  import Api from "./services/Api";
  import ToolkitApi from "./api/ToolkitApi";


  export async function preload() {
    // awaits for the loading of the 'en-US' and 'en' dictionaries
    return waitLocale();
  }

  export async function setEndPointUrl() {
    const response = await fetch("/config/endPointConfig.json");
    const endPointConfig = await response.json();
    console.log({endPointConfig});
    $endPointUrl = endPointConfig?.endPointUrl;
    $toolkitEndPointUrl = endPointConfig?.toolkitEndPointUrl;
    console.log({$endPointUrl}, {$toolkitEndPointUrl});
    Api.refreshBaseUrl();
    ToolkitApi.refreshBaseUrl()
  }

  $: $locale = $userLanguage;

  onMount(() => {
    preload();
    setEndPointUrl();
    setupErrorHandling();
  })

/*****/
    const report_error = (msg) => {
        console.log("report_error(): ", msg);
        // console.log("traceid: ", msg.reason.cause.response.headers.traceid);

        const stack = msg?.stack
            .split('\n')
            .slice(2)
            .map((line) => line.replace(/\s+at\s+/, ''))
            .join('\n');
        console.log(stack);

        document.getElementsByTagName("body")[0].innerHTML = [
            '<section id="runtime_error" style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">',
            '    <div class="pt-0 pl-0 pr-0 mnw-400">',
            '        <div class="" style="background-color: #eaeaea; box-shadow: 2px 2px 10px 0px gray; border-radius: 4px 4px 4px 4px;">',
            '            <div class="flex-column w-100 gap-2">',
            '                <h1 class="w-100 pt-2 pb-2 ma-0 tescored white-text text-center top-rounded">Runtine Error</h1>',
            '                <div class="pa-2 bottom-rounded">',
            '                   <div class="fs-20 text-center pb-2">Sorry, an error occured.</div>',
            '                   <div style="display: flex"><div style="width: 5rem;"><b>Message:</b> </div>', msg.message, '</div>',
            '                   <div style="display: flex"><div style="width: 5rem;"><b>File:</b> </div>', msg?.fileName, '</div>',
            '                   <div style="display: flex"><div style="width: 5rem;"><b>Line:</b> </div>', msg?.lineNumber, '</div>',
            '                   <div style="display: flex"><div style="width: 5rem;"><b>Column:</b> </div>', msg?.columnNumber, '</div>',
            '                   <div style="display: flex"><div style="width: 5rem;"><b>Trace id:</b> </div>', msg?.cause?.response?.headers?.traceid, '</div>',
            '                </div>',
            '            </div>',
            '        </div>',
            '    </div>',
            '</section>'
        ].join("");
    }



    const setupErrorHandling =(() => {

        const handle_rejection = (e) => {
            console.log('handle_rejection');
            console.log({e});
            e.preventDefault();

            if (document.getElementById('runtime_error')) {
                console.log("Another error already shown. Exiting.");
                return;
            }

            if (e?.reason?.cause?.response?.status == 401) {
                console.log("Unauthorized!");
                if (!$unauthorized) {
                    $sessionExpired = true;
                }
                $unauthorized = true;
                $authenticatedUser = {};
            } else {
                console.log("call report_error");
                report_error(e?.reason);
            }

        }

        const handle_error = (e) => {
            console.log('handle_error');
            e.preventDefault();

            if (document.getElementById('runtime_error')) {
                console.log("Another error already shown. Exiting.");
                return;
            }

            if (e?.reason?.cause?.response?.status == 401) { // Unauthorized
                console.log("Unauthorized!")
                if (!$unauthorized) {
                    $sessionExpired = true;
                }
                $unauthorized = true;
                $authenticatedUser = {};
            } else if (e?.error?.message != "Tried to dipatch event without element.") { // Workaround to SMUI issue
                console.log("call report_error");
                report_error(e?.error);
            }
        }

        window.addEventListener('unhandledrejection', handle_rejection)
        window.addEventListener('error', handle_error)

        return () => {
            window.removeEventListener('unhandledrejection', handle_rejection)
            window.removeEventListener('error', handle_error)
        }
    })

    const handleError = ((e) => {
        console.log("### ERROR ###");
        console.log({e});
        e.preventDefault();

        if (document.getElementById('runtime_error')) {
            console.log("Another error already shown. Exiting.");
            return;
        }

        // Workaround to SMUI issue
        if (e?.error?.message != "Tried to dipatch event without element.") {
            console.log("call report_error");
            report_error(e?.error);
            $apiError = true;
            $apiErrorDetails = e?.error;
        }
    })

</script>

<svelte:window on:error={handleError}/>

<svelte:head>
    <title>{$appName}</title>
    <meta name="robots" content="noindex nofollow" />
    <html lang="en" />
</svelte:head>

{#if $isLoading}
    Loading...
{:else}
<main>

    <Snackbar bind:this={$snackbarInfo.element} labelText={$snackbarInfo.text}  timeoutMs={$snackbarInfo.delay} class="info-msg">
        <Label/>
        <Actions>
            <IconButton class="material-icons" title="{$_('close')}">close</IconButton>
        </Actions>
    </Snackbar>

    <Snackbar bind:this={$snackbarSuccess.element} labelText={$snackbarSuccess.text}  timeoutMs={$snackbarSuccess.delay} class="success-msg">
        <Label/>
        <Actions>
            <IconButton class="material-icons" title="{$_('close')}">close</IconButton>
        </Actions>
    </Snackbar>

    <Snackbar bind:this={$snackbarWarning.element} labelText={$snackbarWarning.text}  timeoutMs={$snackbarWarning.delay} class="warning-msg">
        <Label/>
        <Actions>
            <IconButton class="material-icons" title="{$_('close')}">close</IconButton>
        </Actions>
    </Snackbar>

    <Snackbar bind:this={$snackbarError.element} variant="{$snackbarError.variant}"  labelText={$snackbarError.text} timeoutMs={$snackbarError.delay} class="error-msg">
        <Label/>
        <Actions>
            <IconButton class="material-icons" title="$_('close')">close</IconButton>
        </Actions>
    </Snackbar>

    <header>
        <Header/>
    </header>

    <div bind:this={$contentDiv} class="content bs-bb">
        {#if !$apiError && !$appInitFailed}
            <Router {routes} restoreScrollState={true} />
        {:else}
            <AppError/>
        {/if}
    </div>

    <footer>
        <Footer/>
    </footer>

<dialog id="errorModal">
    <header id="errorModalHeader" style="line-height: 1rem; font-size: 1.6rem;"><center>{$_('error')}</center></header>
    <section id="errorModalBody">
        <div id="errorModalBodyMsg" class="fs-20 text-center pb-2 text-center"></div>
        <DataTable class="slim-rows">
            <Body>
                <Row><Cell class="datatable-tescored-header"><b>{$_('error')}</b></Cell>
                    <Cell style="background-color: white !important">
                    <span id="errorModalBodyDetailCode"></span>
                    <span id="errorModalBodyDetailError"></span><br>
                    <span id="errorModalBodyDetailErrorPayload"></span>
                    </Cell>
                </Row>
                <Row><Cell class="datatable-tescored-header"><b>{$_('message')}</b></Cell><Cell style="background-color: white !important"> <span id="errorModalBodyDetailMsg"></span></Cell></Row>
                <Row><Cell class="datatable-tescored-header"><b>{$_('request_url')}</b></Cell><Cell style="background-color: white !important"><span id="errorModalBodyDetailMethod"></span>&nbsp;<span id="errorModalBodyDetailUrl"></span></Cell></Row>
                <Row><Cell class="datatable-tescored-header"><b>{$_('trace_id')}</b></Cell><Cell style="background-color: white !important"><span id="errorModalBodyTraceId"></span></Cell></Row>
                <Row><Cell class="datatable-tescored-header"><b>{$_('trace_parent')}</b></Cell><Cell style="background-color: white !important"><span id="errorModalBodyTraceParent"></span></Cell></Row>
            </Body>
        </DataTable>
        <span id="errorModalBodyInputData" style="display:none"></span>
        <span id="errorModalBodyUserName" style="display:none"></span>
        <span id="errorModalBodyUserRole" style="display:none"></span>

    </section>
    <actions>
        <div style="display: flex; justify-content: end;" class="gap-1">
            <Button
                id="errorModalActionCopyBtn"
                class="white-tescoblue-outlined"
                on:click="{() => {
                    let errorText = [
                        document.getElementById('errorModalBodyMsg').innerText,
                        'Error: ' + document.getElementById('errorModalBodyDetailCode').innerText + ' ' + document.getElementById('errorModalBodyDetailError').innerText,
                        'Detail: ' + document.getElementById('errorModalBodyDetailErrorPayload').innerText,
                        'Message: ' + document.getElementById('errorModalBodyDetailMsg').innerText,
                        'Request URL: ' + document.getElementById('errorModalBodyDetailMethod').innerText + ' ' + document.getElementById('errorModalBodyDetailUrl').innerText,
                        'Trace ID: ' + document.getElementById('errorModalBodyTraceId').innerText,
                        'Trace Path: ' + document.getElementById('errorModalBodyTraceParent').innerText,
                        '',
                        'User Name: ' + document.getElementById('errorModalBodyUserName').innerText,
                        'User Role: ' + document.getElementById('errorModalBodyUserRole').innerText,
                        '',
                        'Input Data: ',
                        document.getElementById('errorModalBodyInputData').innerText
                    ].join('\n');
                   navigator.clipboard.writeText(errorText);
                }}"
            >{$_('copy_to_clipboard')}</Button>
            <Button
                id="errorModalActionCloseBtn"
                class="tescoblue white-text"
                on:click="{() => {
                    document.getElementById('errorModal').close();
                }}"
            >{$_('close')}</Button>
            <Button
                id="errorModalActionReloadBtn"
                class="tescoblue white-text"
                on:click="{() => {
                    window.location.href = window.location.origin;
                }}"
            >{$_('reload')}</Button>
        </div>
    </actions>
</dialog>

<ErrorDialog />
</main>
{/if}



<style>
    main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        overflow-x: clip;
        overflow-y: clip;
        box-sizing: border-box;
    }

    header {
        background-color: white;
        position:sticky;
        top: 0;
        box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.4) ;
        z-index: 5;
    }

    .content {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        flex:1;
        padding: 0.5rem;
        width: 100%;
        height: calc( 100% - 4.7rem);;
        margin: 0;
        padding: 0;
    }

    footer {
        background-color: white;
        position: sticky;
        bottom: 0;
        display: flex;
    }

    dialog {
        border: none;
        box-shadow: 0px 0px 10px gray;
        border-radius: 4px;
        padding: 0px;
        min-width: 400px;
        min-height: 10rem;

        & header {
            background-color: red;
            color: white;
            line-height: 2rem;
            font-weight: bold;
            padding: 16px;
            text-align: start;
        }

        & section#errorModalBody {
            padding: 16px;
        }

        & actions {
            display: block;
            width: auto;
            border-top: 1px solid grey;
            padding: 16px;
            text-align: right;
        }
    }

    dialog[open]::backdrop {
        background-color: rgb(0 0 0 / 25%);
    }
</style>
