<script lang="ts">
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Switch from "@smui/switch";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button from "@smui/button";
    import { Label } from "@smui/common";
    import { createEventDispatcher } from "svelte";
    import CircularProgress from "@smui/circular-progress";
    import { Text } from "@smui/list";
    import { _ } from "svelte-i18n";

    import { getRMSProduct as apiGetRMSProduct } from "../../../../api/ProduceApi";
    import DTInputCell from "../../common/DTInputCell.svelte";

    import DTCountrySelectCell from "../../common/DTCountrySelectCell.svelte";
    import BuyerTmEmailAutocomplete from "../../common/BuyerTmEmailAutocomplete.svelte";

    import Icon from "@smui/textfield/icon";
    import Textfield from "@smui/textfield";
    import HelperText from "@smui/textfield/helper-text";

    export let data;
    export let availableCountries;
    export let open = false;
    export let type = "add";
    export let headerColors = "tescoblue white-text";

    let selectedRMSProduct = "";

    const dispatch = createEventDispatcher();

    let buttonSaveEnabled = true;
    let selectedRMSProductDisabled = false;
    let errorNoHelper = false;
    let productFetching = false;
    let errorMissingEan = false;

    let inBuyerListEmail;
    let inTMListEmail;

    // Email autocomplete
    let role = "Buyer";
    let label = "";
    let icon = false;
    // let style = "min-width: 535px;";
    // let classe = "tescoblue-text white w-100 flex-1";
    let style = "";
    let classe = "";

    function productDetailClear(type = null) {
        console.log("================= productDetailClear ==============");
        if (data.country && data.country != null && data.country != "") {
            selectedRMSProductDisabled = false;
        } else {
            selectedRMSProductDisabled = true;
        }
        if (type == "hard") data.tpnb = "";
        data.ean = "";
        data.description = "";
        data.descriptionEn = "";
        data.cartonLogistics = "";
        data.palletLogistics = "";
        if (type == "hard") data.buyer = "";
        if (type == "hard") data.technicalManager = "";
        selectedRMSProduct = "";
    }

    async function fetchRMSProductData() {
        if (!data.country || data?.tpnb?.length < 9) {
            if (data?.ean || data?.description) {
                productDetailClear();
                errorNoHelper = false;
                errorMissingEan = false;
            }
            return [];
        }

        console.log("Fetch: ", { data }, selectedRMSProduct);

        try {
            productFetching = true;
            const response = await apiGetRMSProduct(data.country, data.tpnb);

            if (response && response.length > 0) {
                const [product] = response;
                console.log({ response });
                console.log({ product });
                // Update rowData based on the supplier response
                data = {
                    ...data,
                    country: product.country,
                    tpnb: product.item,
                    description: product.description,
                    descriptionEn: product.descriptionEn ? product.descriptionEn : product.description,
                    ean: product.ean,
                    // Convert status
                    status: product.status,
                };
                console.log({ data });
                // Check if user did not change data after WS call
                //rmskDataCheck = { ...rowData };
                errorMissingEan = !product.ean;
                productFetching = false;
                errorNoHelper = false || errorMissingEan;
            } else {
                productFetching = false;
                errorMissingEan = false;
                errorNoHelper = true;
                console.log("No product data received");
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
            if (error?.cause?.response?.status == 401) {
                throw error;
            }
            productFetching = false;
            errorMissingEan = false;
            errorNoHelper = true;

            // Clear the fields
            productDetailClear();
        }
    }

    $: if (!selectedRMSProduct && !data.tpnb) {
        console.log("Not selected product");
        productDetailClear();
    }

    $: buttonSaveEnabled = data && data.buyer && data.tpnb && data.country && data.ean && inBuyerListEmail;
    /* $: buyerTmFieldsDisabled = !(data && data.ean); */

    // $: {
    //     console.log(selectedRMSProduct);
    //     console.log({data});
    //     console.log({buttonSaveEnabled});
    //     console.log({buyerTmFieldsDisabled});
    //     console.log({availableCountries});
    //     console.log({inBuyerListEmail});
    //     console.log({inTMListEmail});
    // }
</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="product-detail-management"
    aria-describedby="product-detail-link"
    class="pa-2"
    surface$style="width: 750px; max-width: calc(100vw - 32px);"
>
    <Title id="product-detail-link" class={headerColors}>
        {$_(type + "_local_product_detail")}
    </Title>
    <Content id="data-changed-content" class="mt-4">
        <DataTable class="input-form no-border pa-2 ma-2">
            <Body>
                <Row>
                    <Cell class="form-label">{$_("country")}</Cell>
                    <DTCountrySelectCell
                        bind:value={data.country}
                        editable={true}
                        replaceList={availableCountries}
                        showIcon={true}
                        style="min-width: 435px;"
                        on:SMUISelect:change={() => {
                            productDetailClear("hard");
                        }}
                    />
                </Row>
                <Row>
                    <Cell class="form-label">{$_("tpnb")}</Cell>
                    {#if data.country}
                        <Textfield
                            variant="outlined"
                            type="text"
                            bind:value={data.tpnb}
                            withTrailingIcon={errorNoHelper}
                            invalid={errorNoHelper}
                            input$maxlength={9}
                            input$autocomplete="LinkLocalProduct"
                            on:input={() => fetchRMSProductData()}
                            required
                            style="min-width: 435px;"
                            class="w-100 edit-cell-border"
                        >
                            <svelte:fragment slot="trailingIcon">
                                {#if productFetching}
                                    <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
                                {:else if errorNoHelper}
                                    <Icon class="material-icons red-text">error</Icon>
                                {/if}
                            </svelte:fragment>
                            <svelte:fragment slot="helper">
                                {#if errorNoHelper}
                                    <HelperText persistent validationMsg class="red-text">
                                        {errorMissingEan ? $_("rms_product_has_no_ean") : $_("rms_product_not_found")}
                                    </HelperText>
                                {/if}
                            </svelte:fragment>
                        </Textfield>
                    {:else}
                        <Cell>{$_("country_not_selected")}</Cell>
                    {/if}
                </Row>
                <Row>
                    <Cell class="form-label">{$_("ean")}</Cell>
                    <DTInputCell bind:value={data.ean} editable={false} numeric={false} class="fs-1rem grey lighten-2" />
                </Row>
                <Row>
                    <Cell class="form-label">{$_("description")}</Cell>
                    <DTInputCell bind:value={data.description} editable={true} numeric={false} class="fs-1rem" />
                </Row>
                <Row>
                    <Cell class="form-label">{$_("description_en")}</Cell>
                    <DTInputCell bind:value={data.descriptionEn} editable={true} numeric={false} class="fs-1rem" />
                </Row>
                <Row>
                    <Cell class="form-label">{$_("buyer")}</Cell>
                    <Cell class="w-100 pa-0 edit-cell-border">
                        <BuyerTmEmailAutocomplete bind:value={data.buyer} {role} {label} {icon} class="w-100" insideDataTable={true} bind:emailFromList={inBuyerListEmail} />
                    </Cell>
                </Row>
                {#if data.tpnb && (!data.buyer || !inBuyerListEmail)}
                    <Row class="helpertext__row">
                        <Cell></Cell>
                        <Cell colspan="4" style="padding: 0">
                            <div class="red-text fs-08rem">
                                {$_(!data.buyer ? "field_is_required" : "email_not_in_list")}
                            </div>
                        </Cell>
                    </Row>
                {/if}
                {#if type == "edit"}
                    <Row>
                        <Cell class="form-label">{$_("autobuy")}</Cell>
                        <Cell class="white">
                            <Switch bind:checked={data.autobuy} />
                        </Cell>
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("status")}</Cell>
                        <Cell class="white">
                            <Switch bind:checked={data.status} />
                        </Cell>
                    </Row>
                {/if}
            </Body>
        </DataTable>
    </Content>

    <Actions>
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click={() => {
                dispatch("productDetailCancel");
                data = {};
            }}
        >
            <Label>{$_("cancel")}</Label>
        </Button>
        <Button
            action="save"
            disabled={!buttonSaveEnabled}
            class="white-text {buttonSaveEnabled ? 'tescoblue' : 'grey lighten-2'}"
            on:click={() => dispatch("productDetailSave", data)}
        >
            <Label>{type == "add" ? $_("add") : $_("save")}</Label>
        </Button>
    </Actions>
</Dialog>