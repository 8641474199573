<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Textfield from "@smui/textfield";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";

    import SelectStatus from "./SelectStatus.svelte";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    const dispatch = createEventDispatcher();

    let ceSuppNoSearch = "";
    let ceSuppNameSearch = "";
    let ceSupplierStatus = "";
    let rmsSupplierNo = "";

    /* console.log(ceSupplierStatus) */

</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100">
    <Textfield
        variant="outlined"
        bind:value={ceSuppNoSearch}
        input$maxlength={7}
        input$pattern={"\\d+"}
        title={$_("search_supplier")}
        class="white w-100 small flex-0"
        input$class="mnw-200 pl-1"
        label={$_("ce_supplier_no")}
    >
        <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
            <path fill="currentColor" d={mdiMagnify} />
        </svg>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={ceSuppNameSearch}
        input$maxlength={200}
        title={$_("name")}
        class="white w-100 small flex-0"
        input$class="mnw-200 pl-1"
        label={$_("ce_supplier_name")}
    >
        <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
            <path fill="currentColor" d={mdiMagnify} />
        </svg>
    </Textfield>

    <Textfield
        variant="outlined"
        bind:value={rmsSupplierNo}
        input$maxlength={7}
        input$pattern={"\\d+"}
        title={$_("search_supplier")}
        class="white w-100 small flex-0"
        input$class="mnw-200 pl-1"
        label={$_("rms_supplier_no")}
    >
        <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
            <path fill="currentColor" d={mdiMagnify} />
        </svg>
    </Textfield>

    <SelectStatus bind:value={ceSupplierStatus}/>

    <Button
        title={$_("clear")}
        class="tescoblue white-text"
        on:click={() => {
            ceSuppNoSearch = "";
            ceSuppNameSearch = "";
            ceSupplierStatus = "";
            rmsSupplierNo = "";
            dispatch("clearSupplierList");
        }}
    >
        <svg viewBox="0 0 24 24" width="20" height="20">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </svg>
    </Button>

    <Button
        class="tescoblue white-text pr-4"
        title={$_("search")}
        on:click={() => {
            dispatch("loadSupplierList", {
                id: ceSuppNoSearch,
                name: ceSuppNameSearch,
                status: ceSupplierStatus ? ceSupplierStatus.id : "", 
                rmsSupplierNo: rmsSupplierNo,
            });
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={mdiDownloadCircleOutline} />
        </Icon>
        {$_("search")}
    </Button>
</Flex>

<style>
</style>
