<script>
    import { selectedModule } from "../../stores/AppStatus";

    import ProductManagement from "./Management/Products/ProductManagement.svelte";
    import SupplierManagement from "./Management/Suppliers/SupplierManagement.svelte";
    import DelegateProductManagement from "./Management/DelegateProduct/DPManagementScreen.svelte";
    import DelegateUserManagement from "./Management/DelegateUser/DUManagementScreen.svelte";
    import DCLManagement from "./Management/DCListManagement/DCLManagement.svelte";
    import ExchRateMain from "./Management/ExChangeRate/ExchRateMain.svelte"
    import LOVManagement from "./Management/LOVManagement/LOVManagement.svelte";

    import TescoAppLogo from '../../components/elementary/TescoAppLogo.svelte';

    import { _ } from 'svelte-i18n'

</script>

<section class="main-data-view {$selectedModule ? 'management' : 'list'}">
    {#if $selectedModule == 'mng_products'}
        <ProductManagement/>
    {:else if $selectedModule == 'mng_suppliers'}
        <SupplierManagement/>
    {:else if $selectedModule == 'mng_delegateProduct'}
        <DelegateProductManagement/>
    {:else if $selectedModule == 'mng_delegateUser'}
        <DelegateUserManagement/>
    {:else if $selectedModule == 'mng_dcl'}
        <DCLManagement/>
    {:else if $selectedModule == 'mng_exchRate'}
        <ExchRateMain/>
    {:else if $selectedModule == 'mng_lov'}
        <LOVManagement/>
    {:else}
        <TescoAppLogo position="center" size="big"/>
    {/if}

</section>


<style>

</style>
