<script lang="ts">

    import Menu from '@smui/menu';
    import List, { Item, Separator, Text } from '@smui/list';
    import Button, { Group, GroupItem, Label} from '@smui/button';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown } from '@mdi/js';

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';


    export let country;
    export let countries;

    let countriesMenu: Menu;
    let countriesMenuBtnWidth;

    $: {
    console.log("---------- SELECTOR COUNTRY -----------");
    console.log({country});
    console.log({countries});
  }
</script>

{#if countries}
    <Group
        id="countries-menu-grp"
        class="text-box white-text valign-center ma-0 pa-0 h-100"
    >
        <div use:GroupItem>
            <Button
                title="{$_('country')}"
                on:click="{() => {
                    countriesMenuBtnWidth = document.getElementById('countries-menu-grp').clientWidth;
                    countriesMenu.setOpen(true)
                }}"
                variant="raised"
                class="tescoblue-text white-tescoblue-outlined h-100 pt-0 pb-0 pl-4 pr-2"
                style="min-width: 50px; text-align: left"
            >
                <Label class="font-weight-medium w-100">
                    {#if country}
                        {country.id.toUpperCase()}
                    {:else}
                        {$_('select_country')}
                    {/if}
                </Label>
                <Icon class="pr-0 mr-0 pl-0 ml-1" tag='svg' viewBox="2 2 20 20">
                    <path fill="currentColor" d={mdiMenuDown} />
                </Icon>
            </Button>
            <Menu
                bind:this={countriesMenu}
                anchorCorner="BOTTOM_RIGHT"
                class="actions-menu"
                style="min-width: {countriesMenuBtnWidth}px"
            >
                <List>
                    {#each countries as w}
                        <Item
                            class="fs-14 small"
                            on:SMUI:action="{() => {
                                console.log({country});
                                console.log({w});
                                country = w;
                            }}"
                        >
                            <Text>{w.id.toUpperCase() + ' - ' + $_(w.name)}</Text>
                        </Item>
                    {/each}
                </List>
            </Menu>
        </div>
    </Group>
{/if}

<style>
</style>
