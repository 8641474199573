<script>

    import Button, { Label} from '@smui/button';

    import { Icon } from '@smui/icon-button';

    import Flex from 'svelte-flex';

    import { _ } from 'svelte-i18n';


</script>

    <Button
        class="tescoblue-text white-tescoblue-outlined pr-4 nowrap"
        title="{$_('add_row')}"
        on:click
    >
        <Icon class="material-icons">add_circle</Icon>
        {$_('add_value')}
    </Button>
