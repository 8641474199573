import Api from "../services/Api";

// Method to get current user data
export const getUser = async () => {
    try {
      const response = await Api.get("/user");
      console.log({response});
      if (response.payload) {
        return response.payload;
      } else {
        console.error(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.log("==== API ERROR ==== ")
      console.error({error});
      if (error?.response?.status == 401) {
          throw new Error('Unauthorized', {cause: error});
      }
      throw new Error(error.message, {cause: error});
    }
};

// Method to refresh user Toolkit token
export const userTokenRefresh = async () => {
  try {
    const response = await Api.post("/refreshToken");
    console.log({ response });
    if (response.access_token) {
      return response;
    } else {
      console.log(response.payload ? response.payload : response?.message);
      throw new Error(response.payload ? response.payload : response?.message);
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status == 401) {
        throw new Error('Unauthorized', {cause: error});
    }
    throw new Error(error.message, { cause: error });
  }
};

export const logout = async () => {
  try {
    const response = await Api.post("/logout");
    console.log({ response });
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status == 401) {
        throw new Error('Unauthorized', {cause: error});
    }
    throw new Error(error.message, { cause: error });
  }
};

// All users
export const getUserManagement = async (email, role, firstName, lastName, linked, uuid) => {
  try {
    const params = new URLSearchParams();
    if (email) params.append('email', email);
    if (role) params.append('role', role);
    if (firstName) params.append('firstName', firstName);
    if (lastName) params.append('lastName', lastName);
    if (linked) params.append('linked', linked);
    if (uuid) params.append('uuid', uuid);

    console.log('getUserManagement params:', params.toString());

    const response = await Api.get("/users", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status == 401) {
        throw new Error('Unauthorized', {cause: error});
    }
    throw new Error(error.message, { cause: error });
  }
};

// Email adress from uuid
export const getUserEmailByUUID = async (uuid) => {
  try {
    const response = await Api.get(`/api/email?uuid=${uuid}`);
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 401) {
      throw new Error('Unauthorized', { cause: error });
    }
    throw new Error(error.message, { cause: error });
  }
};