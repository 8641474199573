import {getQuoteSheets as apiGetQuoteSheet, checkQuoteSheetItems as apiCheckQuoteSheetItems} from "../../../api/QuoteSheet";


export const loadQuoteSheet = async (id, year, week, country, supplierId, productId, productDesc, productGroup, countryOfOrigin, variety, units) => {
    var response = await apiGetQuoteSheet(id, year, week, country, supplierId, productId, productDesc, productGroup, countryOfOrigin, variety, units)
    .then((res) => {
        console.log({res});
        var quouteSheetList = [];
        for (let quoteSheet of res) {
            // console.log({product});
            quouteSheetList.push({...quoteSheet});
        }

        if (quouteSheetList.length == 0) {
            console.log('loadQuoteSheet().empty');
        } else {
            return quouteSheetList;
        }
    })
    .catch((error) => {
        console.log(error);
        if (error?.cause?.response?.status == 401) { throw(error) }
    })
    return response;
};

export const checkQuoteSheetItems = async (data) => {
    console.log("checkQuoteSheetItems()", {data});
    var response = await apiCheckQuoteSheetItems(data)
    .then((res) => {
        console.log({res});
        var quouteSheetList = [];
        for (let quoteSheet of res) {
            // console.log({product});
            quouteSheetList.push({...quoteSheet});
        }

        if (quouteSheetList.length == 0) {
            console.log('loadQuoteSheet().empty');
        } else {
            return quouteSheetList;
        }
    })
    .catch((error) => {
        console.log(error);
        if (error?.cause?.response?.status == 401) { throw(error) }
    })
    return response;
};
