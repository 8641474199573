import { _ } from 'svelte-i18n';
import { forecastItemsExistsData, getForecast } from '../../../api/Forecast';
import { getProduct, getProductDetail } from '../../../api/Product';

function isNumber(value) {
	return typeof value === 'number' && !isNaN(value);
}

function isInteger(value) {
	return Number.isInteger(value);
}

export function validateDataType(fieldValue, fieldName) {
	if (!isNumber(fieldValue)) {
		return { priority: getPriorityForField(fieldName), message: `${fieldName}_must_be_number`, field: fieldName, type: 'E' };
	}
	if (!isInteger(fieldValue)) {
		return { priority: getPriorityForField(fieldName), message: `${fieldName}_must_be_integer`, field: fieldName, type: 'E' };
	}
	return null;
}

function getPriorityForField(fieldName) {
	const priorities = {
		week: 1,
		productId: 3,
		warehouseId: 2,
		tpnb: 3,
		units: 5,
		flexVol: 4,
		d1Vol: 4,
		d2Vol: 4,
		d3Vol: 4,
		d4Vol: 4,
		d5Vol: 4,
		d6Vol: 4,
		d7Vol: 4,
		totalVol: 4,
		note: 4,
	};
	return priorities[fieldName] || 4;
}

export function validateNoteValue(note, forecastNotes) {
	if (note != "") {
		const noteToFind = note ? note.toLowerCase() : '';
		const selectedNote = forecastNotes.find(forecastNote => forecastNote.name === noteToFind);

		if (!selectedNote) {
			return { priority: 4, message: 'invalide_note_value', field: 'note', type: 'E' };
		}
		return null;
	} else {
		return null;
	}
}

export function validateVolume(volume, field) {
	// Datatype check
	const typeError = validateDataType(volume, field);
	if (typeError) return typeError;

	if (volume === null || volume === undefined) {
		return { priority: 4, message: `${field}_volume_missing`, field, type: 'E' };
	}

	if (volume < 0 || volume > 10000000) {
		return {
			priority: 4, message: `${field}_volume_out_of_range`, field, type: 'E'
		};
	}
	return null;
}

export function validateFixFlex(row, field) {
	// Check Forecast's Fix versus Flex type
	let vol;
	if (field !== 'flexVol') {
		vol = row[field];
	}
	if ((!(row?.d1Vol === null || row?.d1Vol === undefined || row?.d1Vol == 0) ||
	     !(row?.d2Vol === null || row?.d2Vol === undefined || row?.d2Vol == 0) ||
		 !(row?.d3Vol === null || row?.d3Vol === undefined || row?.d3Vol == 0) ||
		 !(row?.d4Vol === null || row?.d4Vol === undefined || row?.d4Vol == 0) ||
		 !(row?.d5Vol === null || row?.d5Vol === undefined || row?.d5Vol == 0) ||
		 !(row?.d6Vol === null || row?.d6Vol === undefined || row?.d6Vol == 0) ||
		 !(row?.d7Vol === null || row?.d7Vol === undefined || row?.d7Vol == 0)) &&
		!(row?.flexVol === null || row?.flexVol === undefined || row?.flexVol == 0)
	   ) {
		if (field == 'flexVol' || !(vol === null || vol === undefined || vol == 0))
		return { priority: 4, message: `fix_and_flex_volume_present`, field, type: 'E' };
	}

	return null;
}

export function validateUnits(units, productUnits) {
	const errorField = 'units';
	if (units === null || units === undefined) {
		return { priority: 5, message: 'missing_units', field: errorField, type: 'E' };
	}

	if (units.toLowerCase() !== productUnits.toLowerCase()) {
		return {
			priority: 5, message: 'units_mismatch_or_missing', field: errorField, type: 'E'
		};
	}
	return null;
}

export function validateTotalVolume(totalVol, calculatedTotal, field = 'totalVol') {
	// Datatype and range validation
	const validationError = validateVolume(totalVol, field);
	if (validationError) return validationError;

	if (totalVol !== calculatedTotal) {
		return {
			priority: 4, message: 'total_vol_not_sum_of_flex_and_fix', field, type: 'E'
		};
	}
	else if (calculatedTotal === 0 || calculatedTotal < 0 ) {
			return { priority: 5, message: 'total_vol_zero', field, type: 'W' };
	}
	return null;
}

export function validateTPNBExistence(productDetail) {
	if (!productDetail) {
		return { priority: 3, message: 'missing_country_tpnb', field: 'tpnb', type: 'E' };
	}
	return null;
}

export function validateExistence(productId, field, productMap) {
	const product = productMap.get(productId);
	if (!product) {
		return { priority: 3, message: 'product_not_found', field, type: 'E' };
	}
	return null;
}

export const duplicityCheckUploadedFCData = async (data) => {
	console.log('================= duplicityCheckUploadedFCData ==============');

	let idsList = [...data]
		.filter(item => item.tpnb && item.warehouseId)
		.map(item => [item.tpnb, item.warehouseId].join('|'));

	let duplicities = idsList.filter((item, index) => idsList.indexOf(item) !== index);

	data.forEach(x => {
		// Ensure x.errors is an array
		if (!Array.isArray(x.errors)) {
			x.errors = [];
		}

		const duplicityIndex = duplicities.indexOf([x.tpnb, x.warehouseId].join('|'));
		const errorMessageIndex = x.errors.findIndex(error => error.message === 'duplicate_tpnb_in_file');

		if (x.tpnb && x.warehouseId && duplicityIndex !== -1) {
			// Duplicity found but no error message exists => add the error message
			if (errorMessageIndex === -1) {
				x.errors.push({ priority: 3, message: 'duplicate_tpnb_in_file', field: 'tpnb', type: 'E' });
			}
		} else {
			// Duplicity not found and error message exists => remove the error message
			if (errorMessageIndex !== -1) {
				x.errors.splice(errorMessageIndex, 1);
			}
		}
	});
};


export const validateFCExists = async (r, fcItemsExists) => {
	console.log('================= validateFCExists ==============');
	//console.log({ r });
	//console.log({ fcItemsExists });
	//console.log(Array.isArray(fcItemsExists));

	try {
		let err = null;
		if (!fcItemsExists || !Array.isArray(fcItemsExists)) {
			var res = await getForecast(null, r.year, r.week, null, r.warehouseId, null, r.productDetailId, null, r.tpnb, null, null, null, null, null, null, null, null, null);

			if (res && res.length > 0) {
				// Forecast exists already
				err = { message: 'forecast_exists_already', field: 'tpnb', type: 'W', fcId: res[0].id };
			}
		} else {
			let fc = fcItemsExists.filter((x) =>
				x.year == r.year &&
				x.week == r.week &&
				x.tpnb == r.tpnb &&
				x.productId == r.productId &&
				x.warehouseId == r.warehouseId);
			if (fc && fc.length > 0 && fc[0].id) {
				// Forecast exists already
				err = { message: 'forecast_exists_already', field: 'tpnb', type: 'W', fcId: fc[0].id };
			}
		}

		return err;
	} catch (error) {
		console.error('Error in validateFCExists:', error);
/* 		apiError.set(true);
		apiErrorDetails.set({
			loc: "validateFCExists()",
			err: error.message || error.toString()
		}); */
	}
}


export const validateUploadedFCData = async (data, selectedWeek, forecastNotes) => {
	const extractCountry = data[0].country;

	try {
		const [productResponse, detailResponse] = await Promise.all([
			getProduct(null, null, null, null, null, null, extractCountry, null, 1, null),
			getProductDetail(null, null, extractCountry, null, null, null, null, null, null, null, 1, null, null)
		]);

		const productMap = new Map(productResponse.map(p => [p.id, p]));
		const productDetailMap = new Map(detailResponse.map(pd => [`${pd.country}-${pd.tpnb}`, pd]));

		data.forEach(x => x.year = selectedWeek.tesco_year);

		for (let r of data) {
			r.errors = [];
			// Week validation
			if (r.week != selectedWeek.tesco_week) {
				r.errors.push({ priority: 1, message: 'wrong_week_number', field: 'week', type: 'E' });
			}

			// Validate tpnb
			const dataTypeError = validateDataType(r.tpnb, 'tpnb');
			if (dataTypeError) r.errors.push(dataTypeError);
			else {
				const detailKey = `${r.country}-${r.tpnb}`;
				const productDetail = productDetailMap.get(detailKey);
				const tpnbError = validateTPNBExistence(productDetail);
				if (tpnbError) r.errors.push(tpnbError);
				else {
					r.description = productDetail.description;
					r.productDetailId = productDetail.id;
					r.buyer = productDetail.buyer;

					// ProductId
					r.productId = productDetail.productId;
					const productError = validateExistence(r.productId, 'productId', productMap);
					if (productError) r.errors.push(productError);
					else {
						const product = productMap.get(r.productId);
						r.descriptionEn = product.description;
						if (r.units === "" || r.units === null || r.units === undefined) {
							r.units = product.units;
						} else {
							const unitsError = validateUnits(r.units, product.units);
							if (unitsError) r.errors.push(unitsError);
						}
					}

					// Note validation
					if (r.note) {
						const noteError = validateNoteValue(r.note, forecastNotes);
						if (noteError) r.errors.push(noteError);
					}

					// Validate all volume fields and calculate total
					const volumeFields = ['flexVol', 'd1Vol', 'd2Vol', 'd3Vol', 'd4Vol', 'd5Vol', 'd6Vol', 'd7Vol'];
					let totalCalculated = 0;

					volumeFields.forEach(field => {

						// Validate if only one of type Fix or Flex values being entered
						let fixFlexError = null;
						if (field == 'flexVol') {
							fixFlexError = validateFixFlex(r, field);
						}
						if (fixFlexError) {
							r.errors.push(fixFlexError);
						} else {
							const volumeError = validateVolume(r[field], field);
							if (volumeError) {
								r.errors.push(volumeError);
							} else {
								// Only add to total if no error
								totalCalculated += r[field] || 0;
							}
						}
					});

					// Validate total volume against calculated total
					const totalVolumeError = validateTotalVolume(r.totalVol, totalCalculated);
					if (totalVolumeError) r.errors.push(totalVolumeError);
				}
			}

		}


		// Check if forecast exists
		// Prepare data for forecast check / Skipp null products
		const forecastCheckData = data.filter(x => x.tpnb && x.productId && x.productDetailId && x.warehouseId)
			.map(x => ({ id: null, year: selectedWeek.tesco_year, week: x.week, tpnb: x.tpnb, productId: x.productId, productDetailId: x.productDetailId, warehouseId: x.warehouseId, country: extractCountry, status: x.status }));

		// Check forecasts in bulk
		const forecastItemsExists = await forecastItemsExistsData(forecastCheckData);
		const forecastMap = new Map(forecastItemsExists.map(item => [`${item.year}-${item.week}-${item.tpnb}-${item.productId}-${item.productDetailId}-${item.warehouseId}`, item]));

		console.log("forecastItemsExists", forecastItemsExists);
		console.log("forecastCheckData", forecastCheckData);

		console.log("forecastMap", forecastMap);

		for (let r of data) {
			if (r.tpnb && r.productId && r.productDetailId && r.warehouseId) {
			const forecastKey = `${r.year}-${r.week}-${r.tpnb}-${r.productId}-${r.productDetailId}-${r.warehouseId}`;
			const forecastExists = forecastMap.get(forecastKey);

			if (forecastExists.id) {
				r.warnings.push({ message: 'forecast_exists_already', field: 'tpnb', type: 'W', fcId: forecastExists.id });
				r.id = forecastExists.id;
				r.status = forecastExists.status;

				// If Forecast exists and has already different status, then change it
				if (forecastExists.status !== 'new') {
					r.status = "updated";
				}
			}}
		}

	} catch (err) {
		console.error("Error fetching Product/Local item data in validateUploadedFCData:", err);
		if (err?.cause?.response?.status == 401) { throw(err) }
		throw new Error('Failed to validate uploaded forecast: ' + err.message);
/* 		apiError.set(true);
		apiErrorDetails.set({
			loc: "validateUploadedFCData()",
			err: err.message || err.toString()
		}); */
	}

	return data;
};

