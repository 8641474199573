<script lang="ts">
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Dialog, { Title as DTitle, Content as Content, Actions, InitialFocus } from "@smui/dialog";
    import { mdiWrench } from "@mdi/js";
    import CircularProgress from "@smui/circular-progress";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Button, { Label } from "@smui/button";
    import IconButton, { Icon } from "@smui/icon-button";
    import Autocomplete from "../../extra/Autocomplete.svelte";
    import Textfield from "@smui/textfield";

    import { getProduct, getProductDetail } from "../../../api/Product";

    import { nvl, getFieldErrMsg, existsError } from "../Upload/Upload.js";

    import { validateUnits, validateVolume, validateFixFlex, validateTotalVolume, validateFCExists } from "../Upload/ValidateForecast";

    import DTInputCell from "../common/DTInputCell.svelte";
    import DTSelectCell from "../common/DTSelectCell.svelte";
    import DTSelectCellName from "../common/DTSelectCellName.svelte";
    import {
        snackbarError,
        authenticatedUser,
    } from "../../../stores/AppStatus";

    import { forecastNotes, forecastStatuses, unitList } from "../../../stores/AppConfig";
    import type { ForecastItems } from "../../../types/Forecast";

    export let dcList;
    export let item: ForecastItems;
    export let title;
    export let type = "add";
    export let open;
    export let screenMode = "edit";

    const dispatch = createEventDispatcher();

    export let warehouseNameForDisplay = "";

    let buttonSaveEnabled = true;

    // Autocomplete
    let prodEl;
    let menuBottom;
    let menuWidth;
    let productsFetching = false;

    let unitListLoc = [];
    let localItems = [];
    let selectedLocalItem = [];
    let selectedProduct = null;

    // Upload mode - store fields with error
    let keepUnitsEnabled = screenMode == "upload" && existsError(item, "units", "E") && item.productId;

    $: buttonSaveEnabled = !productsFetching && item && item.errors && Array.isArray(item.errors) && item.errors.length == 0 && item.productId != null;
    $: productNotSelected = !item || (item && (!item?.tpnb || item?.tpnb == undefined));
    $: fixDisabled = (item?.flexVol > 0);
    $: flexDisabled = ((item?.d1Vol > 0) || (item?.d2Vol > 0) || (item?.d3Vol > 0) || (item?.d4Vol > 0) || (item?.d5Vol > 0) || (item?.d6Vol > 0) || (item?.d7Vol > 0));

    onMount(async () => {
        unitListLoc = $unitList
            .filter((e) => e.active)
            .map((x) => {
                return { id: x.code, name: x.description };
            });
    });

    function saveItemDetail() {
        console.log("====================== saveItemDetail =========================");
        if (item && item.errors && item.errors != null && item.errors.length > 0 && item.note != null) {
            $snackbarError.text = $_("cannot_save_fc_errors_found", { values: { reason: "cannot_save_fc_errors_found" } });
            $snackbarError.element && $snackbarError.element.open();
        } else {
            dispatch("ForecastDialogSave", { item: item, type: type });
        }
    }

    function cancelItemDetail() {
        console.log("====================== cancelItemDetail =========================");
        dispatch("reject");
    }

    function handleNoteSelectionChange(event) {
        // Validation is not needed as select is taking values from store
        // Remove all errors where field is "note"
        item.errors = item.errors.filter((error) => error.field !== "note");
    }

    // Function to handle warehouse selection changes
    function handleWarehouseSelectionChange(event) {
        const selectedWarehouseId = event.detail.value;
        if (selectedWarehouseId) {
            item.warehouseId = selectedWarehouseId;

            // Find the corresponding country information based on the selected warehouse ID
            const selectedWarehouse = dcList.find((dc) => dc.id === selectedWarehouseId);
            if (selectedWarehouse) {
                item.country = selectedWarehouse.country;
            } else {
                console.error("Selected warehouse not found in dcList");
            }
        }
    }

    // Function to handle unit changes
    async function handleUnitChange(event) {
        console.log(event.detail.value);
        try {
            const ceTpn = await getProduct(item.productId, item.tpnb, null, null, null, null, item.country, null, 1, null);

            if (ceTpn) {
                const validationError = validateUnits(event.detail.value, ceTpn[0].units);

                // Remove all errors where field is "units"
                item.errors = item.errors.filter((error) => error.field !== "units");

                if (validationError) {
                    item.errors.push(validationError);
                    item = item;
                }
            } else {
                console.log("ceTpn Not found for unit change validation");
            }
        } catch(e) {
            if (e?.cause?.response?.status == 401) { throw(e) }
        }
    }

    // Function to handle volume changes
    function handleVolumeChange(value, field) {
        console.log("====================== handleVolumeChange =========================");
        // When disabling daily values, change values to "0" and delete errors for missing values, or let unchanged for not empty values
        if (fixDisabled && item) {
            // For each day of week
            for (let i = 1; i<=7; i++){
                if (item["d"+i+"Vol"] === null || item["d"+i+"Vol"] === undefined || item["d"+i+"Vol"] =='') {
                    if (field != "d"+i+"Vol") {
                        item["d"+i+"Vol"] = 0;
                    }
                    item.errors = item.errors.filter((error) => error.field !== 'd'+i+'Vol');
                } else {
                    const validationError = validateVolume(Number(item["d"+i+"Vol"]), "d"+i+"Vol");
                    if (validationError) {
                        item.errors.push(validationError);
                    }
                }
            }
        }

        // Remove all errors for field flexVol
        item.errors = item.errors.filter((error) => error.field !== 'flexVol');
        // When disabling flex value, change value to "0" and delete errors if missing value, or let unchanged for not empty value
        if (flexDisabled && item) {
            if (item.flexVol === null || item.flexVol === undefined) {
                if (field != "flexVol") {
                    item.flexVol = 0;
                }
            } else {
                // Remove all errors for field flexVol
                item.errors = item.errors.filter((error) => error.field !== 'flexVol');
                const validationError = validateVolume(Number(item.flexVol), 'flexVol');
                if (validationError) {
                    item.errors.push(validationError);
                }
            }
        }

        // Validate given field
        let fixFlexError = validateFixFlex(item, "flexVol");
        if (fixFlexError) {
            item.errors.push(fixFlexError);
        } else {
            const validationError = validateVolume(Number(value), field);
            // Remove all errors for the given field
            item.errors = item.errors.filter((error) => error.field !== field);
            if (validationError) {
                item.errors.push(validationError);
            }
        }

        // Calculate Total Volume
        calculateTotalVolume();
        calculateAndValidateTotalVolume();
        console.log(item.errors);

    }

    // Function to handle volume changes
    function fixTotalVolume() {
        item.totalVol =
            (+item.flexVol || 0) + (+item.d1Vol || 0) + (+item.d2Vol || 0) + (+item.d3Vol || 0) + (+item.d4Vol || 0) + (+item.d5Vol || 0) + (+item.d6Vol || 0) + (+item.d7Vol || 0);
    }

    function calculateAndValidateTotalVolume() {
        const totalCalculated =
            (+item.flexVol || 0) + (+item.d1Vol || 0) + (+item.d2Vol || 0) + (+item.d3Vol || 0) + (+item.d4Vol || 0) + (+item.d5Vol || 0) + (+item.d6Vol || 0) + (+item.d7Vol || 0);

        const validationError = validateTotalVolume(Number(item.totalVol), totalCalculated);
        // Remove previous total volume errors
        item.errors = item.errors.filter((error) => error.field !== "totalVol");
        // Add new error if found
        if (validationError) {
            item.errors.push(validationError);
        }
    }

    function calculateTotalVolume() {
        item.totalVol = (+item.flexVol || 0) + (+item.d1Vol || 0) + (+item.d2Vol || 0) + (+item.d3Vol || 0) + (+item.d4Vol || 0) + (+item.d5Vol || 0) + (+item.d6Vol || 0) + (+item.d7Vol || 0);
        item.errors = item.errors.filter((error) => error.field !== "totalVol");
    }

    async function loadLocalProducts(productId, tpnb, country, status) {
        console.log("====================== loadSupplierProducts =========================");
        productsFetching = true;
        localItems = await getProductDetail(null, productId, country, tpnb, null, null, null, null, null, null, null, status, null);
        productsFetching = false;
        console.log({ localItems });
    }

    async function searchLocalProduct(input: string) {
        console.log("====================== searchLocalProduct =========================");
        console.log("input: ", input);
        console.log({ localItems });
        console.log("localItems.length: ", localItems.length);

        if (input.length < 3) {
            return [];
        }
        try {
            if (localItems.length == 0) {
                console.log("Loading products...");
                await loadLocalProducts(item?.productId, item?.tpnb, item?.country, 1);
            }

            console.log("Search product in list...");

            let results = [...localItems].filter(
                (x) => x.tpnb.toString().toUpperCase().includes(input.toUpperCase()) || (x.description && x.description.toUpperCase().includes(input.toUpperCase())),
            );
            selectedLocalItem = results;

            return results.map((item) => `${item.tpnb} - ${item.description}`);
        } catch (error) {
            if (error?.cause?.response?.status == 401) { throw(error) }
            console.error("Error fetching SupplierProduct autocomplete data:", error);
            return [];
        }
    }

    function productBlur(object) {
        console.log("====================== productBlur =========================");
        console.log({ selectedProduct });
        if (typeof selectedProduct === "undefined" || !selectedProduct || selectedProduct == null || selectedProduct == "") {
            productClear();
        }
    }

    function productClear() {
        console.log("====================== productClear =========================");
        item.productId = null;
        item.descriptionEn = null;
        item.tpnb = null;
        item.description = null;
        item.buyer = null;
        item.technicalManager = null;
        selectedProduct = null;
        // Cleanup previous error
        if (item && item.errors && item.errors.map((x) => x.field).indexOf("productId") > -1) {
            item.errors.splice(item.errors.map((x) => x.field).indexOf("productId"), 1);
        }
    }

async function productSelected(option) {
    console.log("====================== productSelected =========================");
    console.log({ selectedLocalItem });
    console.log(option.detail);

    // Extract the tpnb from option.detail
    const tpnb = option.detail.split(" - ")[0];
    const matchingItem = selectedLocalItem.find(item => item.tpnb === tpnb);

    if (matchingItem) {
        // Assign matching item properties to the item
        item.productId = matchingItem.productId;
        item.tpnb = matchingItem.tpnb;
        item.productDetailId = matchingItem.id;
        item.description = matchingItem.description;
        item.descriptionEn = matchingItem.descriptionEn;
        item.buyer = matchingItem.buyer;
        item.technicalManager = matchingItem.technicalManager;
        item.errors = [];
        console.log("matchingItem", item);

        // Prepare promises for validateFCExists and getProduct
        console.log("Before promise",item);
        const validatePromise = validateFCExists(item);
        const getProductPromise = getProduct(item.productId, item.tpnb, null, null, null, null, item.country, null, 1, null);
        const [forecastExists, ceTpn] = await Promise.all([validatePromise, getProductPromise]);

        // Handle forecastExists
        console.log("forecastExists", forecastExists);
        const errorMessageIndex = item.errors.findIndex(error => error.message === "duplicate_tpnb_in_file");
        if (forecastExists) {
            // Add the error message if it doesn't already exist
            if (errorMessageIndex === -1) {
                item.errors.push(forecastExists);
            }
        } else {
            // Remove the error message if it exists
            if (errorMessageIndex !== -1) {
                item.errors.splice(errorMessageIndex, 1);
            }
        }

        // Handle ceTpn response
        console.log(ceTpn ? ceTpn[0] : 'No ceTpn data');
        if (ceTpn && ceTpn.length > 0) {
            const newCeProduct = ceTpn[0];
            item.units = newCeProduct.units;
            item.status = "new";
        }
    } else {
        console.log("No matching item found.");
    }
}

</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style="width: 850px; max-width: calc(100vw - 32px);"
>
    <DTitle id="data-changed-title" class="tescoblue white-text">{title}</DTitle>
    <Content>
        {#if item}
            <DataTable class="w-100 input-form no-border pa-2">
                <Body>
                    <Row>
                        <Cell class="form-label">{$_("dc")}</Cell>
                        {#if type == "edit"}
                            <DTInputCell colspan="2" value={warehouseNameForDisplay} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        {:else if Array.isArray(dcList) && dcList.length > 0}
                            <DTSelectCell
                                bind:value={item.warehouseId}
                                list={dcList}
                                format={{ showId: false, translateName: true }}
                                required
                                on:SMUISelect:change={handleWarehouseSelectionChange}
                            />
                        {/if}
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("tpn")}</Cell>
                        {#if type == "edit"}
                            <DTInputCell colspan="2" value={`${item.tpnb} - ${item.description}`} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        {:else}
                            <!--<DTInputCell colspan="2" bind:value={item.tpnb} editable={true} numeric={false} class="w-100" /> -->
                            {#if item.country}
                                <td colspan="4">
                                    <Autocomplete
                                        class="w-100 tescoblue-text edit-cell-border"
                                        search={(input) => searchLocalProduct(input)}
                                        title={$_("enter_min_chars", { values: { chars: 3 } })}
                                        showMenuWithNoInput={false}
                                        minInputSizeToShowMenu="3"
                                        selectOnExactMatch={true}
                                        bind:value={selectedProduct}
                                        bind:this={prodEl}
                                        menu$class="mdc-menu-surface--fixed with-parameter__menu"
                                        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
                                        on:focusin={() => {
                                            menuWidth = prodEl?.getElement()?.clientWidth;
                                            menuBottom = prodEl?.getElement()?.getBoundingClientRect()?.bottom;
                                        }}
                                        on:click={() => {
                                            menuWidth = prodEl?.getElement()?.clientWidth;
                                            menuBottom = prodEl?.getElement()?.getBoundingClientRect()?.bottom;
                                        }}
                                        on:focus={(event) => {
                                            event.detail.target.select();
                                        }}
                                        on:blur={(event) => {
                                            productBlur(event);
                                        }}
                                        on:SMUIAutocomplete:selected={(option) => {
                                            productSelected(option);
                                        }}
                                    >
                                        <Textfield
                                            type="text"
                                            bind:value={selectedProduct}
                                            withTrailingIcon={existsError(item, "productId", "E") || productsFetching}
                                            disabled={productsFetching}
                                            invalid={existsError(item, "productId", "E")}
                                            input$maxlength={30}
                                            input$autocomplete="productId"
                                            input$emptyValueUndefined
                                            input$placeholder={productsFetching ? $_("loading_products_wait") : $_("enter_min_chars", { values: { chars: 3 } })}
                                            required
                                            style="min-width: 500px;"
                                            class="w-100"
                                        >
                                            <svelte:fragment slot="trailingIcon">
                                                {#if existsError(item, "productId", "E")}
                                                    <Icon class="material-icons red-text">error</Icon>
                                                {:else if productsFetching}
                                                    <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
                                                {/if}
                                            </svelte:fragment>
                                        </Textfield>
                                    </Autocomplete>
                                </td>
                            {:else if !item.country}
                                <Cell colspan="4">{$_("dc_not_selected")}</Cell>
                            {:else}
                                <Cell colspan="4">{$_("rms_product_not_found")}</Cell>
                            {/if}
                        {/if}
                    </Row>
                    <!-- Error Forecast Exists-->
                    {#if existsError(item, "tpnb", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "tpnb", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <!--                     <Row>
                        <Cell class="form-label">{$_("tpnb_desc")}</Cell>
                        <DTInputCell colspan="2" value={item.description} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                    </Row> -->
                    <Row>
                        <Cell class="form-label">{$_("tpn_en")}</Cell>
                        <DTInputCell
                            colspan="2"
                            value={item.productId && item.descriptionEn ? `${item.productId} - ${item.descriptionEn}`: ""}
                            editable={false}
                            numeric={false}
                            class="fs-1rem tescoblue-text grey lighten-2"
                        />
                    </Row>
                    <!--                     <Row>
                        <Cell class="form-label">{$_("product_en")}</Cell>
                        <DTInputCell colspan="2" value={item.descriptionEn} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                    </Row> -->
                    <Row>
                        <Cell class="form-label">{$_("units")}</Cell>
                        {#if existsError(item, "units", "E") || keepUnitsEnabled}
                            <td colspan="4">
                                <DTSelectCell
                                    bind:value={item.units}
                                    list={unitListLoc}
                                    format={{ showId: false, translateName: true }}
                                    required
                                    errorMsg={existsError(item, "units", "E")}
                                    on:SMUISelect:change={handleUnitChange}
                                >
                                </DTSelectCell>
                            </td>
                        {:else}
                            <DTInputCell colspan="2" value={$_(nvl(item.units, ""))} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        {/if}
                    </Row>
                    <!-- Units error -->
                    {#if existsError(item, "units", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "units", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("status")}</Cell>
                        <DTInputCell colspan="2" value={$_(nvl(item.status, ""))} editable={false} numeric={false} class="w-100 fs-1rem tescoblue-text grey lighten-2" />
<!--                         <td colspan="4" class="w-100">
                            <DTSelectCellName
                                bind:value={item.status}
                                list={existsError(item, "note", "E") ? [{ id: "placeholder", name: item.status }, ...$forecastStatuses] : $forecastStatuses}
                                format={{ showId: false, translateName: true }}
                                required
                            />
                        </td> -->
                    </Row>
                    <Row>
                        <Cell class="form-label">{$_("note")}</Cell>
                        <DTSelectCellName
                            bind:value={item.note}
                            list={existsError(item, "note", "E") ? [{ id: "placeholder", name: item.note }, ...$forecastNotes] : $forecastNotes}
                            format={{ showId: false, translateName: true }}
                            required
                            errorMsg={existsError(item, "note", "E")}
                            on:SMUISelect:change={handleNoteSelectionChange}
                        />
                    </Row>
                    <!-- Note error -->
                    {#if existsError(item, "note", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "note", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("forecast_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.flexVol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (flexDisabled && !fixDisabled))  && !existsError(item, "flexVol", "E")}
                                bind:value={item.flexVol}
                                withTrailingIcon={existsError(item, "flexVol", "E")}
                                invalid={existsError(item, "flexVol", "E") || (!productNotSelected && (item?.flexVol == null || item?.flexVol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="flexVol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.flexVol, "flexVol");
                                }}
                                on:blur={() => {if (item.flexVol == null || item.flexVol == '') {item.flexVol = 0};handleVolumeChange(item.flexVol, "flexVol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "flexVol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "flexVol", "E") || (!productNotSelected && (item?.flexVol == null || item?.flexVol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.flexVol == null || item?.flexVol == undefined)
                                        ? $_("field_is_required_zero")
                                        : $_(getFieldErrMsg(item, "flexVol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d1_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d1Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d1Vol", "E")}
                                bind:value={item.d1Vol}
                                withTrailingIcon={existsError(item, "d1Vol", "E")}
                                invalid={existsError(item, "d1Vol", "E") || (!productNotSelected && (item?.d1Vol == null || item?.d1Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d1Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d1Vol, "d1Vol");
                                }}
                                on:blur={() => {if (item.d1Vol == null || item.d1Vol == '') {item.d1Vol = 0};handleVolumeChange(item.d1Vol, "d1Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d1Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d1Vol", "E") || (!productNotSelected && (item?.d1Vol == null || item?.d1Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d1Vol == null || item?.d1Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d1Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d2_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d2Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d2Vol", "E")}
                                bind:value={item.d2Vol}
                                withTrailingIcon={existsError(item, "d2Vol", "E")}
                                invalid={existsError(item, "d2Vol", "E") || (!productNotSelected && (item?.d2Vol == null || item?.d2Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d2Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d2Vol, "d2Vol");
                                }}
                                on:blur={() => {if (item.d2Vol == null || item.d2Vol == '') {item.d2Vol = 0};handleVolumeChange(item.d2Vol, "d2Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d2Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d2Vol", "E") || (!productNotSelected && (item?.d2Vol == null || item?.d2Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d2Vol == null || item?.d2Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d2Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d3_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d3Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d3Vol", "E")}
                                bind:value={item.d3Vol}
                                withTrailingIcon={existsError(item, "d3Vol", "E")}
                                invalid={existsError(item, "d3Vol", "E") || (!productNotSelected && (item?.d2Vol == null || item?.d3Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d3Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d3Vol, "d3Vol");
                                }}
                                on:blur={() => {if (item.d3Vol == null || item.d3Vol == '') {item.d3Vol = 0};handleVolumeChange(item.d3Vol, "d3Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d3Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d3Vol", "E") || (!productNotSelected && (item?.d3Vol == null || item?.d3Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d3Vol == null || item?.d3Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d3Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d4_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d4Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d4Vol", "E")}
                                bind:value={item.d4Vol}
                                withTrailingIcon={existsError(item, "d4Vol", "E")}
                                invalid={existsError(item, "d4Vol", "E") || (!productNotSelected && (item?.d2Vol == null || item?.d4Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d4Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d4Vol, "d4Vol");
                                }}
                                on:blur={() => {if (item.d4Vol == null || item.d4Vol == '') {item.d4Vol = 0};handleVolumeChange(item.d4Vol, "d4Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d4Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d4Vol", "E") || (!productNotSelected && (item?.d4Vol == null || item?.d4Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d4Vol == null || item?.d4Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d4Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d5_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d5Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d5Vol", "E")}
                                bind:value={item.d5Vol}
                                withTrailingIcon={existsError(item, "d5Vol", "E")}
                                invalid={existsError(item, "d5Vol", "E") || (!productNotSelected && (item?.d2Vol == null || item?.d5Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d5Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d5Vol, "d5Vol");
                                }}
                                on:blur={() => {if (item.d5Vol == null || item.d5Vol == '') {item.d5Vol = 0};handleVolumeChange(item.d5Vol, "d5Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d5Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d5Vol", "E") || (!productNotSelected && (item?.d5Vol == null || item?.d5Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d5Vol == null || item?.d5Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d5Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d6_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d6Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d6Vol", "E")}
                                bind:value={item.d6Vol}
                                withTrailingIcon={existsError(item, "d6Vol", "E")}
                                invalid={existsError(item, "d6Vol", "E") || (!productNotSelected && (item?.d2Vol == null || item?.d6Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d6Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d6Vol, "d6Vol");
                                }}
                                on:blur={() => {if (item.d6Vol == null || item.d6Vol == '') {item.d6Vol = 0};handleVolumeChange(item.d6Vol, "d6Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d6Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d6Vol", "E") || (!productNotSelected && (item?.d6Vol == null || item?.d6Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d6Vol == null || item?.d6Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d6Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("d7_vol")}</Cell>
                        <!-- <DTInputCell colspan="2" bind:value={item.d7Vol} editable={true} numeric={false} class="w-100" /> -->
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={(productNotSelected || (fixDisabled && !flexDisabled))  && !existsError(item, "d7Vol", "E")}
                                bind:value={item.d7Vol}
                                withTrailingIcon={existsError(item, "d7Vol", "E")}
                                invalid={existsError(item, "d7Vol", "E") || (!productNotSelected && (item?.d7Vol == null || item?.d7Vol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="d7Vol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.d7Vol, "d7Vol");
                                }}
                                on:blur={() => {if (item.d7Vol == null || item.d7Vol == '') {item.d7Vol = 0};handleVolumeChange(item.d7Vol, "d7Vol");}}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "d7Vol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "d7Vol", "E") || (!productNotSelected && (item?.d7Vol == null || item?.d7Vol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.d7Vol == null || item?.d7Vol == undefined) ? $_("field_is_required_zero") : $_(getFieldErrMsg(item, "d7Vol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("total_vol")}</Cell>
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={!existsError(item, "totalVol", "E")}
                                bind:value={item.totalVol}
                                withTrailingIcon={existsError(item, "totalVol", "E")}
                                invalid={existsError(item, "totalVol", "E") || (!productNotSelected && (item?.totalVol == null || item?.totalVol == undefined))}
                                updateInvalide={false}
                                readonly={true}
                                input$maxlength={8}
                                input$pattern={"\\d+"}
                                input$autocomplete="totalVol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.totalVol, "totalVol");
                                }}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "totalVol", "E")}
                                        {#if getFieldErrMsg(item, "totalVol", "E") != 'total_vol_zero'}
                                            {#if !existsError(item, "d7Vol", "E") && !existsError(item, "d6Vol", "E") && !existsError(item, "d5Vol", "E") && !existsError(item, "d4Vol", "E") && !existsError(item, "d3Vol", "E") && !existsError(item, "d2Vol", "E") && !existsError(item, "d1Vol", "E") && !existsError(item, "flexVol", "E")}
                                                <IconButton
                                                    title={$_("recalculate")}
                                                    class="material-icons red-text"
                                                    on:click={() => {
                                                        fixTotalVolume();
                                                        handleVolumeChange(item.totalVol, "totalVol");
                                                    }}
                                                    size="mini"
                                                >
                                                    <Icon tag="svg" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d={mdiWrench} />
                                                    </Icon>
                                                </IconButton>
                                            {:else}
                                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                            {/if}
                                        {/if}
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "totalVol", "E") || (!productNotSelected && (item?.totalVol == null || item?.totalVol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.totalVol == null || item?.totalVol == undefined)
                                        ? $_("field_is_required")
                                        : $_(getFieldErrMsg(item, "totalVol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    {#if screenMode == "edit"}
                        <Row>
                            <Cell class="form-label">{$_("buyer_comment")}</Cell>
                            <DTInputCell colspan="2" bind:value={item.userComment} editable={true} maxlength={500} numeric={false} class="w-100" />
                        </Row>
                    {/if}
                </Body>
            </DataTable>
        {/if}
    </Content>
    <Actions class="pr-4 gap-1 space-around">
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click={() => {
                cancelItemDetail();
            }}
        >
            <Label>{$_("cancel")}</Label>
        </Button>
        <Button
            action="save"
            use={[InitialFocus]}
            class={buttonSaveEnabled ? "tescoblue white-text" : "grey white-text"}
            disabled={!buttonSaveEnabled}
            on:click={() => {
                saveItemDetail();
            }}
        >
            <Label>{$_("save")}</Label>
        </Button>
    </Actions>
</Dialog>

<style>
</style>
