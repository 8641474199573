<script lang="ts">

    import { unauthorized, authenticatedUser, userLoggedOut } from "../../stores/AppStatus";

    import { logout as toolkitLogout} from "../../api/User";

    import Button, { Group, GroupItem, Label} from '@smui/button';

    import Menu from '@smui/menu';
    import List, { Item, Separator, Text } from '@smui/list';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown, mdiLogout} from '@mdi/js';

    import { push } from "svelte-spa-router";
    import { _ } from 'svelte-i18n';

    let userMenu: Menu;
    let userMenuBtnWidth;

    const logout = (async () => {
        try {
            console.log("Logout");

            let res = await toolkitLogout();
            $userLoggedOut = true;
            $unauthorized = true;
            $authenticatedUser = null;
        } catch {
            console.log("Logout Error!");
        }

    })


</script>

<Group
    id="user-menu-grp"
    class="text-box white-text valign-center left-splitter pl-4 pr-4 mr-0 mt-0 mb-0 h-100"
>
    <div use:GroupItem>
        <Button
            on:click="{() => {
                userMenuBtnWidth = document.getElementById('user-menu-grp').clientWidth;
                userMenu.setOpen(true)
            }}"
            variant="raised"
            class="white-text header-btn square-btn h-100 pt-0 pb-0 pl-2 pr-0 gap-05"
        >
            <Label class="font-weight-medium">
                <span>{$authenticatedUser.name}</span>
                <br><span class="fs-07rem red-text lighten-4">{$authenticatedUser.role}</span>
            </Label>
            <Icon class="pr-0 mr-0 pl-0 ml-1" tag='svg' viewBox="2 2 20 20">
                <path fill="currentColor" d={mdiMenuDown} />
            </Icon>
        </Button>
        <Menu
            bind:this={userMenu}
            anchorCorner="BOTTOM_RIGHT"
            class="user-menu"
            style="min-width: {userMenuBtnWidth}px"
        >
            <List>
                <Item on:SMUI:action={logout} class="fs-14 small">
                    <Text>
                        <div class="valign-center">
                            <Icon tag='svg'
                                viewBox="0 0 24 24"
                                class="menu-icon pr-1">
                                <path fill="currentColor" d={mdiLogout} />
                            </Icon>
                            {$_('logout')}
                        </div>
                    </Text>
                </Item>
            </List>
        </Menu>
    </div>
</Group>
