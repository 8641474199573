<script lang="ts">
    import Button, { Label, Group } from "@smui/button";
    import Flex from "svelte-flex";
    import { Icon } from "@smui/icon-button";
    import { _ } from "svelte-i18n";
    import type { UserManagementContact } from "../../../../types/Management";
    import Textfield from "@smui/textfield";
    import { mdiMagnify, mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";
    import Checkbox from "@smui/checkbox";
    import Paper from "@smui/paper";
    import DataTable, { Head, Body, Row, Cell, Pagination } from "@smui/data-table";
    import CircularProgress from "@smui/circular-progress";
    import Select, { Option } from "@smui/select";
    import IconButton from "@smui/icon-button";
    import FormField from "@smui/form-field";
    import { getUserManagement } from "../../../../api/User";

    export let selectedContacts: UserManagementContact[] = [];
    export let suppRmsData;
    export let contacts: UserManagementContact[] = [];

    let tableLoadIcon = false;
    let tableSearchMessage = false;
    let firstName = "";
    let lastName = "";
    let email = "";
    let linked = true;
    let checked = true;
    const role = "Supplier";

    async function apiLoadRmsContacts(email, firstName, lastName, linked) {
        try {
            tableLoadIcon = true;
            contacts = await getUserManagement(email, role, firstName, lastName, linked);
            if (contacts.length == 0) {
                console.log("apiLoadRmsContacts().empty");
                tableSearchMessage = false;
            } else if (contacts.length == null) {
                console.log("apiLoadRmsContacts().null");
                tableSearchMessage = false;
            }
            tableLoadIcon = false;
        } catch (error) {
            console.error("Error fetching suppliers RMS contacts:", error);
            if (error?.cause?.response?.status == 401) {
                throw error;
            }
        }
    }

    function clearFields() {
        contacts.length = 0;
        selectedContacts.length = 0;
    }

    // Pagging
    let rowsPerPage = 10;
    let currentPage = 0;

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, contacts.length);
    $: filteredContacts = checked ? contacts.filter((contact) => !suppRmsData?.contact?.some((suppContact) => suppContact.contact === contact.email)) : contacts;
    $: slice = filteredContacts.slice(start, end);
    $: lastPage = Math.max(Math.ceil(filteredContacts.length / rowsPerPage) - 1, 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }
</script>

<Paper class="w-100 grey lighten-3 bs-bb pl-3">
    <Flex direction="column" class=" tescoblue-text pr-4 gap-1">
        <Flex direction="row" justify="between" align="stretch" class="gap-1" style="width: 100%;">
            <Textfield
                variant="outlined"
                bind:value={firstName}
                input$maxlength={20}
                input$autocomplete="nameEmail"
                title={$_("firstName")}
                class="white w-100 small flex-0"
                input$class="mnw-200 pl-1"
                label={$_("firstName")}
            >
                <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
                    <path fill="currentColor" d={mdiMagnify} />
                </svg>
            </Textfield>
            <Textfield
                variant="outlined"
                bind:value={lastName}
                input$maxlength={20}
                input$autocomplete="lastnameEmail"
                title={$_("lastName")}
                class="white w-100 small flex-0"
                input$class="mnw-200 pl-1"
                label={$_("lastName")}
            >
                <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
                    <path fill="currentColor" d={mdiMagnify} />
                </svg>
            </Textfield>
            <Textfield
                variant="outlined"
                bind:value={email}
                input$maxlength={50}
                input$autocomplete="contactEmail"
                title={$_("contact_email")}
                class="white w-100 small flex-0"
                input$class="mnw-200 pl-1"
                label={$_("contact_email")}
            >
                <svg slot="leadingIcon" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
                    <path fill="currentColor" d={mdiMagnify} />
                </svg>
            </Textfield>
        </Flex>

        <Flex direction="row" justify="between" align="stretch" class="gap-1" style="width: 90%;">
            <FormField>
                <Checkbox bind:checked={linked} touch on:click={() => clearFields()} />
                <span slot="label">{$_("not_linked_contacts")}</span>
            </FormField>
            <Flex direction="row" align="end" class="gap-1">
                <Group>
                    <Button
                        title={$_("clear")}
                        class="tescoblue white-text"
                        on:click={() => {
                            firstName = "";
                            lastName = "";
                            email = "";
                            contacts.length = 0;
                        }}
                    >
                        <svg viewBox="0 0 24 24" width="20" height="20">
                            <path fill="currentColor" d={mdiBackspaceOutline} />
                        </svg>
                    </Button>

                    <Button
                        class="tescoblue white-text pr-4"
                        title={$_("search")}
                        on:click={() => {
                            apiLoadRmsContacts(email, firstName, lastName, linked);
                        }}
                    >
                        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                            <path fill="currentColor" d={mdiDownloadCircleOutline} />
                        </Icon>
                        {$_("search")}
                    </Button>
                </Group>
            </Flex>
        </Flex>
    </Flex>
</Paper>
<section class="tescoblue-border-top-thin">
    <Paper class="w-100 bs-bb">
        <DataTable stickyHeader table$aria-label="Contact list" style="width: 100%;" class="report">
            <Head>
                <Row>
                    <Cell columnId="checkbox" class="datatable-header pl-0 pr-0 sticky-col" checkbox>
                        <Checkbox />
                    </Cell>
                    <Cell columnId="firstName" class="datatable-header">
                        <Label>{$_("firstName")}</Label>
                    </Cell>
                    <Cell columnId="lastName" class="datatable-header">
                        <Label>{$_("lastName")}</Label>
                    </Cell>
                    <Cell columnId="contact_email" class="datatable-header">
                        <Label>{$_("contact_email")}</Label>
                    </Cell>
                </Row>
            </Head>
            <Body>
                {#if tableLoadIcon}
                    <Row class="datatable-content">
                        <Cell colspan={10} class="text-center">
                            <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
                            {$_("loading_wait")}
                        </Cell>
                    </Row>
                {:else if slice.length > 0}
                    {#each slice as contact (contact.email)}
                        <Row>
                            <Cell class="pl-0 pr-0 sticky-col" checkbox>
                                <Checkbox bind:group={selectedContacts} value={contact} valueKey={contact.email} />
                            </Cell>
                            <Cell>{contact.firstName}</Cell>
                            <Cell>{contact.lastName}</Cell>
                            <Cell>{contact.email}</Cell>
                        </Row>
                    {/each}
                {:else if tableSearchMessage}
                    <Row class="datatable-content">
                        <Cell colspan={10} class="text-center" style="width: 100%">{$_("search_instructions")}</Cell>
                    </Row>
                {:else}
                    <Row class="datatable-content">
                        <Cell colspan={10} class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
                    </Row>
                {/if}
            </Body>

            <Pagination slot="paginate">
                <svelte:fragment slot="rowsPerPage">
                    <Label>{$_("rows_per_page")}</Label>
                    <Select variant="outlined" bind:value={rowsPerPage} noLabel>
                        <Option value={10}>10</Option>
                        <Option value={25}>25</Option>
                        <Option value={100}>100</Option>
                    </Select>
                </svelte:fragment>
                <svelte:fragment slot="total">
                    {start + 1}-{end} of {contacts.length}
                </svelte:fragment>

                <IconButton class="material-icons" action="first-page" title={$_("first_page")} on:click={() => (currentPage = 0)} disabled={currentPage === 0}
                    >first_page</IconButton
                >
                <IconButton class="material-icons" action="prev-page" title={$_("prev_page")} on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
                <IconButton class="material-icons" action="next-page" title={$_("next_page")} on:click={() => currentPage++} disabled={currentPage === lastPage}
                    >chevron_right</IconButton
                >
                <IconButton class="material-icons" action="last-page" title={$_("last_page")} on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
                    >last_page</IconButton
                >
            </Pagination>
        </DataTable>
    </Paper>
</section>
