<script lang="ts">
    import { onMount } from "svelte";
    import Flex from "svelte-flex";
    import Paper from "@smui/paper";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { _ } from "svelte-i18n";
    import { handleApiError } from "../../lib/errorHandler";

    import { snackbarSuccess } from "../../../../stores/AppStatus";
    import ProductPopup from "./DPPopup.svelte";
    import ProductDataTable from "./DPDataTable.svelte";
    import ProductFilterBar from "./DPFilterBar.svelte";

    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";
    import ProductDelegationDetailsDialog from "./ProductDelegationDetailsDialog.svelte";
    import { getProductDelegation, createProductDelegation, revokeProductDelegation } from "../../../../api/Delegate.js";
    import { getUserManagement } from "../../../../api/User.js";
    import type { productDelegationList, products } from "../../../../types/Management";

    let delegationList: productDelegationList[] = [];
    let role = "TechnicalManager,Buyer";
    let userToList = [];
    let userFromList = [];
    let delegateProductPopupOpen = false;
    let showRevokeProductDelegationConfirmationDialog = false;
    let viewProductDelegationItemsDialog = false;
    let delegationDetails: products[] = [];
    let tableLoad = false;
    let tableSearchMessage = false;

    // Fetch data when the component mounts
    onMount(() => {
        Promise.all([getUserManagement(null, role)])
            .then(([userResponse]) => {
                userFromList = userResponse;
                userToList = userResponse;
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
                if (error?.cause?.response?.status == 401) { throw(error) }
            });
    });

    function apiCreateProductDelegation(data) {
        createProductDelegation(data)
            .then((res) => {
                const date = new Date();
                res[0].created = date.toISOString();

                // Add productDescription to each product from data selection
                res[0].products.forEach((product) => {
                    const productData = data.products.find((p) => p.productId === product.productId);
                    if (productData) {
                        product.productDescription = productData.productDescription;
                    }
                });

                console.log("Created new delegation", res[0]);
                delegationList = [res[0], ...delegationList];
                $snackbarSuccess.text = $_("product_delegation_created");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("product_delegation_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    }

    function apiRevokeProductDelegation(data) {
        revokeProductDelegation(data)
            .then((res) => {
                delegationList = delegationList.map((item) => (item.id === data.id ? { ...item, status: 0 } : item));
                $snackbarSuccess.text = $_("product_delegation_revoked");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("product_delegation_revoke_failed", { values: { reason: error.message } }), error, 'non-fatal')) throw(error);
            });
    }

    function apiGetProductDelegation(userFromSearch, userToSearch, tpnEnSearch, userTypeSearch, country = null, statusSearch, id = null) {
        getProductDelegation(userFromSearch, userToSearch, tpnEnSearch, id, country, userTypeSearch, statusSearch)
            .then((response) => {
                delegationList = response;
                console.log("Delegation List Updated", delegationList);

                if (delegationList.length == 0) {
                    console.log("getProductDelegation().empty");
                    tableSearchMessage = false;
                } else if (delegationList.length == null) {
                    console.log("getProductDelegation().null");
                    tableSearchMessage = false;
                }
                tableLoad = false;
            })
            .catch((error) => {
                console.error("Error loading product delegation list:", error);
                if (error?.cause?.response?.status == 401) { throw(error) }
                tableLoad = false;
            });
    }

    let confirmationDialogQuestion = "";
    let confirmationDialogData = {};

    const showRevokeRowConfirmation = (input) => {
        console.log("Show delte id", input);
        confirmationDialogData = input;
        console.log(confirmationDialogData);
        confirmationDialogQuestion = $_("do_you_want_revoke_product_delegation", { values: { id: input.id, country: input.country, type: input.type } });
        showRevokeProductDelegationConfirmationDialog = true;
    };

    const handleEvents = (event) => {
        console.log("Event");
        console.log({ event });

        switch (event.type) {
            case "viewProductDelegation":
                console.log("View Delegate Product");
                console.log(event.detail.products);
                delegationDetails = event.detail.products;
                viewProductDelegationItemsDialog = true;
                break;
            case "delegateProductPopupCancel":
                console.log("Delegate Product popup Cancel");
                delegateProductPopupOpen = false;
                break;
            case "delegateProductPopupSave":
                console.log("Delegate Product popup Saves", event.detail);
                apiCreateProductDelegation(event.detail);
                delegateProductPopupOpen = false;
                break;
            case "revokeProductDelegation":
                console.log("Revoke Product Delegation Dialog");
                showRevokeRowConfirmation(event.detail);
                break;
            case "loadList":
                console.log("loadList");
                tableLoad = true;
                // Extract the parameters from event.detail
                const { userFromSearch, userToSearch, tpnEnSearch, userTypeSearch, countrySearch, statusSearch } = event.detail;
                apiGetProductDelegation(userFromSearch, userToSearch, tpnEnSearch, userTypeSearch, countrySearch, statusSearch);
                break;
            case "clearList":
                console.log("clearList");
                delegationList = [];
                tableSearchMessage = true;
                tableLoad = false;
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };
</script>

<section class="w-100 header top-splitter">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3 pr-3" justify="between">
        <Flex direction="row" justify="between" class="gap-1 w-100">
            <div class="w-100 font-weight-bold">
                {$_("delegate_product")}
            </div>
            <Flex direction="row" justify="end" class="w-100 gap-1">
                <Button
                    class="tescoblue-text white-tescoblue-outlined pr-4 nowrap"
                    title={$_("add_item")}
                    on:click={async () => {
                        console.log("Delegate Product popup Open");
                        delegateProductPopupOpen = true;
                    }}
                >
                    <Icon class="material-icons">add_circle</Icon>
                    {$_("add_product_delegation")}
                </Button>
            </Flex>
        </Flex>
    </Flex>
</section>

<section class="data tescoblue-border-top-thin">
    <Paper class="grey lighten-3 w-100 bs-bb pt-3">
        <div class="smui-paper__content bs-bb">
            <section class="mt-2 pt-2 w-100">
                <ProductFilterBar on:loadList={handleEvents} on:clearList={handleEvents} />
            </section>

            <section class="mt-2 pt-2 w-100">
                <ProductDataTable {delegationList} {tableLoad} {tableSearchMessage} on:revokeProductDelegation={handleEvents} on:viewProductDelegation={handleEvents} />
            </section>
        </div>
    </Paper>
</section>

<ProductPopup open={delegateProductPopupOpen} on:delegateProductPopupCancel={handleEvents} on:delegateProductPopupSave={handleEvents}></ProductPopup>

<ProductDelegationDetailsDialog bind:isOpen={viewProductDelegationItemsDialog} {delegationDetails} />

<ConfirmationDialog
    open={showRevokeProductDelegationConfirmationDialog}
    title={$_("revoke_product_delegation")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    itemData="[]"
    headerColors="tescoblue white-text"
    on:reject={() => {
        showRevokeProductDelegationConfirmationDialog = false;
    }}
    on:confirm={() => {
        showRevokeProductDelegationConfirmationDialog = false;
        apiRevokeProductDelegation(confirmationDialogData);
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>
