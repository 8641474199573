<script>

    import { push } from 'svelte-spa-router'
    import { unauthorized } from "../stores/AppStatus";
    import AppMenuTabs from '../components/AppMenuTabs.svelte';

</script>

{#if $unauthorized }
    { push('/login') }
{:else}
    <AppMenuTabs/>
{/if}
