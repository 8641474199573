<script>
    import Button, { Label} from '@smui/button';

    import IconButton, { Icon } from '@smui/icon-button';
    import { mdiMenuDown, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

    import SelectorProduct from './SelectorProduct.svelte';

    import Flex from 'svelte-flex';

    import { format } from 'date-fns';
    import { _ } from 'svelte-i18n';

    export let selectedProduct;
    export let products = [];


    const prevProduct = (() => {
        console.log({selectedProduct});
        if (selectedProduct.id > 1) {
            selectedProduct = products[selectedProduct.id - 2];
        }
    })

    const nextProduct = (() => {
        console.log({selectedProduct});
        if (selectedProduct.id < products.length) {
            selectedProduct = products[selectedProduct.id];
        }
    })

    $: prevBtnDisabled = (selectedProduct.id == 1);
    $: nextBtnDisabled = (selectedProduct.id == products.length);

    // $: {
    //     console.log("---------- PRODUCT SELECTOR -----------");
    //     console.log({selectedProduct});
    // }

</script>

<section class="">
{#if selectedProduct}
    <Flex direction="row" justify="start" class="pa-2 gap-1">
        <Flex direction="row">
            <Flex direction="row" class="gap-0">
                <IconButton
                    title="{$_('previous')}"
                    size="button"
                    disabled={prevBtnDisabled}
                    on:click="{prevProduct}"
                >
                    <Icon class="pr-0 mr-0 pl-0 ml-1 {(prevBtnDisabled ? 'grey-text' : 'tescoblue-text')} " tag='svg' viewBox="2 2 20 20">
                        <path fill="currentColor" d={mdiChevronLeft} />
                    </Icon>
                </IconButton>

                <SelectorProduct
                    bind:product={selectedProduct}
                    products={products}
                />

                <IconButton
                    title="{$_('next')}"
                    size="button"
                    disabled={nextBtnDisabled}
                    on:click="{nextProduct}"
                >
                    <Icon class="pr-0 mr-0 pl-0 ml-1 {(nextBtnDisabled ? 'grey-text' : 'tescoblue-text')}" tag='svg' viewBox="2 2 20 20">
                        <path fill="currentColor" d={mdiChevronRight} />
                    </Icon>
                </IconButton>
            </Flex>
        </Flex>
    </Flex>
{/if}
</section>

<style>
    section {
        width: fit-content;
    }
</style>
