<script>
    import { suppliersList } from "../../../stores/Suppliers";
    import { loadSuppliers as callLoadSuppliers } from "../Load/Supplier";

    import { onMount } from "svelte";

    import Select, { Option } from "@smui/select";
    import CircularProgress from "@smui/circular-progress";

    import { _ } from "svelte-i18n";

    export let selection;

    let suppliersFetching = false;

    onMount(async () => {
        loadSuppliers(null, null, 1);
    });

    async function loadSuppliers(id, rmsSupplierName, status) {
        console.log("====================== loadSuppliers =========================");
        if ($suppliersList && Array.isArray($suppliersList) && $suppliersList.length > 0) {
            return;
        }

        suppliersFetching = true;
        $suppliersList = await callLoadSuppliers(id, rmsSupplierName, status);
        suppliersFetching = false;
        console.log({ $suppliersList });
    }
</script>

{#if suppliersFetching}
    <CircularProgress style="height: 2rem; width: 10rem; margin: auto" indeterminate />
{:else if $suppliersList}
    <Select
        variant="outlined"
        bind:value={selection}
        class="white tescoblue-text w-100 small custom-icon "
        menu$style="width: fit-content !important;"
        key={(item) => `${item ? item.id : ""}`}
        label={$_("supplier")}
    >
        <Option value="" />
        {#each $suppliersList as supp}
            <Option value={supp.id} style="white-space: nowrap !important; max-width: 250px; text-overflow: ellipsis; display: block;" title="{supp.id} - {supp.name}">
                {supp.id} - {supp.name}
            </Option>
        {/each}
    </Select>
{/if}
