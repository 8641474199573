// Components
import Index from './routes/Index.svelte'
import Login from './routes/Login.svelte'
import AppMenu from './routes/AppMenu.svelte'
import NotFound from './routes/NotFound.svelte'
import Module from './routes/Module.svelte'

// Export the route definition object
export default {
    // Exact path
    '/': Index,

    '/login': Login,

    '/appmenu': AppMenu,

    '/module': Module,

    // Catch-all, must be last
    '*': NotFound,
}
