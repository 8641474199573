// Library to handle API errors

export const notAuthorizedError = (error) => {
    return (error?.cause?.response?.status == 401);
}

export const isRuntineError = (error) => {
    return !error?.cause?.response;
}

/**
 * Check error, show API error dialog or return false
 *
 * Usage:
 *
 * try {
 *   ...
 * } catch (error) {
 *    console.log({error});
 *    error.loc = "some_location_name";
 *
 *    if (!handleApiError($_("update_failed_plain"), error)) throw(error);
 *  }
 *
 */
export const handleApiError = (errorMessage, error, type) => {

    console.log("handleApiError()");
    if (notAuthorizedError(error) || isRuntineError(error)) {
        return false;
    }


    console.log({errorMessage});
    console.log("error", {error});
    console.log({type});

    let detailError = error?.cause?.response?.data?.error;
    let detailErrorPayload = '';
    let detailMsg = error?.cause?.response?.data?.message;
    let detailCode = error?.cause?.response?.data?.status;
    let detailUrl = error?.cause?.response?.data?.path;
    let traceId = error?.cause?.response?.headers?.traceid;
    let traceParent = error?.cause?.response?.headers?.traceparent;
    let detailMethod = error?.cause?.response?.config?.method?.toUpperCase();
    let inputData = error?.cause?.response?.config?.data;

    // If no data error
    if (!detailMsg) {
        if (error?.cause?.response?.data?.payload?.at(0))
           detailMsg = error?.cause?.response?.data?.payload?.at(0);
        else if (error?.cause?.response?.status && error?.cause?.response?.statusText)
           detailMsg = [error?.cause?.response?.status, error?.cause?.response?.statusText].join(": ");
        else if (error?.cause?.message)
           detailMsg = error?.cause?.message;
    };

    if (!detailError) {
        detailError = error?.cause?.message;
    }

    if (Array.isArray(error?.cause?.response?.data?.payload) && error?.cause?.response?.data?.payload.length > 0) {
        detailErrorPayload = error?.cause?.response?.data?.payload.at(0);
        if (typeof detailErrorPayload === "object" && detailErrorPayload?.status) {
            detailErrorPayload = ["Payload:", detailErrorPayload?.status].join(" ");
        }
    }

    if (!detailUrl && error?.cause?.response?.request?.responseURL) {
        // extract path from full url
        detailUrl = error?.cause?.response?.request?.responseURL.replace(window.location.origin , '').split('?').at(0);
    }

    document.getElementById('errorModalBodyMsg').innerText = errorMessage ? errorMessage : '';
    document.getElementById('errorModalBodyDetailError').innerText = detailError ? detailError : '';
    document.getElementById('errorModalBodyDetailErrorPayload').innerText = detailErrorPayload ? detailErrorPayload : '';
    document.getElementById('errorModalBodyDetailMsg').innerText = detailMsg ? detailMsg : '';
    document.getElementById('errorModalBodyDetailCode').innerText = detailCode ? detailCode : '';
    document.getElementById('errorModalBodyDetailUrl').innerText = detailUrl ? detailUrl : '';
    document.getElementById('errorModalBodyDetailMethod').innerText = detailMethod ? detailMethod : '';
    document.getElementById('errorModalBodyTraceId').innerText = traceId;
    document.getElementById('errorModalBodyTraceParent').innerText = traceParent;
    document.getElementById('errorModalBodyInputData').innerText = inputData;

    if (type == 'fatal') {
        document.getElementById('errorModalActionCloseBtn').style = "display: none"
        document.getElementById('errorModalActionReloadBtn').style = "display: inline-block"
    } else {
        document.getElementById('errorModalActionCloseBtn').style = "display: inline-block"
        document.getElementById('errorModalActionReloadBtn').style = "display: none"
    }

    let errorModal = document.getElementById('errorModal');
    console.log("errorModal", {errorModal});

    if (errorModal)
        errorModal.showModal();
    else {
        console.log("ERROR: errorModal element does not exists!");
        return false;
    }
    return true;
}

//         message: error.cause.message,
//         responseURL: error.cause.request?.responseURL,
//         method: error.cause.response?.config?.method,
//         data: error.cause.response?.config?.data,
//         url: error.cause?.config?.url,
//         date: error.cause.response?.headers?.date,
//         status: error.cause.response?.data.status,
//         dataError: error.cause.response?.data?.error,
//         dataMessage: error.cause.response?.data?.message,
//         dataPayload: error.cause.response?.data?.payload,
//         teamNumber: error.cause.response?.headers?.teamnumber,
//         traceId: error.cause.response?.headers?.traceid,
//         traceParent: error.cause.response?.headers?.traceparent,
