    import { modules } from "../../../stores/AppConfig";
    import { get as getValue } from 'svelte/store';

    // Determine if the ACTION (insert, edit, delete) is enabled in the MODULE when the week has status WEEKSTATUS
export const isActionEnabled = (tab, action, weekStatus) => {
    //console.log("================= isActionEnabled ==============");
    var modls = getValue(modules);
    //console.log({ tab });
    //console.log({ action });
    //console.log({ weekStatus });
    //console.log({ modls });
    try {
        const module = modls.find(m => m.name === tab);
        if (module) {
            const statusArray = module[action + 'Statuses'];
            if (statusArray) {
                return statusArray.map(status => status.trim()).includes(weekStatus.trim());
            }
        }
        //console.error(`isActionEnabled() - No matching action/status found for given role tab: ${tab}, action: ${action}`);
        return false;
    } catch (err) {
        console.error(`isActionEnabled() - failed for tab: ${tab}, action: ${action}, weekStatus: ${weekStatus}`, err);
        return false;
    }
};

    export function isDateValueToCheck(value) {
        console.log("================= isDateValueToCheck ==============");
        return value !== null && value !== '' && !isNaN(value);
    }

    export function excelDateToDate(serial) {
        console.log("================= excelDateToDate ==============");
        const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel leap year bug
        const millisecondsPerDay = 86400000;
        return new Date(excelEpoch.getTime() + serial * millisecondsPerDay);
    }

    export function formatDate(date) {
        console.log("================= formatDate ==============");
        return date.toISOString().split('T')[0]; // "YYYY-MM-DD"
    }

    export function formatDateTime(date) {
        console.log("================= formatDate ==============");
        return date.toISOString().split('.')[0]; // "YYYY-MM-DDTHH24:MI:SS"
    }

    export function validateDateField(date, fieldName, weekStart, weekEnd) {
        console.log("================= validateDateField ==============");
        console.log(date);
        if (!(date instanceof Date && !isNaN(date))) {
            return { priority: 9, message: `${fieldName}_must_be_date`, field: fieldName, type: 'E' };
        }
        if (weekStart && weekEnd) {
            weekStart = new Date(weekStart);
            weekEnd = new Date(weekEnd);
            if (date < weekStart || date > weekEnd) {
                return { priority: 9, message: `${fieldName}_out_of_week`, field: fieldName, type: 'E' };
            }
        }
        return null;
    }

    export const formatVolume = (val) => {
        var locale = navigator.language || 'en-US';
        var priceNumberFormat = new Intl.NumberFormat(locale, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                        });
        return (val != Math.round(val))? (val ? priceNumberFormat.format(+(Math.round(val + "e+2")  + "e-2")) : ''): String(val);
    }

    export const isNumber = (value) => {
        if (typeof value == "string") {
            value = value.replace(',','.');
        }

        return Number.isFinite(+value);
    }
