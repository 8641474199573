<script lang="ts">
    import { onDestroy } from 'svelte';
    import Select, { Option } from '@smui/select';
    import { _ } from 'svelte-i18n';

    export let value;
    export let editable = true;
    export let list = [];
    export let format = { showId: false, translateName: true };
    export let focused = false;

    let displayedOptions = [];
    let pageSize = 50;
    let intervalId;

    function loadContentPeriodically() {
        intervalId = setInterval(() => {
            if (displayedOptions.length < list.length) {
                const nextItems = list.slice(displayedOptions.length, displayedOptions.length + pageSize);
                displayedOptions = [...displayedOptions, ...nextItems];
            } else {
                clearInterval(intervalId); // End of list
            }
        }, 100);
    }

    // Load the selected value initially
    $: if (value) {
        const selectedOption = list.find(item => item.id === value);
        if (selectedOption && displayedOptions.length === 0) {
            displayedOptions = [selectedOption];
        }
    }

    $: if (focused && displayedOptions.length <= 1) {
        loadContentPeriodically();
    }

    onDestroy(() => {
        if (intervalId) {
            clearInterval(intervalId);
        }
    });

    const formatOptionName = ((item) => {
        if (format?.showId) {
            return [item.id, (format?.translateName ? $_(item.name) : item.name)].join(" - ");
        } else {
            return (format?.translateName ? $_(item.name) : item.name);
        }
    })
</script>

<div class="select-cell">
    <Select
        variant="filled"
        bind:value
        noLabel={true}
        class="tescoblue-text white with-parameter w-100 bs-bb outlined"
        disabled={!editable}
        menu$class="mdc-menu-surface--fixed with-parameter__menu"
        on:click={() => { focused = true;}}
        on:SMUISelect:change
    >
        {#each displayedOptions as item}
            <Option value={item.id}>{formatOptionName(item)}</Option>
        {/each}
    </Select>
</div>

<style>
</style>
