<script>
  import Flex from 'svelte-flex';
  import { _ } from 'svelte-i18n'

  import Paper, { Content } from '@smui/paper';
  import CircularProgress from '@smui/circular-progress';

  export let text = $_('loading_wait');
  export let size = 'small';

  let elementSize;

  $: if (size) {
    switch (size) {
      case 'mini':
        elementSize = ("height: 12px; width: 12px;");
        break;
      case 'small':
        elementSize = ("height: 24px; width: 24px;");
        break;
      case 'medium':
        elementSize = ("height: 48px; width: 48px;");
        break;
      case 'big':
        elementSize = ("height: 96px; width: 96px;");
        break;
      case 'extra-big':
        elementSize = ("height: 192px; width: 192px;");
        break;
    default:
        elementSize = ("height: 48px; width: 48px;")
    }
  }

</script>

<section>
    <Paper class="paper90">
        <div class="smui-paper__content">
            <Flex direction="column" align="center" class="w-100" justify="center">
                <CircularProgress style="{elementSize}" indeterminate />
                <div>{text}</div>
            </Flex>
        </div>
    </Paper>
</section>

<style>
    section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    }

</style>
