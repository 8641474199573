<script>
    import { amendments } from "../../stores/Amendments";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import AmCountryScreen from "./CountryAmendments/AmCountryScreen.svelte";
    import AmSupplierScreen from "./SupplierAmendments/AmSupplierScreen.svelte";
    import TescoAppLogo from "../../components/elementary/TescoAppLogo.svelte";

    export let module = "country";
    export let autoLoadData = false;
    $: countryAmendment = $amendments ? $amendments.filter((x) => x.type == "cntr").at(0) : { id: null, items: [] };

    $: {
        console.log({ module });
    }
</script>

{#if module}
    {#if module === "country"}
        <AmCountryScreen
            id={countryAmendment.id}
            tsWeek={countryAmendment.tsWeek}
            calWeek={countryAmendment.calWeek}
            {autoLoadData}
            bind:items={countryAmendment.items}
            on:getDataRequest
        />
    {:else if module === "suppliers"}
        <AmSupplierScreen {autoLoadData} on:getDataRequest />
    {/if}
{:else}
    <section class="list">
        <TescoAppLogo position="center" size="big" />
    </section>
{/if}

<style>
</style>
