<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Textfield, { Input } from "@smui/textfield";
  import Button from "@smui/button";
  import { mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";

  import BuyerTmEmailAutocomplete from "../../common/BuyerTmEmailAutocomplete.svelte";

  const dispatch = createEventDispatcher();

  // Date
  const date = new Date();
  const today = date.toISOString().split("T")[0];

  let userFromSearch = "";
  let userToSearch = "";
  let validFromSearch= today;
  let validToSearch= "";
  $: console.log("Date",validFromSearch);
</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100">
  <BuyerTmEmailAutocomplete
    bind:value={userFromSearch}
    role={"Buyer,TechnicalManager"}
    label={$_("from_user")}
    variant="outlined"
    small={true}
    white={true}
    style="min-width: 300px;"
  />
  <BuyerTmEmailAutocomplete
    bind:value={userToSearch}
    role={"Buyer,TechnicalManager"}
    label={$_("to_user")}
    variant="outlined"
    small={true}
    white={true}
    style="min-width: 300px;"
  />

  <Textfield
    variant="outlined"
    type="date"
    title={$_("valid_from")}
    bind:value={validFromSearch}
    validateOnValueChange
    useNativeValidation
    updateInvalid
    input$emptyValueUndefined
    input$autocomplete="valid_from"
    prefix="{$_("valid_from")}: " 
    class="white small"
  >
</Textfield>

  <Textfield
    variant="outlined"
    type="date"
    title={$_("valid_to")}
    bind:value={validToSearch}
    validateOnValueChange
    useNativeValidation
    updateInvalid
    input$emptyValueUndefined
    input$autocomplete="valid_to"
    prefix="{$_("valid_to")}: " 
    class="white small"
  >
</Textfield>

  <Button
    title={$_("clear")}
    class="tescoblue white-text"
    on:click={() => {
      userFromSearch = "";
      userToSearch = "";
      validFromSearch = today;
      validToSearch = "";
      dispatch("clearList");
    }}
  >
    <svg viewBox="0 0 24 24" width="20" height="20">
      <path fill="currentColor" d={mdiBackspaceOutline} />
    </svg>
  </Button>

  <Button
    class="tescoblue white-text pr-4"
    title={$_("search")}
    on:click={() => {
      dispatch("loadList", {
        userFromSearch: userFromSearch,
        userToSearch: userToSearch,
        validFromSearch: validFromSearch,
        validToSearch: validToSearch,
      });
    }}
  >
    <svg viewBox="0 0 24 24" width="20" height="20">
      <path fill="currentColor" d={mdiDownloadCircleOutline} />
    </svg>
    {$_("search")}
  </Button>
</Flex>

<style>
</style>
