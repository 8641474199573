<script>

    import { dcList }  from "../../../stores/AppConfig";

    import Select, { Option } from '@smui/select';
    import { _ } from 'svelte-i18n';


    export let selection;

    $: dcArray = $dcList.map(x => {return {id: x.code, label: x.code + " - " + x.name}}).sort((a,b) => a.displayOrder - b.displayOrder);

     $: {
         console.log("=== DC ===", {selection});
         console.log({$dcList});
         console.log({dcArray});
     }
</script>

<Select
    variant="outlined"
    bind:value={selection}
    class="white tescoblue-text w-100 small custom-icon"
    menu$style="width: fit-content !important;"
    key="{(item) => `${item ? item.id : ''}`}"
    label={$_('dc')}
>

    <Option value="" />
    {#each dcArray as dc}
        <Option value={dc.id} style="white-space: nowrap !important;">{dc.label}</Option>
    {/each}
</Select>
