<script lang="ts">
    import { onMount } from "svelte";
    import Textfield from "@smui/textfield";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";
    import { createEventDispatcher } from "svelte";
    import Select, { Option } from "@smui/select";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import CountryFilter from "../../common/CountryFilterBar.svelte";
    import Autocomplete from "@smui-extra/autocomplete";

    import { getSupplier } from "../../../../api/Supplier.js";
    import BuyerSearchAutocomplete from "../../common/BuyerSearchAutocomplete.svelte";

    let ceTpnSearch = "";
    let descriptionSearch = "";
    let buyerSearch = "";
    let clearBuyer: boolean = false;
    let tpnbSearch = "";
    let eanSearch = "";
    let technicalManagerSearch = "";
    let countrySearch = [];
    let statusSearch = "";

    const dispatch = createEventDispatcher();

    type Suppliers = {
        id: number;
        name: string;
        status: number;
    };

    let options: Suppliers[] = [];
    let supplierNameSearch: Suppliers | undefined = undefined;
    let supplierTextSearch = "";

    // Fetch data when the component mounts
    onMount(() => {
        Promise.all([getSupplier()])
            .then(([productResponse]) => {
                options = productResponse;
            })
            .catch((err) => {
                if (err?.cause?.response?.status == 401) {
                    throw err;
                }
                console.error("Error fetching data:", err);
            });
    });

    // Email search
    let role = "Buyer";
    let label = "buyer";
</script>

<Flex direction="row" class="">
    <Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100 mr-1">
        <CountryFilter bind:selection={countrySearch} title={$_("country")} size="10" multiselect />

        <Textfield
            variant="outlined"
            bind:value={ceTpnSearch}
            input$maxlength={13}
            input$pattern={"\\d+"}
            style="max-width: 170px;"
            placeholder=""
            title={$_("ce_tpn")}
            class="white w-100 small flex-1"
            input$class="mnw-100"
            label={$_("tpn_en")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={tpnbSearch}
            input$maxlength={9}
            input$pattern={"\\d+"}
            style="max-width: 140px;"
            placeholder=""
            title={$_("tpnb")}
            class="white small flex-0"
            input$class="mnw-100"
            label={$_("tpnb")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={eanSearch}
            input$maxlength={14}
            input$pattern={"\\d+"}
            placeholder=""
            title={$_("ean")}
            class="white w-100 small flex-0"
            input$class="mnw-100"
            label={$_("ean")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={descriptionSearch}
            input$maxlength={200}
            placeholder=""
            title={$_("description")}
            class="white w-100 small flex-1"
            input$class="mnw-100"
            label={$_("description")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <!--  COD-4875       <BuyerTmEmailAutocomplete bind:value={buyerSearch} {role} {label} variant="outlined" small="{true}" class="white tescoblue-text" /> -->
        <!--  COD-4610       <BuyerTmEmailAutocomplete bind:value={technicalManagerSearch} variant="outlined" small="{true}" class="white tescoblue-text" /> -->
        <BuyerSearchAutocomplete bind:emailOutput={buyerSearch} bind:clear={clearBuyer} class="white tescoblue-text small" />

        <Autocomplete
            {options}
            bind:value={supplierNameSearch}
            bind:text={supplierTextSearch}
            getOptionLabel={(option) => (option ? `${option.id} - ${option.name}` : "")}
        >
            <Textfield
                bind:value={supplierTextSearch}
                withLeadingIcon
                variant="outlined"
                class="white small"
                label={$_("ce_supplier")}
                input$emptyValueUndefined
            >
                <svelte:fragment slot="leadingIcon">
                    <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                        <path fill="currentColor" d={mdiMagnify} />
                    </Icon>
                </svelte:fragment>
            </Textfield>
        </Autocomplete>

        <Select variant="outlined" key={(bool) => `${bool}`} bind:value={statusSearch} label={$_("status")} class="white small" style="width: 135px;">
            <Option value="" />
            {#each [1, 0] as value}
                <Option {value}>{value ? $_("active") : $_("inactive")}</Option>
            {/each}
        </Select>
    </Flex>

    <Flex direction="row" class="ml-1 gap-05" style="align-self: end;">
        <Button
            title={$_("clear")}
            class="tescoblue white-text"
            on:click={() => {
                countrySearch = [];
                ceTpnSearch = "";
                tpnbSearch = "";
                descriptionSearch = "";
                eanSearch = "";
                clearBuyer = true;
                technicalManagerSearch = "";
                supplierTextSearch = "";
                supplierNameSearch = undefined;
                statusSearch = "";
                dispatch("clearProductList");
            }}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
                <path fill="currentColor" d={mdiBackspaceOutline} />
            </Icon>
        </Button>

        <Button
            class="tescoblue white-text pr-4"
            title={$_("search")}
            on:click={() => {
                dispatch("loadProductList", {
                    id: ceTpnSearch,
                    tpnb: tpnbSearch,
                    ean: eanSearch,
                    description: descriptionSearch,
                    buyer: buyerSearch,
                    technicalManager: technicalManagerSearch,
                    country: countrySearch
                        .map((x) => {
                            return { id: x.id }.id;
                        })
                        .join(",")
                        .toUpperCase(),
                    supplierName: supplierNameSearch ? supplierNameSearch.name : "",
                    status: statusSearch,
                });
            }}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                <path fill="currentColor" d={mdiDownloadCircleOutline} />
            </Icon>
            {$_("search")}
        </Button>
    </Flex>
</Flex>