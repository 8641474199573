import Api from '../services/Api';

// Method to get Exchange Rates
export const getExchangeRates = async (id, currencyIdArray, validFrom, validTo, validNow) => {
    try {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        if (currencyIdArray) params.append('currencyIds', currencyIdArray);
        if (validFrom) params.append('validFrom', validFrom);
        if (validTo) params.append('validTo', validTo);
        if (validNow) params.append('validNow', validNow);

        console.log('getExchangeRates params:', params.toString());

        const response = await Api.get("/exchangerate", params);
        console.log({ response });
        if (response.status) {
            return response.payload;
        } else {
            console.error(response.payload ? response.payload : response.message);
            throw new Error(response.payload ? response.payload : response.message);
        }
    } catch (error) {
        console.log(error);
        console.error(error.response.data.payload);
        throw new Error(error.message, { cause: error });
    }
};

// Method to save Exchange Rate
export const saveExchangeRate = async (data) => {
    try {
        const response = await Api.post('/exchangerate', data );
        console.log({ response });
        if (response.status) {
            data.id = response.payload[0].id;
            return data;
        } else {
            console.error(response.data ? response.data : response.statusText);
            throw new Error(response.data ? response.data : response.statusText);
        }
    } catch (error) {
        console.log(error);
        console.error(error.response?.data?.message);
        throw new Error(error.message, { cause: error });
    }
};

export const createForecast = async (data) => {
    try {
        let url = Array.isArray(data) ? "/forecast/array" : "/forecast/";

        const response = await Api.post(url, data);
        console.log("createForecast", { response });
        if (response.status) {
            if (data.idXLS && data.idXLS != null) {
                response.payload[0].idXLS = data.idXLS;
            }
            return response.payload;
        } else {
            console.log(response.payload ? response.payload : response.message);
            throw new Error(response.payload ? response.payload : response.message);
        }
    } catch (error) {
        console.log(error);
        console.error(error.response.data.payload);
        throw new Error(error.message, { cause: error });
    }
};

// Method to update Exchange Rate
export const updateExchangeRate = async (data) => {
    try {
        const params = new URLSearchParams();
        params.append('id', data?.id);

        const response = await Api.put('/exchangerate', params, data);
        console.log({ response });
        if (response.status) {
            return response.data;
        } else {
            console.error(response.data ? response.data : response.statusText);
            throw new Error(response.data ? response.data : response.statusText);
        }
    } catch (error) {
        console.log(error);
        console.error(error.response?.data?.message);
        throw new Error(error.message, { cause: error });
    }
};

// Method to delete Exchange Rate
export const deleteExchangeRate = async (id) => {
    try {
        const params = new URLSearchParams();
        params.append('id', id);

        console.log('deleteExchangeRate params:', params.toString());

        const response = await Api.delete('/exchangerate', params);
        console.log({ response });
        if (response.status) {
            return response.data;
        } else {
            console.error(response.data ? response.data : response.statusText);
            throw new Error(response.data ? response.data : response.statusText);
        }
    } catch (error) {
        console.log(error);
        console.error(error.response?.data?.message);
        throw new Error(error.message, { cause: error });
    }
};
