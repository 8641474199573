<script lang="ts">
    import { _ } from "svelte-i18n";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Checkbox from "@smui/checkbox";
    import Textfield from "@smui/textfield";
    import { Icon } from "@smui/icon-button";

    import { getFieldErrMsg, existsError } from "../Upload/Upload";
    import { validateVolumeField } from "../Upload/ValidateAmendmentSupplier";

    import DTInputCell from "../common/DTInputCell.svelte";
    import DTSelectCell from "../common/DTSelectCell.svelte";
    /* 
    import { selectedWeek } from "../../../stores/AppStatus";
     */
    import { amendmentDeadlineTimeIntervals } from "../../../stores/AppConfig";

    // Type
    import type { SupplierAM } from "../../../types/Amendments";

    export let isSupplier = false;
    export let suppliersData = [];
    export let backupRowData: SupplierAM;

    export let rowData: SupplierAM = {
        id: 0,
        amendCountryId: 0,
        forecastId: 0,
        year: 0,
        week: 0,
        roundNo: 0,
        country: "",
        supplierId: 0,
        supplierDescription: "",
        supplierStatus: 0,
        warehouseId: 0,
        productId: 0,
        productDetailId: 0,
        productDescription: "",
        purchaseId: 0,
        tpnb: "",
        deliveryDate: "",
        units: "",
        originalVol: 0,
        amendVol: 0,
        requestedVol: 0,
        timeLimit: 0,
        deadline: "",
        confirmedVol: 0,
        supplierComment: null,
        supplierDate: null,
        status: "",
        applyStatus: "",
        countryComment: null,
        userComment: null,
        fileUuid: null,
        errors: [],
        warnings: [],
    };

    /* 
    // Date range acording selected week
    function readableDate(date) {
        return date.toISOString().split("T")[0];
    }

    $: minDate = $selectedWeek.week_start ? readableDate($selectedWeek.week_start) : undefined;
    $: maxDate = $selectedWeek.week_end ? readableDate($selectedWeek.week_end) : undefined;
 */
    let noSupplierResponse = false;
    $: noSupplierResponse = rowData.status === "not_responded";

    let deadlineTimeIntervals = $amendmentDeadlineTimeIntervals.map((x) => {
        return { id: x.id, name: $_(x.name) };
    });

    $: {
        console.log({ suppliersData });
        console.log({ rowData });
    }

    const supplierIdChanged = () => {
        rowData.supplierDescription = suppliersData.find((s) => s.id == rowData.supplierId)?.name || "";
        rowData.confirmedVol = null;
        rowData.supplierDate = null;
        rowData.supplierComment = "";
        rowData.userComment = "";
        noSupplierResponse = false;
    };

    function toggleFieldsAccessibility() {
        console.log({ backupRowData });
        if (!noSupplierResponse) {
            console.log("Change:");
            rowData.confirmedVol = 0;
            rowData.supplierComment = "";
            rowData.supplierDate = null;
            handleVolumeChange();
            rowData.status = "not_responded";
        } else {
            console.log("restore:", { backupRowData });
            rowData.confirmedVol = backupRowData.confirmedVol !== undefined ? backupRowData.confirmedVol : null;
            handleVolumeChange();
            rowData.status = backupRowData.status;
            rowData.supplierComment = backupRowData.supplierComment;
            rowData.supplierDate = backupRowData.supplierDate;
            console.log("restore:", { rowData });
        }
    }

    // Function to handle volume changes
    function handleVolumeChange() {
        // Convert to number or keep original value if conversion fails
        function convertToNumberOrKeepOriginal(value) {
            const numberValue = Number(value);
            return isNaN(numberValue) ? value : numberValue;
        }

        // Check and convert confirmedVol if necessary
        // Allow "-" string to be displayed as start of a negativ value
        if (rowData.status != "new") {
            if (rowData.confirmedVol === "-" || rowData.confirmedVol === "") {
                rowData.confirmedVol = rowData.confirmedVol;
            } else {
                // Convert the input string to a number and update rowData if conversion is valid
                rowData.confirmedVol = rowData.confirmedVol != null ? convertToNumberOrKeepOriginal(rowData.confirmedVol) : null;
            }
        } else {
            if (rowData.requestedVol === "-" || rowData.requestedVol === "") {
                rowData.requestedVol = rowData.requestedVol;
            } else {
                rowData.requestedVol = convertToNumberOrKeepOriginal(rowData.requestedVol);
            }
        }

        // Validate requestedVol
        const validationReqError = validateVolumeField(rowData.requestedVol, rowData.amendVol, "requestedVol", true, false);

        // Validate confirmedVol
        const validationConfError = validateVolumeField(rowData.confirmedVol, rowData.requestedVol, "confirmedVol", false, true);

        // Remove all errors for confirmedVol and requestedVol from the errors
        rowData.errors = rowData.errors.filter((error) => error.field !== "confirmedVol" && error.field !== "requestedVol");

        // Check for validation errors
        if (validationReqError) {
            rowData.errors.push(validationReqError);
        }
        if (validationConfError && rowData.status != "new") {
            rowData.errors.push(validationConfError);
        }

        // Call update status function if there are no errors
        if (!validationReqError && !validationConfError) {
            updateStatusBasedOnVolume();
        }
    }

    function updateStatusBasedOnVolume() {
        if (rowData.confirmedVol === 0) {
            rowData.status = "rejected";
        } else if (rowData.confirmedVol === rowData.requestedVol) {
            rowData.status = "confirmed_all";
        } else if (rowData.requestedVol < 0 && rowData.confirmedVol > rowData.requestedVol) {
            rowData.status = "confirmed_less";
        } else if (rowData.confirmedVol < rowData.requestedVol) {
            rowData.status = "confirmed_less";
        }
    }

</script>

<section class="scroll-on">
    <DataTable class="w-100 input-form no-border pa-2">
        <Body>
            <Row>
                <Cell class="form-label">{$_("supplier")}</Cell>
                <DTSelectCell
                    bind:value={rowData.supplierId}
                    disabled={rowData.status != "new"}
                    list={suppliersData}
                    format={{ showId: true, translateName: false }}
                    on:change={supplierIdChanged}
                />
            </Row>
            <!-- Error: No purchase / suppliers -->
            {#if existsError(rowData, "supplierId", "E")}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell colspan="4" style="padding: 0">
                        <div class="red-text fs-08rem">
                            {$_(getFieldErrMsg(rowData, "supplierId", "E"))}
                        </div>
                    </Cell>
                </Row>
            {/if}
            <Row>
                <Cell class="form-label">{$_("supp_deadline")}</Cell>
                <DTInputCell
                    colspan="2"
                    value={rowData.deadline ? rowData.deadline : ""}
                    editable={false}
                    numeric={false}
                    class="w-100 fs-1rem tescoblue-text grey lighten-2 edit-cell-border"
                />
            </Row>
            {#if !isSupplier}
            <Row>
                <Cell class="form-label">{$_("amend_vol")}</Cell>
                <DTInputCell
                    colspan="2"
                    value={rowData.amendVol ? rowData.amendVol : 0}
                    editable={false}
                    numeric={false}
                    class="w-100 fs-1rem tescoblue-text grey lighten-2 edit-cell-border"
                />
            </Row>                
            {/if}
            <Row>
                <Cell class="form-label">{$_("req_volume_cases")}</Cell>
                <td colspan="4">
                    <Textfield
                        variant="outlined"
                        type="text"
                        bind:value={rowData.requestedVol}
                        disabled={rowData.status != "new"}
                        withTrailingIcon={existsError(rowData, "requestedVol", "E")}
                        invalid={existsError(rowData, "requestedVol", "E") || rowData?.requestedVol == null || rowData?.requestedVol == undefined}
                        updateInvalide={false}
                        input$maxlength={8}
                        input$autocomplete="requestedVol"
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        required
                        class={rowData.status != "new" ? "w-100 fs-1rem tescoblue-text grey lighten-2 edit-cell-border" : "w-100 tescoblue-text edit-cell-border"}
                        on:input={() => {
                            handleVolumeChange();
                        }}
                    >
                        <svelte:fragment slot="trailingIcon">
                            {#if existsError(rowData, "requestedVol", "E")}
                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                            {/if}
                        </svelte:fragment>
                    </Textfield>
                </td>
            </Row>
            {#if existsError(rowData, "requestedVol", "E")}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell colspan="4" style="padding: 0">
                        <div class="red-text fs-08rem">
                            {$_(getFieldErrMsg(rowData, "requestedVol", "E"))}
                        </div>
                    </Cell>
                </Row>
            {/if}
            {#if !isSupplier}
            <Row>
                <Cell class="form-label">{$_("country_comment")}</Cell>
                <DTInputCell
                    colspan="2"
                    value={rowData.countryComment ? rowData.countryComment : ""}
                    editable={false}
                    numeric={false}
                    class="w-100 fs-1rem tescoblue-text grey lighten-2"
                />
            </Row>               
            {/if}

            {#if !isSupplier}
            <Row>
                <Cell class="form-label">{$_("time_limit")}</Cell>
                <DTSelectCell bind:value={rowData.timeLimit} disabled={rowData.status != "new"} list={deadlineTimeIntervals} format={{ showId: false, translateName: false }} />
            </Row>               
            {/if}

            {#if !isSupplier}
            <Row>
                <Cell class="form-label">{$_("supp_no_response")}</Cell>
                <Cell>
                    <Checkbox bind:checked={noSupplierResponse} disabled={rowData.status === "new"} on:click={toggleFieldsAccessibility} />
                </Cell>
            </Row>                
            {/if}

            <Row>
                <Cell class="form-label">{$_("supp_conf_vol")}</Cell>
                <td colspan="4">
                    <Textfield
                        variant="outlined"
                        type="text"
                        bind:value={rowData.confirmedVol}
                        disabled={rowData.status === "new"}
                        withTrailingIcon={existsError(rowData, "confirmedVol", "E")}
                        invalid={existsError(rowData, "confirmedVol", "E") || rowData?.confirmedVol == null || rowData?.confirmedVol == undefined}
                        updateInvalide={false}
                        input$maxlength={8}
                        input$autocomplete="confirmedVol"
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        required
                        class={rowData.status === "new" ? "w-100 fs-1rem tescoblue-text grey lighten-2 edit-cell-border" : "w-100 tescoblue-text edit-cell-border"}
                        on:input={() => {
                            handleVolumeChange();
                        }}
                    >
                        <svelte:fragment slot="trailingIcon">
                            {#if existsError(rowData, "confirmedVol", "E")}
                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                            {/if}
                        </svelte:fragment>
                    </Textfield>
                </td>
            </Row>
            {#if existsError(rowData, "confirmedVol", "E")}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell colspan="4" style="padding: 0">
                        <div class="red-text fs-08rem">
                            {$_(getFieldErrMsg(rowData, "confirmedVol", "E"))}
                        </div>
                    </Cell>
                </Row>
            {/if}
            <Row>
                <Cell class="form-label">{$_("supplier_comment")}</Cell>
                <DTInputCell
                    colspan="2"
                    bind:value={rowData.supplierComment}
                    editable={rowData.status != "new"}
                    maxlength={500}
                    numeric={false}
                    class={rowData.status != "new" ? "w-100 tescoblue-text" : "w-100 fs-1rem tescoblue-text grey lighten-2"}
                />
            </Row>
            <Row>
                <Cell class="form-label">{$_("user_comment")}</Cell>
                <DTInputCell
                    colspan="2"
                    bind:value={rowData.userComment}
                    editable={rowData.status != "new" && !isSupplier}
                    maxlength={500}
                    numeric={false}
                    class={rowData.status != "new"  && !isSupplier ? "w-100 tescoblue-text" : "w-100 fs-1rem tescoblue-text grey lighten-2"}
                />
            </Row>
        </Body>
    </DataTable>
</section>

<style>
    section {
        padding: 0.5rem;
    }
</style>
