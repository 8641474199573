import { Client } from '@stomp/stompjs';
import { writable, get } from 'svelte/store';
import { selectedWeek } from './AppStatus';
import { endPointUrl } from './AppConfig';

export const messages = writable([]);
export const connectionStatus = writable('Disconnected');

let client;
let isConnected = false;

export function wsConnect() {
    if (isConnected) return; // Prevent duplicate connections

    // Wait for endPointUrl until its set
    const unsubscribe = endPointUrl.subscribe((url) => {
        const API_URL = import.meta.env.VITE_PROXY_API_URL || url;

        if (!API_URL || API_URL === '') {
            console.log('Waiting for a valid API URL...');
            return;
        }

        let tmpUrl = API_URL.replace(/^http/, import.meta.env.VITE_PROXY_API_URL ? 'ws' : 'wss').trimEnd();
        if (tmpUrl.at(-1) != '/') {
            tmpUrl = tmpUrl + '/'
        }

        const WS_URL = tmpUrl + 'msg';

        console.log({ WS_URL });

        client = new Client({
            brokerURL: WS_URL,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => {
                connectionStatus.set('Connected');
                isConnected = true;
                subscribeToTopics();

                // Unsubscribe from endPointUrl once connected
                if (unsubscribe && typeof unsubscribe === 'function') {
                    unsubscribe();
                }
            },
            onDisconnect: () => {
                connectionStatus.set('Disconnected');
                isConnected = false;
            },
            onStompError: (error) => {
                console.error('STOMP error: ', error);
                connectionStatus.set('Disconnected');
                isConnected = false;
            },
            onWebSocketError: (error) => {
                console.error('WebSocket error: ', error);
                connectionStatus.set('Disconnected');
                isConnected = false;
            },
        });

        client.activate();
    });
}

function subscribeToTopics() {
    console.log('Subscribing to WebSocket topics...');
    client.subscribe('/topic/messages', handleMessage);
    client.subscribe('/topic/weekStatus', handleMessage);
 //   client.subscribe('/topic/exchangeRate', handleMessage);
}

function handleMessage(message) {
    const parsedMsg = JSON.parse(message.body);

    if (message.headers.destination === '/topic/weekStatus') {
        handleWeekStatusMessage(parsedMsg);
    }

    // Adding to messages store for testing purpouse
    messages.update((m) => [...m, parsedMsg]);
}

function handleWeekStatusMessage(msg) {
    const currentWeek = get(selectedWeek);

    if (currentWeek.id !== msg.data.id || currentWeek.status !== msg.data.status) {
        // Update the selectedWeek store status
        selectedWeek.update(week => ({
            ...week,
            id: msg.data.id,
            status: msg.data.status
        }));
    }
}

export function sendMessage(text, data = {}) {
    const message = {
        from: 'User',
        text,
        data,
    };

    client.publish({ destination: '/app/msg', body: JSON.stringify(message) });
}
