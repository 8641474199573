<script>
    import { createEventDispatcher } from "svelte";
    import Button from "@smui/button";
    import Paper, { Title, Content } from "@smui/paper";
    import Flex from "svelte-flex";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiFileExcel, mdiPencil } from "@mdi/js";
    import { _ } from "svelte-i18n";

    import { isActionEnabled } from "../lib/Functions";
    import CircularProgress from '@smui/circular-progress';

    const dispatch = createEventDispatcher();

    export let week;
    export let apiCallProcessing;

    $: editDisabled = !(isActionEnabled('quote_sheets', 'insert', week.status) || isActionEnabled('quote_sheets', 'edit', week.status) || isActionEnabled('quote_sheets', 'delete', week.status));
    $: uploadDisabled = !isActionEnabled('quote_sheets', 'insert', week.status);

    let tableRows = [
        { key: "qsTotalLines", label: "total_lines" },
        { key: "qsUsedSuppliers", label: "suppliers_uploaded" },
        { key: "qsUsedProducts", label: "products_uploaded" },
        { key: "qsBuyers", label: "buyers_involved" }
    ];
</script>

<section>
    <Paper square elevation="0" class="pa-0 ma-0">
        <Title class="pt-2 pl-2 pr-2 tescoblue-text">
            <Flex direction="row" align="center" justify="between" class="gap-1">
                <div>{$_("quote_sheets")}</div>
                <Flex direction="row" class="mr-2 gap-1">
                    <div title="{$_('upload')}">
                        <Button
                            disabled={uploadDisabled}
                            variant="outlined"
                            class="{(uploadDisabled) ? ' grey-text white-grey-outlined' : ' tescoblue-text white-tescoblue-outlined'}"
                            on:click="{() => dispatch('uploadQSData', {id: week.id, year: week.tescoYear})}"
                        >    
                            <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
                                <path fill="currentColor" d={mdiFileExcel} />
                            </Icon>
                            {$_('upload')}
                        </Button>
                    </div>
                    {#if editDisabled}
                        <div title={$_("view")}>
                            <Button 
                                class="pl-4 pr-4 tescoblue-text white-tescoblue-outlined "
                                on:click={() => dispatch("editQSWeek", {id: week.id, year: week.tescoYear})}
                            >
                                <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
                                    <path fill="currentColor" d={mdiMagnify} />
                                </Icon>
                                {$_("view")}
                            </Button>
                        </div>
                    {:else}
                        <div title={$_("edit")}>
                        <Button 
                                class="pl-4 pr-4 {(editDisabled) ? ' grey-text white-grey-outlined ' : ' tescoblue-text white-tescoblue-outlined '}"
                                on:click={() => dispatch("editQSWeek", {id: week.id, year: week.tescoYear})}
                            >
                                <Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
                                    <path fill="currentColor" d={mdiPencil} />
                                </Icon>
                                {$_("edit")}
                            </Button>
                        </div>
                    {/if}
                </Flex>
            </Flex>
        </Title>
        <Content class="ma-2 pb-2">
            <Flex direction="row" class="w-100 gap-2" justify="between">
                <DataTable class="summary summary-border-fix tescoblue-border w-100">
                    <Body>
                        {#each tableRows as row}
                            <Row>
                                <Cell class="text-label datatable-header pl-1 pr-2">{$_(row.label)}</Cell>
                                <Cell class="text-right pl-2 pr-2" style="min-width:7rem">
                                    {#if apiCallProcessing}
                                        <CircularProgress indeterminate style="height: 24px; width: 24px;" />
                                    {:else}
                                        {week[row.key]}
                                    {/if}
                                </Cell>
                            </Row>
                        {/each}
                    </Body>
                </DataTable>
            </Flex>
        </Content>
    </Paper>
</section>

<style>
</style>
