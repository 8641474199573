import { _ } from 'svelte-i18n';
import { getSupplierAmendRound as apiGetSupplierAmendRound } from "../../../api/AmendmentsSupplier";
import { getProduct, getProductDetail } from '../../../api/Product';

function isNumber(value) {
	return typeof value === 'number' && !isNaN(value);
}

function isInteger(value) {
	return Number.isInteger(value);
}

function isDateValueToCheck(value) {
	return value !== null && value !== '' && !isNaN(value);
}

function excelDateToDate(serial) {
	const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel leap year bug
	const millisecondsPerDay = 86400000;
	return new Date(excelEpoch.getTime() + serial * millisecondsPerDay);
}

function formatDate(date) {
	return date.toISOString().split('T')[0]; // "YYYY-MM-DD"
}

function validateDateField(date, fieldName, weekStart, weekEnd) {
	if (!(date instanceof Date && !isNaN(date))) {
		return { priority: 9, message: `${fieldName}_must_be_date`, field: fieldName, type: 'E' };
	}
	weekStart = new Date(weekStart);
	weekEnd = new Date(weekEnd);
	if (date < weekStart || date > weekEnd) {
		return { priority: 9, message: `${fieldName}_out_of_range`, field: fieldName, type: 'E' };
	}
	return null;
}


export function validateDataType(fieldValue, fieldName) {
	if (!isNumber(fieldValue)) {
		return { priority: getPriorityForField(fieldName), message: `${fieldName}_must_be_number`, field: fieldName, type: 'E' };
	}
	if (!isInteger(fieldValue)) {
		return { priority: getPriorityForField(fieldName), message: `${fieldName}_must_be_integer`, field: fieldName, type: 'E' };
	}
	return null;
}

function getPriorityForField(fieldName) {
	const priorities = {
		week: 1,
		warehouseId: 2,
		tpnb: 3,
		productId: 4,
		requestedVol: 7,
		confirmedVol: 8,
	};
	return priorities[fieldName] || 4;
}

export function validateVolume(volume, field) {
	// Datatype check
	const typeError = validateDataType(volume, field);
	if (typeError) return typeError;

	if (volume === null || volume === undefined) {
		return { priority: 4, message: `${field}_volume_missing`, field, type: 'E' };
	}

	if (volume < -10000000 || volume > 10000000) {
		return {
			priority: 4, message: `${field}_volume_out_of_range`, field, type: 'E'
		};
	}
	return null;
}

export function validateVolumeField(volume, compareVolume, volumeField, exactMatch = false, specificStringError = false) {
	if (volume === null || volume === undefined) {
		return { priority: 8, message: `${volumeField}_missing`, field: volumeField, type: 'E' };
	}

	// Check if it's a number
	if (isNumber(volume)) {
		// Volume range validation
		const volumeValidationError = validateVolume(volume, volumeField);
		if (volumeValidationError) {
			return volumeValidationError;
		}

		if (volume == 0) return null;

		// Check for exact match if required
		if (exactMatch && volume == compareVolume) return null;

		// Range validation based on the comparison volume
		if (compareVolume >= 0 && (volume < 1 || volume > compareVolume)) {
			return { priority: 8, message: `${volumeField}_out_of_range`, field: volumeField, type: 'E' };
		} else if (compareVolume <= 0 && (volume < compareVolume || volume > -1)) {
			return { priority: 8, message: `${volumeField}_out_of_range`, field: volumeField, type: 'E' };
		}
	} else {
		// Handle specific error messages
		const errorMessage = specificStringError ? `${volumeField}_must_be_number_or_specific_string` : `${volumeField}_must_be_number`;
		return { priority: 8, message: errorMessage, field: volumeField, type: 'E' };
	}
	return null;
}

export function validateTPNBExistence(productDetail) {
	if (!productDetail) {
		return { priority: 3, message: 'missing_tpnb', field: 'tpnb', type: 'E' };
	} else if (productDetail.status != true) {
		return { priority: 3, message: 'tpnb_is_inactive', field: 'tpnb', type: 'E' };
	}
	return null;
}

export function validateExistence(productId, field, productMap) {
	const product = productMap.get(productId);
	if (!product) {
		return { priority: 2, message: 'product_not_found', field, type: 'E' };
	} else if (product.status != true) {
		return { priority: 2, message: 'product_is_inactive', field: 'productId', type: 'E' };
	}
	return null;
}


export function validateMatch(field1, field2, fieldName) {
	if (field1 != field2 && field === 'requestedVol') {
		return { priority: 3, message: `${fieldName}_mismatch`, fieldName, type: 'E' };
	} else if (field1 != field2) {
		return { priority: 3, message: `${fieldName}_mismatch`, fieldName, type: 'E' };
	}
	return null;
}

export const validateUploadedAMSData = async (data, selectedWeek) => {

	// Taking supplierId and country from first row, as its same for all rows
	const firstRow = data[0] || {};
	const { supplierId, country } = firstRow;

	// NOTE: there could be more countries in file. Quick win solution is to call WS's for all countries
	try {
		const [productResponse,
			  detailResponseCZ, detailResponseSK, detailResponseHU,
			  amendResponseCZ, amendResponseSK, amendResponseHU] = await Promise.all([
			getProduct(null, null, null, null, null, null, null, null, null, supplierId),
			getProductDetail(null, null, 'CZ', null, null, null, null, null, null, null, null, null, supplierId),
			getProductDetail(null, null, 'SK', null, null, null, null, null, null, null, null, null, supplierId),
			getProductDetail(null, null, 'HU', null, null, null, null, null, null, null, null, null, supplierId),
			apiGetSupplierAmendRound(null, selectedWeek.tesco_year, selectedWeek.tesco_week, null, null, null, 'CZ', supplierId),
			apiGetSupplierAmendRound(null, selectedWeek.tesco_year, selectedWeek.tesco_week, null, null, null, 'SK', supplierId),
			apiGetSupplierAmendRound(null, selectedWeek.tesco_year, selectedWeek.tesco_week, null, null, null, 'HU', supplierId)
		]);

		// Maping to find the connection
		const productMap = new Map(productResponse.map(p => [p.id, p]));
		const productDetailMap = new Map([...detailResponseCZ, ...detailResponseSK, ...detailResponseHU,].map(pd => [`${pd.country}-${pd.tpnb}`, pd]));
		const amendMap = new Map([...amendResponseCZ, ...amendResponseSK, ...amendResponseHU].map(item => [item.productId + '_' + item.tpnb + '_' + item.warehouseId + '_' + item.deliveryDate, item]));

		data.forEach(x => x.year = selectedWeek.tesco_year);

		for (let r of data) {
			r.errors = [];
			r.warnings = [];

			// Validate tpnb
			const tpnbDataTypeError = validateDataType(r.tpnb, 'tpnb');
			if (tpnbDataTypeError) r.errors.push(tpnbDataTypeError);
			else {
				const detailKey = `${r.country}-${r.tpnb}`;
				const productDetail = productDetailMap.get(detailKey);
				const tpnbError = validateTPNBExistence(productDetail);
				if (tpnbError) r.errors.push(tpnbError);
				else {
					// ProductId
					const productIdDataTypeError = validateDataType(r.productId, 'productId');
					if (productIdDataTypeError) r.errors.push(productIdDataTypeError);
					else {
						if (r.productId == productDetail.productId) {
							r.productDetailId = productDetail.id;
							const productError = validateExistence(r.productId, 'productId', productMap);
							if (productError) r.errors.push(productError);
							else {
								// Delivery date checking
								if (isDateValueToCheck(r.deliveryDate)) {
									r.deliveryDate = formatDate(excelDateToDate(r.deliveryDate));
									const deliveryDateError = validateDateField(new Date(r.deliveryDate), 'deliveryDate', selectedWeek.week_start, selectedWeek.week_end);
									if (deliveryDateError) {
										r.errors.push(deliveryDateError);
									} else {
										r.createdAmend = formatDate(excelDateToDate(r.createdAmend));
										// Amend data from DB connection
										const key = r.productId + '_' + r.tpnb + '_' + r.warehouseId + '_' + r.deliveryDate;
										const amendItem = amendMap.get(key);
										if (amendItem) {
											// Validate all volume fields and set statuses
											// Status of amendd has to be "sent"
											if (amendItem.applyStatus != "not_applied") {
												r.errors.push({ priority: 1, message: 'supplier_res_already_confirmed', field: 'applyStatus', type: 'E' });
												r.applyStatus = "";
											} else {

												if (amendItem.status != "sent") {
													r.warnings.push({ priority: 3, message: 'supplier_am_already_updated', field: 'status', type: 'W' });
												}
												// Requested volume
												const volumeReqError = validateVolume(r.requestedVol, 'requestedVol');
												if (volumeReqError) {
													r.errors.push(volumeReqError)
												} else {
													// // Confirmed volume
													if (typeof r.confirmedVol === 'string') {
														const upperVol = r.confirmedVol.toUpperCase();
														if (['ANO', 'YES'].includes(upperVol)) {
															r.confirmedVol = r.requestedVol;
															r.status = "confirmed_all"
														} else if (['NE', 'NO'].includes(upperVol)) {
															r.confirmedVol = 0;
															r.status = "rejected"
														} else {
															r.errors.push({ priority: 8, message: 'confirmedVol_invalid_string', field: 'confirmedVol', type: 'E' });
														}
													} else {
														const volumeConError = validateVolumeField(r.confirmedVol, r.requestedVol, 'confirmedVol', false, true);
														if (volumeConError) { r.errors.push(volumeConError) } else {
															if (r.confirmedVol === 0) {
																r.status = "rejected"
															} else if (r.confirmedVol === r.requestedVol) {
																r.status = "confirmed_all"
															} else if (r.requestedVol < 0 && r.confirmedVol > r.requestedVol) {
																r.status = "confirmed_less"
															} else if (r.confirmedVol < r.requestedVol) {
																r.status = "confirmed_less";
															} else r.status = "error";

														}
													}
												}
												// Check if the delivery date is same as in database before merging
												const deliveryDateMatch = validateMatch(r.deliveryDate, amendItem.deliveryDate, 'deliveryDate');
												if (deliveryDateMatch) {
													r.errors.push(deliveryDateMatch);
												} else {
													// Check if the requestedVol is same as in database before merging
													const requestedVolMatch = validateMatch(r.requestedVol, amendItem.requestedVol, 'requestedVol');
													if (requestedVolMatch) {
														r.errors.push(requestedVolMatch);
													} else {
														// Update the fields from Amend
														Object.assign(r, {
															id: amendItem.id,
															forecastId: amendItem.forecastId,
															purchaseId: amendItem.purchaseId,
															amendCountryId: amendItem.amendCountryId,
															roundNo: amendItem.roundNo ?? r.roundNo,
															supplierDescription: amendItem.supplierDescription,
															supplierStatus: amendItem.supplierStatus,
															productDescription: amendItem.productDescription,
															units: amendItem.units,
															deadline: amendItem.deadline,
															timeLimit: amendItem.timeLimit ?? r.timeLimit,
															originalVol: amendItem.originalVol ?? r.originalVol,
															amendVol: amendItem.amendVol,
															countryComment: amendItem.countryComment ?? r.countryComment,
														  });
													}
												}
											}
										} else {
											// Add error if no matching amend entry is found
											r.errors.push({
												priority: 3,
												message: 'no_matching_amend_entry_found',
												field: 'productId',
												type: 'E'
											});
										}
									}
								} else {
									r.errors.push({ priority: 9, message: 'deliveryDate_invalid_or_empty', field: 'deliveryDate', type: 'E' });
								}


							}
						} else {
							r.errors.push({ priority: 2, message: 'productId_mismatch', field: 'productId', type: 'E' });
						}
					}
				}
			}
		}

	} catch (err) {
		console.error("Error fetching Supplier Amendment data in validateUploadedAMSData:", err);
	}

	return data;
};
