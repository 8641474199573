<script>
    import { exchangeRates } from "../../stores/AppStatus";
    import { Title } from '@smui/top-app-bar';
    import Flex from 'svelte-flex';
    import { _ } from 'svelte-i18n';
    import CircularProgress from '@smui/circular-progress'; // Assuming you're using SMUI Circular Progress

    // Subscribe to keep the rates variable up-to-date.
    let rates = [];

    exchangeRates.subscribe(value => {
        rates = value;
    });

    // Reactive statement to check if data is loaded
    $: dataLoaded = rates.length > 0;
</script>

<Title class="right-splitter left-splitter pr-4 h-100">
    <Flex direction="column" align="start" class="gap-05 line-height-05rem h-100">
        {#if !dataLoaded}
            <CircularProgress class="white-text" style="height: 1rem; width: 1rem; padding-right: 1rem" indeterminate />
        {:else}
            {#each rates as rate}
                <Flex direction="row" justify="between" class="fs-07rem w-100 gap-05" title="{$_('date')}: {new Date(rate.validFrom).toLocaleDateString()}">
                    <div>1 EUR =</div>
                    <div>{rate.valueInEur} {rate.currencySign}</div>
                </Flex>
            {/each}
        {/if}
    </Flex>
</Title>