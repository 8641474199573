<script>

    import { push } from 'svelte-spa-router'
    import { unauthorized, authenticatedUser, selectedModule } from "../stores/AppStatus";

    import AppMenuTabs from '../components/AppMenuTabs.svelte';

</script>

{#if $unauthorized }
    { push('/login') }
{:else}
    <AppMenuTabs
        activeModule={$selectedModule}
        />
{/if}
