import { writable } from 'svelte/store';

export const items = writable([]);

export const bsItems =  writable([]);

export const supplierList =  writable([
    {
        "id": 2005013        ,
        "supplierName": "Ovoce-Zelenina s.r.o"
    },
    {
        "id": 2005014        ,
        "supplierName": "Agro Group"
    },
    {
        "id": 2005015,
        "supplierName": "ACRO SPOL. S R.O"
    },
    {
        "id": 2005016,
        "supplierName": "ALDO GROUP INTERNATIONAL"
    },
    {
        "id": 2005017,
        "supplierName": "ALFA BIO, S.R.O"
    },
    {
        "id": 2005018,
        "supplierName": "Bidfood Czech Republic s.r.o."
    },
    {
        id: 2005019,
        "supplierName": "COUNTRY STYLE FOODS LTD"
    },
    {
        id: 2005020,
        "supplierName": "Dr. Oetker, spol. s r.o."
    },
    {
        id: 2005021,
        "supplierName": "NOVEGA s.r.o."
    }
  ]);

  export const autobuySetupList =  writable([
    {
        "id": 1,
        "supplierId":2005013,
        "supplierName": "Ovoce-Zelenina s.r.o",
        "participation": 65
    },
    {
        "id": 2,
        "supplierId": 2005014,
        "supplierName": "Agro Group",
        "participation": 20
    },
    {
        "id": 3,
        "supplierId": 2005015,
        "supplierName": "ACRO SPOL. S R.O",
        "participation": 15
    }
  ]);
