import { writable } from 'svelte/store';

export const amendments =  writable([
    {
        id: 1,
        type: 'cntr',
        tsWeek: '05/2023',
        calWeek: '13/2023',
        items: [
            {
                id: 1,
                country: 'CZ',
                dc: 'CZK',
                category: 'F',
                tpnEn: '2005123123123',
                tpn: '123123123',
                productDescEn: 'Blue Bananas',
                amendmentDate: new Date(),
                deliveryDate: new Date(),
                requestVolume: 100,
                countryComment: '',
                userComment: '',
                status: 'new'
            },
            {
                id: 2,
                country: 'CZ',
                dc: 'CZK',
                category: 'F',
                tpnEn: '2005123123123',
                tpn: '123123123',
                productDescEn: 'Red Bananas',
                amendmentDate: new Date(),
                deliveryDate: new Date(),
                requestVolume: 100,
                countryComment: '',
                userComment: '',
                status: 'new'
            },
            {
                id: 3,
                country: 'SK',
                dc: 'SKB',
                category: 'F',
                tpnEn: '2005123123123',
                tpn: '123123123',
                productDescEn: 'Yellow Bananas',
                amendmentDate: new Date(),
                deliveryDate: new Date(),
                requestVolume: 100,
                countryComment: '',
                userComment: '',
                status: 'new'
            },
            {
                id: 4,
                country: 'SK',
                dc: 'SKB',
                category: 'F',
                tpnEn: '2005123123123',
                tpn: '123123123',
                productDescEn: 'Green Bananas',
                amendmentDate: new Date(),
                deliveryDate: new Date(),
                requestVolume: 100,
                countryComment: '',
                userComment: '',
                status: 'new'
            },
            {
                id: 5,
                country: 'SK',
                dc: 'SKP',
                category: 'F',
                tpnEn: '2005123123123',
                tpn: '123123123',
                productDescEn: 'Grey Bananas',
                amendmentDate: new Date(),
                deliveryDate: new Date(),
                requestVolume: 100,
                countryComment: '',
                userComment: '',
                status: 'new'
            },
            {
                id: 6,
                country: 'HU',
                dc: 'HUB',
                category: 'F',
                tpnEn: '2005123123123',
                tpn: '123123123',
                productDescEn: 'White Bananas',
                amendmentDate: new Date(),
                deliveryDate: new Date(),
                requestVolume: 100,
                countryComment: '',
                userComment: '',
                status: 'new'
            }
        ]
    },
    {
        id: 2,
        type: 'supp',
        tsWeek: '05/2023',
        calWeek: '13/2023',
        items: [
            {
                id: 1,
                country: 'CZ',
                dc: 'CZK',
                roundNo: 1,
                tpnEn: '2005123123123',
                productDescEn: 'Blue Bananas',
                deliveryDate: new Date('2023-10-24'),
                supplierNo: 14821,
                supplierName: 'Agro Group',
                originalDeliveryScheduleVolume: 100,
                amendmendRequestedVolumeCase: 100,
                supplierDeadline: '22.10.2023 14:34',
                supplierConfirmedVolume: 50,
                supplierComment: '',
                supplierDate: new Date(),
                countryComment: '',
                userComment: '',
                status: 'confirmed_less',
                uploadFlag: 'uploaded',
                timeLimit: 30
            },            {
                id: 2,
                country: 'SK',
                dc: 'SKB',
                roundNo: 1,
                tpnEn: '2005123123135',
                productDescEn: 'White Bananas',
                deliveryDate: new Date('2023-10-24'),
                supplierNo: 14821,
                supplierName: 'Frudis',
                originalDeliveryScheduleVolume: 100,
                amendmendRequestedVolumeCase: 100,
                supplierDeadline: '22.10.2023 14:28',
                supplierConfirmedVolume: 100,
                supplierComment: '',
                supplierDate: new Date(),
                countryComment: '',
                userComment: '',
                status: 'confirmed_all',
                uploadFlag: 'uploaded',
                timeLimit: 15
            },            {
                id: 3,
                country: 'SK',
                dc: 'SKP',
                roundNo: 1,
                tpnEn: '2005123123123',
                productDescEn: 'Green Bananas',
                deliveryDate: new Date('2023-10-24'),
                supplierNo: 14821,
                supplierName: 'Friss Kertesz KFT.',
                originalDeliveryScheduleVolume: 3300,
                amendmendRequestedVolumeCase: 300,
                supplierDeadline: '22.10.2023 14:34',
                supplierConfirmedVolume: '',
                supplierComment: '',
                supplierDate: new Date(),
                countryComment: '',
                userComment: '',
                status: 'not_responded',
                uploadFlag: 'not_uploaded',
                timeLimit: 0
            },            {
                id: 4,
                country: 'CZ',
                dc: 'CZK',
                roundNo: 2,
                tpnEn: '2005123123123',
                productDescEn: 'Blue Bananas',
                deliveryDate: new Date('2023-10-25'),
                supplierNo: 14821,
                supplierName: 'Vviss',
                originalDeliveryScheduleVolume: 100,
                amendmendRequestedVolumeCase: 100,
                supplierDeadline: '23.10.2023 14:34',
                supplierConfirmedVolume: 0,
                supplierComment: 'no more on stock',
                supplierDate: new Date(),
                countryComment: '',
                userComment: '',
                status: 'rejected',
                uploadFlag: 'not_uploaded',
                timeLimit: 30
            },            {
                id: 5,
                country: 'SK',
                dc: 'SKB',
                roundNo: 3,
                tpnEn: '2005123123135',
                productDescEn: 'Red Bananas',
                deliveryDate: new Date('2023-10-25'),
                supplierNo: 14821,
                supplierName: 'Garden Food&Drink',
                originalDeliveryScheduleVolume: 100,
                amendmendRequestedVolumeCase: 100,
                supplierDeadline: '23.10.2023 14:28',
                supplierConfirmedVolume: '',
                supplierComment: '',
                supplierDate: new Date(),
                countryComment: '',
                userComment: '',
                status: 'sent',
                uploadFlag: 'not_uploaded',
                timeLimit: 15
            },            {
                id: 6,
                country: 'SK',
                dc: 'SKP',
                roundNo: 1,
                tpnEn: '2005123123123',
                productDescEn: 'Blue Bananas',
                deliveryDate: new Date('2023-10-25'),
                supplierNo: 14821,
                supplierName: 'Daifruitsv',
                originalDeliveryScheduleVolume: 3300,
                amendmendRequestedVolumeCase: 300,
                supplierDeadline: '23.10.2023 14:34',
                supplierConfirmedVolume: '',
                supplierComment: '',
                supplierDate: new Date(),
                countryComment: '',
                userComment: '',
                status: 'sent',
                uploadFlag: 'not_uploaded',
                timeLimit: 0
            }
        ]
    }
])


