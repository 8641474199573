<script>

    import { createEventDispatcher } from 'svelte';

    import Button, { Label} from '@smui/button';

    import { Icon } from '@smui/icon-button';
    import { mdiFileExcel } from '@mdi/js';

    import { push } from "svelte-spa-router";
    import { _ } from 'svelte-i18n';

    export let disabled = false;

    const dispatch = createEventDispatcher();

</script>

<Button
    disabled={disabled}
    class="{disabled ? 'grey' : 'tescoblue'} white-text pr-4"
    title="{$_('download_excel_file')}"
    on:click="{() => dispatch('download')}"
>
<Icon tag='svg' viewBox="0 0 24 24" class="pr-0 pl-1">
    <path fill="currentColor" d={mdiFileExcel} />
</Icon> {$_('download')}
</Button>

<style>
</style>
