import Api from "../services/Api";

// Method to get current List of DCs
export const getAutobuy = async (id, productId, supplierId) => {
    try {
      const params = new URLSearchParams();
      if (id) params.append('id', id);
      if (productId) params.append('productId', productId);
      if (supplierId) params.append('supplierId', supplierId);

      const response = await Api.get("/product/autobuy", params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Create new DC
export const createAutobuy = async (data) => {
    try {
      const response = await Api.post("/product/autobuy", data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Update DC data
export const updateAutobuy = async (data) => {
    try {
      const params = new URLSearchParams();
      params.append('id', data?.id);

      const response = await Api.put("/product/autobuy", params, data);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.log(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};

// Delete DC
export const deleteAutobuy = async (id) => {
    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const response = await Api.delete("/product/autobuy", params);
      console.log({response});

      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message, {cause: error});
    }
};
