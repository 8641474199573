<script lang="ts">
    import { suppStatus } from "../../../../stores/AppConfig";
    import Select, { Option } from "@smui/select";
    import { _ } from "svelte-i18n";

    export let value = "";
    export let label=$_("ce_supplier_status")
    export let format = { showId: false, translateName: true };

    type status = { id: number; name: string };
    let statuses: status[] = $suppStatus;   

    const formatOptionName = (item) => {
        if (format?.showId) {
            return [item.id, format?.translateName ? $_(item.name) : item.name].join(" - ");
        } else {
            return format?.translateName ? $_(item.name) : item.name;
        }
    };
</script>

<Select 
    variant="outlined" 
    {label}
    class="white w-100 small flex-0" 
    bind:value 
    key={(status) => `${status ? status.id : ""}`}
>
    <Option value={null} />
    {#each statuses as status (status.name)}
        <Option value={status}>{formatOptionName(status)}</Option>
    {/each}
</Select>
